import { CssBaseline, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import MunicipalityBase from "containers/municipalities/MunicipalityBase";
import SidebarTekstveld from "containers/partials/sidebar/SidebarTekstveld";
import { StoresContext } from "index";
import { observer } from "mobx-react";
import React, { FunctionComponent, useContext, useEffect } from "react";
import { MapContext } from "react-map-gl";
import { Route, Switch, useParams } from "react-router-dom";
import { ROUTE_BASE, ROUTE_MUNICIPALITY, ROUTE_MUNICIPALITY_PROJECT, ROUTE_MUNICIPALITY_PROJECT_FILE, ROUTE_MUNICIPALITY_PROJECT_FILE_SPLIT } from "routes/RouteList";
import { fetchMunicipalities } from "services/api";
import HeaderView from "views/Header/HeaderView";
import MapView from "views/Map/MapView";
import Notifier from "views/notifier/Notifier";
import DocumentViewer from "../partials/PDFTron/DocumentViewer";
import Sidebar from "../partials/sidebar/Sidebar";
import SidebarProject from "../partials/sidebar/SidebarProject";
import useBasePageStyles from "./BasePageStyles";
interface Props {
  classes: any;
}

const BasePage: FunctionComponent<Props> = observer(({ classes }) => {
  const {
    uiStore: { sideBarOpen, sideBarTekstveldOpen },
    mapStore: { setNis, mapLayer: { maps }, nis },
    authStore: { token, authenticateUser, sessionId },
    projectStore: { fetchNewProjects },
  } = useContext(StoresContext);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { municipality } = useParams<{ municipality: string }>();

  useEffect(() => {
    console.log("municipality", municipality);
    authenticateUser(sessionId, municipality);
  }, [sessionId, municipality]);

  useEffect(() => {
    const fetchMuni = async () => {
      const { municipalities } = await fetchMunicipalities();
      const nis = municipalities.filter((muni) => muni.municipalityName.toLowerCase() === municipality.toLowerCase())[0].municipalityCode;
      setNis(nis);
    };
    fetchMuni();
  }, [municipality]);

  useEffect(() => {
    if (token) {
      fetchNewProjects(nis);
    }
  }, [token, nis, municipality]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Switch>
        <Route exact path={ROUTE_MUNICIPALITY}>
          <Sidebar />
        </Route>
        <Route exact path={[ROUTE_MUNICIPALITY_PROJECT, ROUTE_MUNICIPALITY_PROJECT_FILE, ROUTE_MUNICIPALITY_PROJECT_FILE_SPLIT]}>
          <SidebarProject />
        </Route>
      </Switch>
      <HeaderView />
      <main
        className={classNames(matches ? classes.contentMobile : classes.content, {
          ...(!matches && { [classes.contentShift]: sideBarOpen }),
        })}
      >
        <div className={classes.drawerHeader} />
        <Switch>
          <Route exact path={[ROUTE_MUNICIPALITY_PROJECT_FILE, ROUTE_MUNICIPALITY_PROJECT_FILE_SPLIT]}>
            <DocumentViewer />
          </Route>
          <Route exact path={ROUTE_BASE}>
            {maps.length > 0 && <MunicipalityBase />}
          </Route>
          <Route exact path={[ROUTE_MUNICIPALITY, ROUTE_MUNICIPALITY_PROJECT]}>
            {maps.length > 0 && <MapView />}
          </Route>
        </Switch>
        {sideBarTekstveldOpen && <SidebarTekstveld />}
      </main>
      <Notifier />
    </div>
  );
});

export default withStyles(useBasePageStyles)(BasePage);
