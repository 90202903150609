import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { createBrowserHistory } from "history";
import { observer } from "mobx-react";
import { syncHistoryWithStore } from "mobx-react-router";
import React, { FunctionComponent, useContext, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { Router } from "react-router";
import { translationMessages } from "./i18n";
import { StoresContext } from "./index";
import { light } from "./layout/theme.js";
import AppRoute from "./routes/AppRoute";

export const browserHistory = createBrowserHistory();
const App: FunctionComponent<{}> = observer(() => {
  const {
    routingStore,
    uiStore: { locale },
  } = useContext(StoresContext);
  const history = syncHistoryWithStore(browserHistory, routingStore);

  return (
    <IntlProvider locale={locale} messages={translationMessages[locale]}>
      <Router history={history}>
        <ThemeApp />
      </Router>
    </IntlProvider>
  );
});

const ThemeApp = () => {
  /*const {
    settingsStore: { darkMode }
  } = useContext(StoresContext);*/

  let theme = light;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AppRoute />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
