import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useThreeDMCViewerStyles = (theme: Theme) =>
  createStyles({
    footprint: {
      //@ts-ignore
      pointerEvents: "none !important",
      stroke: "rgba(38,71,145,1) !important",
      strokeWidth: "1px !important",
      //@ts-ignore
      strokeOpacity: "1 !important",
      fill: "rgba(38,71,145,0.9019607843137255) !important",
      //@ts-ignore
      fillOpacity: "0.9019607843137255 !important",
      zIndex: 499,
      //@ts-ignore
      "&:hover": {
        stroke: "rgba(38,71,145,1) !important",
        strokeWidth: "1px !important",
        strokeOpacity: "1 !important",
        fill: "rgba(38,71,145,0.9019607843137255) !important",
        fillOpacity: "0.9019607843137255 !important",
      },
    },
    pickfootprint: {
      cursor: "crosshair",
      stroke: "rgba(38,71,145,1) !important",
      strokeWidth: "1px !important",
      //@ts-ignore
      strokeOpacity: "1 !important",
      fill: "rgba(38,71,145,0.9019607843137255) !important",
      //@ts-ignore
      fillOpacity: "0.9019607843137255 !important",
      zIndex: 499,
      //@ts-ignore
      "&:hover": {
        stroke: "rgba(38,71,145,1) !important",
        strokeWidth: "1px !important",
        strokeOpacity: "1 !important",
        fill: "rgba(38,71,145,0.9019607843137255) !important",
        fillOpacity: "0.9019607843137255 !important",
      },
    },
  });

export default useThreeDMCViewerStyles;
