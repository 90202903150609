import { StoresContext } from "index";
import { useContext } from "react";

export const firstLetterUpperCase = (input: string): string => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const GetColorForStatusGeoportaal = (statusgeoportaal: string) => {
  const {
    authStore: { role },
  } = useContext(StoresContext);
  let color = "#FF0000";

  switch (statusgeoportaal?.toLocaleLowerCase().replace(" ", "")) {
    case "openbaaronderzoek":
      color = "#008000";
      break;
    case "ingediend":
      color = "#ff6600";
      break;
    case "beslissing":
      color = "#660066";
      break;
    case "beperktopenbaaronderzoek":
      color = "#8BC34A";
      break;
    case "inbehandeling":
      // color = role === "Burgerloket" ? "#8BC34A" : "#0000FF";
      color = "#0000FF";
      break;

    default:
      break;
  }
  return color;
};
