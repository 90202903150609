//import AccessControl from "accesscontrol";
import { observable, action, decorate, autorun } from "mobx";
import AuthStore from "./AuthStore";
import DocViewerModel from "./models/DocViewerModel";
import io from "socket.io-client";

export default class DocViewerStore {
  constructor(authStore: AuthStore) {
    this.authStore = authStore;

    autorun(() => {
      if (this.authStore.token && this.authStore.role === "Dossierbeheerder") {
        //console.log("aaa", this.authStore.token);
        //console.log("aaa", this.authStore.role);
        // validate();
        if (!this.socket) {
          console.log("Setting up websocket 11");
          this.connectToSocket();
          this.initDocviewers();
        }
      } else {
        this.initDocviewers();
      }
    });
  }

  authStore: AuthStore;

  socket: any;
  docViewer1: DocViewerModel;
  docViewer2: DocViewerModel;

  initDocviewers = () => {
    if (!this.docViewer1) {
      this.docViewer1 = new DocViewerModel(this.authStore, this.socket);
      this.docViewer2 = new DocViewerModel(this.authStore, this.socket);
    }
  };

  connectToSocket = () => {
    console.log("aaa", "hier ook ne log zette");
    this.socket = io("http://localhost:3000", {
      //@TODO: Hier nog de URL uit de .env halen
      query: {
        auth_token: this.authStore.token,
      },
    });
    // Connection failed
    this.socket.on("error", function (err) {
      console.log(err.toString());
    });
    // Connection succeeded
    this.socket.on("success", function (data) {
      console.log("connectedToSocket");
    });

    this.socket.on("update", ({ file, xfdfString }) => this.updateXfdfString(file, xfdfString));
  };

  disconnectFromSocket = () => {
    console.log("aaa", "Disconnect from socket");
    if (this.socket) {
      this.socket.removeAllListeners();
      this.socket.disconnect();
    }
  };

  updateXfdfString = async (file, xfdfString) => {
    const annotManager = file === this.docViewer1.file ? this.docViewer1.annotManager : this.docViewer2.annotManager;
    const annotations = await annotManager.importAnnotCommand(xfdfString);
    annotManager.drawAnnotationsFromList(annotations);
  };
}

decorate(DocViewerStore, {
  socket: [observable],
  docViewer1: [observable],
  docViewer2: [observable],
});
