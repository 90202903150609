//import AccessControl from "accesscontrol";
import { observable, action, decorate, toJS } from "mobx";
import { persist } from "mobx-persist";
import { login, linkTokenToAxios } from "../services/auth";
import axios from "axios";

const decode_JWT = (token) => {
  try {
    const base64HeaderUrl = token.split(".")[0];
    const base64Header = base64HeaderUrl.replace("-", "+").replace("_", "/");
    const headerData = JSON.parse(window.atob(base64Header));

    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    let dataJWT = JSON.parse(window.atob(base64));
    dataJWT.header = headerData;

    return dataJWT;
  } catch (err) {
    return false;
  }
};

export default class AuthStore {
  isLoggedIn: boolean = false;

  isBurger: boolean = false;

  selectingProfile: boolean = false;

  user: any = {};

  token: string;

  sessionId: string;

  availableProfiles = [];

  role = "";

  municipality_id: number = 0;

  scopes = [];

  municipalities: any[] = [];

  nis: string;

  setMuniciaplities = async () => {
    const { data: allMunicipalities } = await axios.get("/config/municipalities.json");
    this.municipalities = Object.values(allMunicipalities);
  };

  /**
   * returns logged in status
   */
  doLogout = () => {
    this.role = "Burger Online";
    this.user = {};
    this.token = "";
    this.sessionId = "";
    this.availableProfiles = [];
    this.scopes = [];
    this.municipality_id = 0;
    this.selectingProfile = false;
    this.isLoggedIn = false;
    this.isBurger = false;
    window.location.href = `${window.env.AUTH_URL}logout`;
  };

  fetchToken = async (session: string) => {
    const { jwt, sessionId } = await login(session);
    this.token = jwt;
    this.sessionId = sessionId;
  };

  authenticateUser = async (session: string, municipalityName: string): Promise<void> => {
    if (!municipalityName) {
      console.log("municipalityName not set");
      return;
    }
    console.log("municipalityName is set", municipalityName);
    await this.setMuniciaplities();
    await this.fetchToken(session);
    const token = decode_JWT(this.token);

    if (token.role === "Burger Online") {
      this.isLoggedIn = false;
      this.isBurger = false;
    } else if (token.role === "Burgerloket") {
      this.isLoggedIn = true;
      this.isBurger = true;
    } else {
      this.user = token.user;
      this.isLoggedIn = true;
    }

    console.log("setting role to", token.role);
    this.role = token.role;

    this.scopes = Object.assign({}, token.unrestricted_scopes, token.nis_restricted_scopes);

    //let currentMunicipalityName = "wetteren"; // DE GEMEENTE NAAM UIT URL

    let municipality = this.municipalities.filter((municipality) => municipality.name.replace(/-/g, "") === municipalityName.replace(/-/g, ""))[0];

    console.log("token.nis", token.nis);
    console.log("municipality", municipality);

    if ("nis" in token) {
      this.municipality_id = token.nis;
      // Indien de gebruiker is ingelogd maar een andere gemeente bezoekt
      if (!(municipality && token.nis === municipality.niscode)) {
        console.log("overwrite user his role and scopes");
        this.role = "Burger Online";
        this.scopes = token.unrestricted_scopes;
      }
    }
  };

  checkIfMunicipalityExists = async (municipalityCheck) => {
    await this.setMuniciaplities();
    const newMunicipality = this.municipalities.filter((municipality) => municipality.name.replace(/-/g, "") === municipalityCheck.replace(/-/g, ""))[0];

    if (!newMunicipality) {
      return false;
    }
    this.municipality_id = newMunicipality.niscode;
    return true;
  };

  setAvailableProfiles = (profiles: []) => {
    this.availableProfiles = profiles;
  };

  toggleSelectingProfile = () => {
    this.selectingProfile = !this.selectingProfile;
  };

  setSessionId = (id: string) => {
    this.sessionId = id;
  };

  setSelectingProfile = (value) => {
    this.selectingProfile = value;
  };
}

decorate(AuthStore, {
  isLoggedIn: [observable, persist],
  isBurger: [observable, persist],
  selectingProfile: [observable, persist],
  user: [observable, persist("object")],
  token: [observable],
  role: [observable, persist],
  sessionId: [observable, persist],
  availableProfiles: [observable, persist("list")],
  municipalities: [observable, persist("list")],
  municipality_id: [observable],
  scopes: [observable],
  doLogout: action.bound,
  authenticateUser: action.bound,
  setAvailableProfiles: action.bound,
  setSessionId: action.bound,
  setSelectingProfile: action.bound,
  toggleSelectingProfile: action.bound,
  setMuniciaplities: action.bound,
});
