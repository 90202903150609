import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { SIDEBAR_CONFIG } from "../../../constants";

const useSidebarTekstveldStyles = (theme: Theme) =>
  createStyles({
    drawer: {
      width: SIDEBAR_CONFIG.width,
      flexShrink: 0,
    },
    drawerMobile: {
      width: "100%",
      flexShrink: 0,
      overflow: "hidden",
    },
    drawerPaper: {
      width: SIDEBAR_CONFIG.width,
      overflowX: "hidden",
      borderRight: 0,
    },
    drawerPaperMobile: {
      width: "100%",
      overflow: "hidden",
      borderRight: 0,
    },
    drawerHeader: {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      zIndex: 99,
      display: "flex",
      alignItems: "center",
      padding: "0 16px",
      ...theme.mixins.toolbar,
    },
    whiteText: {
      color: "#fff",
    },
    grow: {
      flexGrow: 1,
    },
    closeButton: {
      color: "#fff",
      right: theme.spacing(1.5),
    },
    root: {
      width: "100%",
      maxWidth: "100%",
      backgroundColor: theme.palette.background.paper,
      color: "#C1C1C1",
      padding: 10,
    },
    title: {
      flexGrow: 1,
      marginRight: theme.spacing(2),
    },
  });

export default useSidebarTekstveldStyles;
