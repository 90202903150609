import { observable, action, computed, decorate } from "mobx";
import { persist } from "mobx-persist";
import axios from "axios";

/**
 * model used to control the layer of the maps
 * maplist is a json with al possible layers
 * when changing the activemapindex the map will be rerendered
 *
 */
class MapLayerModel {
  constructor() {
    this.loadMaps();
  }

  maps: any[] = [];

  activeMapIndex: number = 5;

  loadMaps = async (): Promise<string | void> => {
    try {
      const {
        data: { basemaps },
      } = await axios.get("/config/maps.json");
      if (basemaps && this.activeMapIndex >= basemaps.length) {
        this.activeMapIndex = 0;
      }
      this.maps = basemaps;
    } catch (error) {
      console.log("ERROR::", error.toString());
    }
  };

  setActiveMapIndex = (index: number) => {
    this.activeMapIndex = index;
  };

  get activeMap() {
    return this.maps[this.activeMapIndex];
  }
}

export default MapLayerModel;

decorate(MapLayerModel, {
  maps: [observable, persist("list")],
  activeMapIndex: [observable, persist],
  loadMaps: action.bound,
  setActiveMapIndex: action.bound,
  activeMap: computed,
});
