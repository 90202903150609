import axios from "axios";

//const APP_ID = process.env.REACT_APP_APP_ID;
const AUTH_URL = window.env.AUTH_URL;
const API_OMGEVING_URL = window.env.API_OMGEVING_URL;
export const LOGIN_URL = `${AUTH_URL}token`;

export const login = async (
  session: string,
): Promise<{
  jwt: string;
  sessionId: string;
  rol: string;
}> => {
  const {
    data: { jwt, sessionId, rol },
  } = await axios.post(AUTH_URL + `token?id=${session ? session : null}`);
  linkTokenToAxios(jwt);
  return {
    jwt,
    sessionId,
    rol,
  };
};

export const getUserRights = async (id: string): Promise<any> => {
  const { data } = await axios.get(`${AUTH_URL}choose?id=${id}`);
  return data;
};

export const validatetoken = async (sessionId: any) => {
  let {
    data: { data: { token: jwt, rol } },
  } = await axios.get(API_OMGEVING_URL + `acm/validatetoken/${sessionId}`);
  linkTokenToAxios(jwt);
  return {
    jwt,
    sessionId,
    rol,
  };
};

export const linkTokenToAxios = (token) => {
  //console.log("SERVICE: linking token to axios:", token);
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
};
