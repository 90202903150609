import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DrawCircleFromCenterMode, DrawLineStringMode, DrawPolygonMode, EditingMode, Editor } from "react-map-gl-draw";
import ControlPanel from "./ControlPanel";
import { getEditHandleStyle, getFeatureStyle, getFeatureStyleLine } from "./EditorStyle";
import { useTheme } from "@mui/material/styles";

enum DrawType {
  None = "None",
  PolyLine = "PolyLine",
  Polygon = "Polygon",
  Circle = "Circle",
  Edit = "Edit",
  Delete = "Delete",
}

const MODES = [
  {
    id: "drawPolyline",
    text: "Draw Polyline",
    type: DrawType.PolyLine,
    handler: new DrawLineStringMode(),
    style: {
      backgroundImage:
        "url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20fill%3D%22%23FFFFFF%22%3E%3Cpath%20d%3D%22m13.5%203.5c-1.4%200-2.5%201.1-2.5%202.5%200%20.3%200%20.6.2.9l-3.8%203.8c-.3-.1-.6-.2-.9-.2-1.4%200-2.5%201.1-2.5%202.5s1.1%202.5%202.5%202.5%202.5-1.1%202.5-2.5c0-.3%200-.6-.2-.9l3.8-3.8c.3.1.6.2.9.2%201.4%200%202.5-1.1%202.5-2.5s-1.1-2.5-2.5-2.5z%22%2F%3E%3C%2Fsvg%3E')",
    },
    drawStyle: getFeatureStyleLine,
  },

  {
    id: "drawPolygon",
    text: "Draw Polygon",
    type: DrawType.Polygon,
    handler: new DrawPolygonMode(),
    style: {
      backgroundImage:
        "url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20fill%3D%22%23FFFFFF%22%3E%3Cpath%20d%3D%22m15%2012.3v-4.6c.6-.3%201-1%201-1.7%200-1.1-.9-2-2-2-.7%200-1.4.4-1.7%201h-4.6c-.3-.6-1-1-1.7-1-1.1%200-2%20.9-2%202%200%20.7.4%201.4%201%201.7v4.6c-.6.3-1%201-1%201.7%200%201.1.9%202%202%202%20.7%200%201.4-.4%201.7-1h4.6c.3.6%201%201%201.7%201%201.1%200%202-.9%202-2%200-.7-.4-1.4-1-1.7zm-8-.3v-4l1-1h4l1%201v4l-1%201h-4z%22%2F%3E%3C%2Fsvg%3E')",
    },
    drawStyle: getFeatureStyle,
  },
  {
    id: "drawCircle",
    text: "Draw Circle",
    type: DrawType.Circle,
    handler: new DrawCircleFromCenterMode(),
    style: {
      backgroundImage:
        "url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20enable-background%3D%22new%200%200%2024%2024%22%20height%3D%2218px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218px%22%20fill%3D%22%23FFFFFF%22%3E%3Cg%3E%3Crect%20fill%3D%22none%22%20height%3D%2224%22%20width%3D%2224%22%2F%3E%3C%2Fg%3E%3Cg%3E%3Cpath%20d%3D%22M12%2C2C6.47%2C2%2C2%2C6.47%2C2%2C12c0%2C5.53%2C4.47%2C10%2C10%2C10s10-4.47%2C10-10C22%2C6.47%2C17.53%2C2%2C12%2C2z%20M12%2C20c-4.42%2C0-8-3.58-8-8%20c0-4.42%2C3.58-8%2C8-8s8%2C3.58%2C8%2C8C20%2C16.42%2C16.42%2C20%2C12%2C20z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E')",
    },
    drawStyle: getFeatureStyle,
  },
  {
    id: "edit",
    text: "Edit",
    type: DrawType.Edit,
    handler: new EditingMode(),
    style: {
      backgroundImage:
        "url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%20height%3D%2218px%22%20width%3D%2218px%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M3%2017.25V21h3.75L17.81%209.94l-3.75-3.75L3%2017.25zM20.71%207.04c.39-.39.39-1.02%200-1.41l-2.34-2.34a.9959.9959%200%2000-1.41%200l-1.83%201.83%203.75%203.75%201.83-1.83z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E')",
    },
  },
];

export default function DrawFunctions() {
  const [mode, setMode] = useState<any>(null);
  const [features, setFeatures] = useState<any>(null);
  const [styles, setStyles] = useState<any>([]);
  const editorRef = useRef<Editor>(null);
  const [drawType, setDrawType] = useState<DrawType>(DrawType.None);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const onDelete = useCallback(() => {
    const props = editorRef?.current?.getModeProps();
    const featureLength = props?.data?.features?.length;
    if (editorRef.current !== null && featureLength > 0) {
      editorRef.current.deleteFeatures(Array.from(Array(featureLength).keys()));
      setFeatures(null);
    }
  }, []);

  const onUpdate = useCallback(({ editType, ...rest }) => {
    console.log(rest);
    setFeatures(editorRef?.current?.getFeatures());
    if (editType === "addFeature") {
      switchTool(DrawType.None);
    }
  }, []);

  const switchTool = (type: DrawType) => {
    if (![DrawType.Edit, DrawType.None].includes(type)) {
      onDelete();
    }
    setDrawType(type);
  };

  const handleClickDelete = () => {
    switchTool(DrawType.None);
    onDelete();
  };

  useEffect(() => {
    if (drawType === DrawType.None) {
      setMode(null);
    }
  }, [drawType]);

  return (
    <>
      <Editor
        ref={editorRef}
        style={{
          width: "100%",
          height: "100%",
          cursor: drawType === DrawType.Edit ? "move" : drawType !== DrawType.None ? "crosshair" : "inherit",
        }}
        mode={mode}
        clickRadius={12}
        editHandleShape={"circle"}
        onUpdate={onUpdate}
        featureStyle={styles}
        editHandleStyle={getEditHandleStyle}
      />
      <div style={{ bottom: matches ? 95 : 70 }} className="mapboxgl-ctrl-bottom-left">
        <div className="mapboxgl-ctrl-group mapboxgl-ctrl ">
          {MODES.map((drawMode) => {
            return (
              <button
                className={`mapbox-gl-draw_ctrl-draw-btn ${drawMode.style}`}
                title={drawMode.text}
                style={{
                  backgroundColor: drawType === drawMode.type ? "#007C91" : "#00ACC1",
                  ...drawMode.style,
                }}
                onClick={() => {
                  if (drawType === drawMode.type) {
                    setMode(null);
                    switchTool(DrawType.None);
                  } else {
                    setMode(drawMode.handler);
                    switchTool(drawMode.type);
                    if (drawMode.drawStyle) {
                      setStyles(drawMode.drawStyle);
                    }
                  }
                }}
              />
            );
          })}
          <button
            className="mapbox-gl-draw_ctrl-draw-btn "
            title="Delete"
            style={{
              backgroundColor: drawType === DrawType.Delete ? "#007C91" : "#00ACC1",
              backgroundImage:
                "url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20fill%3D%22%23fff%22%3E%3Cpath%20d%3D%22M10%2C3.4%20c-0.8%2C0-1.5%2C0.5-1.8%2C1.2H5l-1%2C1v1h12v-1l-1-1h-3.2C11.5%2C3.9%2C10.8%2C3.4%2C10%2C3.4z%20M5%2C8v7c0%2C1%2C1%2C2%2C2%2C2h6c1%2C0%2C2-1%2C2-2V8h-2v5.5h-1.5V8h-3%20v5.5H7V8H5z%22%2F%3E%3C%2Fsvg%3E')",
            }}
            onClick={handleClickDelete}
          />
        </div>
      </div>

      {features?.length > 0 && <ControlPanel features={features} />}
    </>
  );
}
