import { Card, CardActionArea, CardContent, CardMedia, CssBaseline, Grid, Typography, Container, Fab } from "@mui/material";
import { withStyles } from "@mui/styles";
import { StoresContext } from "index";
import { observer } from "mobx-react";
import React, { FunctionComponent, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import MunicipalityHeaderView from "views/Header/MunicipalityHeaderView";
import useMunicipalityBaseStyles from "./MunicipalityBaseStyles";
import MapIcon from "@mui/icons-material/Map";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

interface Props {
  classes: any;
}

const MunicipalityBase: FunctionComponent<Props> = observer(({ classes }) => {
  const {
    uiStore: { municipalities },
  } = useContext(StoresContext);

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        {/* @ts-ignore */}
        <MunicipalityHeaderView />
      </div>
      <Container maxWidth="xl" sx={{ marginTop: 10, marginBottom: 2 }}>
        <Grid container spacing={1}>
          {municipalities.map((municipalityData, id) => (
            <Grid item key={id} xs={12} sm={6} md={4} lg={3} xl={2}>
              <Link to={"/" + municipalityData.name} style={{ textDecoration: "none" }}>
                <Card>
                  <CardActionArea>
                    <CardMedia component="img" height={185} image={"./assets/img/" + municipalityData.name + ".png"} alt={municipalityData.display} />
                    <div style={{ right: 20, top: 165, position: "absolute" }}>
                      <Fab sx={{ color: "#ffffff" }} color="secondary" size="small" aria-label="kaart">
                        <MapIcon fontSize="small" />
                      </Fab>
                    </div>
                    <CardContent>
                      <Typography data-test-id="municipality-name" noWrap variant="h5" component="div">
                        {municipalityData.display}
                      </Typography>
                      <Typography data-test-id="municipality-postcode" gutterBottom variant="body1" component="div">
                        {municipalityData.postcode}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Link
              data-test-id="contact-link"
              to={{ pathname: "https://outlook.office365.com/owa/calendar/OrbitGTBelgiumKopiren@orbitgis.com/bookings/" }}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <Card>
                <CardActionArea>
                  <CardMedia component="img" height={185} image={"./assets/img/vlaanderen.png"} alt="Uw Gemeente" />
                  <div style={{ right: 20, top: 165, position: "absolute" }}>
                    <Fab sx={{ color: "#ffffff" }} color="secondary" size="small" aria-label="kaart">
                      <MailOutlineIcon fontSize="small" />
                    </Fab>
                  </div>
                  <CardContent>
                    <Typography data-test-id="uw-gemeente-hier" noWrap variant="h5" component="div">
                      Uw gemeente hier?
                    </Typography>
                    <Typography data-test-id="let's-talk" noWrap gutterBottom variant="body1" component="div">
                      Let's talk
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
});

export default withStyles(useMunicipalityBaseStyles)(MunicipalityBase);
