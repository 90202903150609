import fetchJsonp from "fetch-jsonp";
import axios from "axios";

export const getStreetSuggestions = async ({
  value,
  municipality = "",
  count = 15,
}: {
  value: string;
  municipality: string;
  count: number;
}) => {
  try {
    const { data } = await axios({
      url: `https://geo.api.vlaanderen.be/geolocation/geolocation/Location?q=${municipality}%20${value}&c=${count}`,
    });

    const LocationResult: GeopuntStreet[] = data.LocationResult;

    return LocationResult.map(({ FormattedAddress: item, Location: { Lat_WGS84, Lon_WGS84 } }) => ({
      value: item,
      label: item,
      location: { lat: Lat_WGS84, lon: Lon_WGS84 },
    }));
  } catch (error) {
    console.log("ERROR::getStreetSuggestions", error.toString());
    return [error.toString()];
  }
};

export const getLocation = async (value: {
  value: string;
  municipality?: string;
  count?: number;
}): Promise<{ lat: number; lng: number } | string[]> => {
  try {
    const response = await fetchJsonp(`https://geo.api.vlaanderen.be/geolocation/v4/Location?q=${value}`);
    const result = await response.json();
    return {
      lat: result.LocationResult["0"].Location.Lat_WGS84,
      lng: result.LocationResult["0"].Location.Lon_WGS84,
    };
  } catch (error) {
    console.log("error", error.toString());
    return [error.toString()];
  }
};

export interface Location {
  Lat_WGS84: number;
  Lon_WGS84: number;
  X_Lambert72: number;
  Y_Lambert72: number;
}

export interface GeopuntStreet {
  FormattedAddress: string;
  Location: Location;
  LocationType: string;
}
