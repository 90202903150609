import React, { Fragment, FunctionComponent } from "react";
import { Fab } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/Add";
import ZoomOutIcon from "@mui/icons-material/Remove";
import { MapConfig } from "./genericTypes";

type ZoomButtonsProps = {
  activeMap: MapConfig;
  zoomLevel: number;
  setZoomLevel: (newZoomLever: number) => void;
  setEnableLayerSelector: (showLayerSelector: boolean) => void;
};

const ZoomButtons: FunctionComponent<ZoomButtonsProps> = ({ activeMap, setZoomLevel, zoomLevel }) => {
  return (
    <>
      <Fab
        data-test-id="zoom-in"
        disabled={activeMap.maxZoom <= Math.round(zoomLevel)}
        color="secondary"
        size="small"
        style={{ color: "white", margin: 4 }}
        onClick={(e) => {
          setZoomLevel(zoomLevel + 1);
        }}
      >
        <ZoomInIcon />
      </Fab>
      <br />
      <Fab
        data-test-id="zoom-out"
        disabled={activeMap.minZoom >= Math.round(zoomLevel)}
        color="secondary"
        size="small"
        style={{ color: "white", margin: 4 }}
        onClick={(e) => setZoomLevel(zoomLevel - 1)}
      >
        <ZoomOutIcon />
      </Fab>
    </>
  );
};

export default ZoomButtons;
