import React from "react";
import HelpIcon from "@mui/icons-material/Help";
import PublicIcon from "@mui/icons-material/Public";
import GavelIcon from "@mui/icons-material/Gavel";
import SettingsIcon from "@mui/icons-material/Settings";

export const HeaderSteps = [
  {
    title: "Welkom",
    content: (
      <div>
        Algemene rondleiding <br />
        Welkom op de vernieuwde Geoportaal Omgeving website. Hieronder kan je een rondleiding volgen met de nieuwigheden in de applicatie.
        <br />
        <br />
        Je kan ook later de rondleiding starten via:
        <br />
        <HelpIcon /> &gt; Rondleiding:
      </div>
    ),
    disableBeacon: true,
    target: "#tour",
    offset: -10,
  },
  {
    title: "Clustering",
    content:
      "Alle huidige besliste vergunningen en vergunningen in openbaar onderzoek vind je op deze kaart. Voor een helder overzicht, zijn de vergunningen gegroepeerd per zone, met aanduiding van aantal. Klik op een groep om de inhoud te zien.",
    target: "#editor",
    offset: -10,
  },
  {
    title: "Navigeren / navigatie",
    content:
      "Gebruik de scroll functie of navigeer hier met + en -. Bij touchscreens kan je dubbeltikken op het scherm om in te zoomen. Je kan tevens het scherm aanraken met twee vingers, en die naar elkaar toe of van elkaar weg bewegen om in- en uit te zoomen.",
    target: "#tour-plus",
    offset: -10,
  },
  {
    title: "Achtergrond",
    content: "Klik hier om de achtergrond te wijzigen.",
    target: "#tour-layers",
    offset: -10,
  },
  {
    title: "Locatie",
    content: "Klik hier om uw locatie te tonen.",
    target: "#tour-location",
    offset: -10,
  },
  {
    title: "Adres zoeken",
    content: "Je kan zoeken op adres. Typ de straatnaam en eventueel het huisnummer in.",
    target: "#tour-adres-zoeken",
    offset: -10,
  },
  {
    title: "Zoeken",
    content: (
      <div>
        Een vergunning zoeken op een bepaald adres kan ook hier.
        <br />
        Hier kan je ook op zoek naar een specifieke vergunning. Zoek op naam van het project of projectnummer uit het omgevingsloket.
      </div>
    ),
    offset: -10,
    target: "#project-search",
  },
  {
    title: "Iconen op kaart",
    content: (
      <div>
        Hier kan je filteren op het type dossier dat je wil zien. Openbare onderzoeken worden aangeduid met <PublicIcon /> <br />
        Besliste vergunningen zijn herkenbaar door <GavelIcon />
      </div>
    ),
    offset: -10,
    target: "#projectstatus",
  },
  {
    title: "Selecteren",
    content: "Klik in dit vak om een vergunning meer in detail te bekijken.",
    offset: -10,
    target: "#tour-selecteren",
  },
  {
    title: (
      <div>
        <h3>Succes!</h3>
        <img src="/assets/img/tour-end.png" height="42" width="42" />
      </div>
    ),
    content: "Je weet nu helemaal je weg in Geoportaal.",
    offset: -10,
    target: "#tour",
    styles: {
      tooltipTitle: {
        textAlign: "center",
        margin: 0,
      },
    },
  },
];

export const InfoStepsOmgevingsloket = [
  {
    title: "Rondleiding dossierinformatie",
    content: (
      <div>
        We tonen je enkele nuttige functies die je kan gebruiken tijdens de inzage van een dossier.
        <br />
        <br />
        Je kan ook later de rondleiding starten via:
        <br />
        <HelpIcon /> &gt; Rondleiding:
      </div>
    ),
    disableBeacon: true,
    target: "#tour",
    offset: -10,
  },
  {
    title: "Administratieve informatie",
    content: "Hier vind je alle basisgegevens van deze omgevingsvergunning. Daarnaast vind je ook de nodige info over het dossierverloop.",
    target: "#informatie",
    offset: -10,
  },
  {
    title: "Bestanden",
    content: "Hier vind je alle documenten terug, gebundeld volgens type. Enkel aan de balie bij uw gemeente kan je het architecturale plan consulteren.",
    target: "#bestanden",
    offset: -10,
  },
  {
    title: "Omgevingsloket",
    content: "Voor het indienen van een bezwaarschrift word je doorwezen naar omgevingsloket.be",
    target: "#omgevingsloket",
    offset: -10,
  },
  {
    title: "Meten op kaart",
    content: "Met deze functies kan je afstanden en oppervlaktes meten op de kaart.",
    target: ".mapboxgl-ctrl-group",
    offset: -10,
  },
  {
    title: (
      <div>
        <h3>Succes!</h3>
        <img src="/assets/img/tour-end.png" height="42" width="42" />
      </div>
    ),
    content: "Je weet nu helemaal je weg in Geoportaal.",
    target: "#tour",
    offset: -10,
    styles: {
      tooltipTitle: {
        textAlign: "center",
        margin: 0,
      },
    },
  },
];

export const InfoStepsInBehandeling = [
  {
    title: "Rondleiding dossierinformatie",
    content: (
      <div>
        We tonen je enkele nuttige functies die je kan gebruiken tijdens de inzage van een dossier.
        <br />
        <br />
        Je kan ook later de rondleiding starten via:
        <br />
        <HelpIcon /> &gt; Rondleiding:
      </div>
    ),
    disableBeacon: true,
    target: "#tour",
    offset: -10,
  },
  {
    title: "Administratieve informatie",
    content: "Hier vind je alle basisgegevens van deze omgevingsvergunning. Daarnaast vind je ook de nodige info over het dossierverloop.",
    target: "#informatie",
    offset: -10,
  },
  {
    title: "Bestanden",
    content: "Hier vind je alle documenten terug, gebundeld volgens type. Enkel aan de balie bij uw gemeente kan je het architecturale plan consulteren.",
    target: "#bestanden",
    offset: -10,
  },
  {
    title: "Beperkt Openbaar Onderzoek",
    content: "Voor het indienen van een Beperkt Openbaar Onderzoek",
    target: "#beperktOpenbaarOnderzoek",
    offset: -10,
  },
  {
    title: (
      <div>
        <h3>Succes!</h3>
        <img src="/assets/img/tour-end.png" height="42" width="42" />
      </div>
    ),
    content: "Je weet nu helemaal je weg in Geoportaal.",
    target: "#tour",
    offset: -10,
    styles: {
      tooltipTitle: {
        textAlign: "center",
        margin: 0,
      },
    },
  },
];

export const InfoStepsIngediend = [
  {
    title: "Rondleiding dossierinformatie",
    content: (
      <div>
        We tonen je enkele nuttige functies die je kan gebruiken tijdens de inzage van een dossier.
        <br />
        <br />
        Je kan ook later de rondleiding starten via:
        <br />
        <HelpIcon /> &gt; Rondleiding:
      </div>
    ),
    disableBeacon: true,
    target: "#tour",
    offset: -10,
  },
  {
    title: "Administratieve informatie",
    content: "Hier vind je alle basisgegevens van deze omgevingsvergunning. Daarnaast vind je ook de nodige info over het dossierverloop.",
    target: "#informatie",
    offset: -10,
  },
  {
    title: "Bestanden",
    content: "Hier vind je alle documenten terug, gebundeld volgens type. Enkel aan de balie bij uw gemeente kan je het architecturale plan consulteren.",
    target: "#bestanden",
    offset: -10,
  },
  {
    title: (
      <div>
        <h3>Succes!</h3>
        <img src="/assets/img/tour-end.png" height="42" width="42" />
      </div>
    ),
    content: "Je weet nu helemaal je weg in Geoportaal.",
    target: "#tour",
    offset: -10,
    styles: {
      tooltipTitle: {
        textAlign: "center",
        margin: 0,
      },
    },
  },
];

export const BestandenSteps = [
  {
    title: "Rondleiding bestanden",
    content: (
      <div>
        We tonen je enkele nuttige functies die je kan gebruiken tijdens het bekijken van plannen, documenten en foto's.
        <br />
        <br />
        Je kan ook later de rondleiding starten via:
        <br />
        <HelpIcon /> &gt; Rondleiding:
      </div>
    ),
    disableBeacon: true,
    target: "#tour",
    offset: -10,
  },
  {
    title: "Zoekfunctie",
    content: "Via deze functie kan je tekst opzoeken in het document.",
    target: "#top-right",
    offset: 40,
  },
  {
    title: "Navigatie functies",
    content: "Hier kan je in en uitzoomen op documenten, pannen en tekst selecteren.",
    target: '[data-test-id="header-title"]',
    offset: 40,
  },
  {
    title: "Snel openen andere bestanden",
    content: (
      <div>
        Met deze pijltjes (&lt; &nbsp; &gt;) kan je snel naar andere bestanden in dezelfde categorie gaan.
        <br />
        (Let op: indien geen andere bestanden in de categorie verschijnen er geen pijltjes)
      </div>
    ),
    target: "#button-bestand1",
    offset: -10,
  },
  {
    title: "Tweede bestand openen",
    content: "Hiermee kan je een tweede document openen om plannen of foto’s te vergelijken.",
    target: "#button-bestand2",
    offset: -10,
  },
  {
    title: (
      <div>
        <h3>Succes!</h3>
        <img src="/assets/img/tour-end.png" height="42" width="42" />
      </div>
    ),
    content: "Je weet nu helemaal je weg in Geoportaal.",
    target: "#tour",
    offset: -10,
    styles: {
      tooltipTitle: {
        textAlign: "center",
        margin: 0,
      },
    },
  },
];

export const MetenSteps = [
  {
    title: "Rondleiding Meten en Annoteren",
    content: (
      <div>
        Om te starten met meten of annoteren op bestanden is het aan te raden deze rondleiding te volgen.
        <br />
        Ter info: De functies Meten en Annoteren zijn enkel voorzien voor Dossierbeheerders en je krijgt enkel de annotaties binnen uw eigen gemeente te zien.
        <br />
        NIEUW: De annotaties worden onmiddellijk zichtbaar op de documenten bij uw collega’s (geen refresh van pagina nodig).
        <br />
        <br />
        Je kan ook later de rondleiding starten via:
        <br />
        <HelpIcon /> &gt; Rondleiding:
      </div>
    ),
    disableBeacon: true,
    target: "#tour",
    offset: -10,
  },
  {
    title: "Meten",
    content:
      "Je kan een afstandsmeting (tussen 2 punten), een omtrek of een oppervlakte meting uitvoeren. Selecteer de getekende meting om de details rechtsonder weer te geven of om opmerkingen toe te voegen.",
    target: "#meten",
    offset: 52,
  },
  {
    title: "Annoteren",
    content:
      "Selecteer een van de annotatie functies om deze op het document uit te voeren. Na het tekenen kan je de annotatie selecteren om er een notitie bij te schrijven, de opmaak te wijzigen of de annotatie te verwijderen.",
    target: "#annoteren",
    disableBeacon: true,
    offset: 52,
  },
  {
    title: "Notities",
    content: (
      <div>
        Het paneel Notities bevat een overzicht van de annotaties en metingen van u en uw collega’s met het tijdstip en de mogelijkheid om opmerkingen bij te noteren.
        <br />
        Alle metingen, annotaties en notities worden onmiddellijk zichtbaar op het document bij uw collega’s.
        <br />
        Tip: via de notities is het eenvoudig om annotaties en metingen te selecteren (of te verwijderen)
      </div>
    ),
    target: "#top-right",
    disableBeacon: true,
    placement: "right",
    offset: 30,
  },
  {
    title: "Downloaden",
    content: (
      <div>
        Onder deze menu <SettingsIcon /> kan je het bestand (met annotaties) downloaden. De notities worden eveneens opgeslagen in het bestand en zijn te raadplegen via een pdf
        reader.
      </div>
    ),
    target: "#downloaden",
    disableBeacon: true,
    placement: "bottom-end",
    offset: 52,
  },
  {
    title: (
      <div>
        <h3>Succes!</h3>
        <img src="/assets/img/tour-end.png" height="42" width="42" />
      </div>
    ),
    content: "Je weet nu helemaal je weg in Geoportaal.",
    target: "#tour",
    disableBeacon: true,
    offset: -10,
    styles: {
      tooltipTitle: {
        textAlign: "center",
        margin: 0,
      },
    },
  },
];
