/* eslint-disable func-names */
import { action, decorate, observable } from "mobx";

class NotifierStore {
  notifications: any[] = [];

  enqueueSnackbar = (note) => {
    this.notifications.push({
      key: new Date().getTime() + Math.random(),
      ...note,
    });
  };

  removeSnackbar = (key) => {
    function matchesEl(el, key) {
      return el.key === key;
    }
    this.notifications.splice(this.notifications.findIndex(matchesEl, key), 1);
  };
}

decorate(NotifierStore, {
  notifications: observable,
  enqueueSnackbar: action.bound,
  removeSnackbar: observable,
});

export default new NotifierStore();
