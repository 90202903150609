import { createTheme } from "@mui/material/styles";

let light = createTheme({
  palette: {
    primary: {
      main: "#607d8b",
    },
    secondary: {
      main: "#00ACC1",
    },
    icon: {
      main: "#000",
      sec: "#00ACC1",
    },
    background: {
      default: "#fafafa",
    },
  },
  typography: {
    useNextVariants: true,
  },
  breakpoints: {
    values: { xs: 0, sm: 568, md: 960, lg: 1264, xl: 1904 },
  },
});

let dark = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#607d8b",
    },
    secondary: {
      main: "#00ACC1",
    },
    icon: {
      main: "#FFF",
      sec: "#00ACC1",
    },
    background: {
      default: "#fafafa",
    },
  },
  typography: {
    useNextVariants: true,
  },
  breakpoints: {
    values: { xs: 0, sm: 568, md: 960, lg: 1264, xl: 1904 },
  },
});

export { light, dark };
