import { observable, action, decorate } from "mobx";
import WebViewer, { WebViewerInstance } from "@pdftron/webviewer";
import AuthStore from "../AuthStore";
import axios from "axios";
import { postAnnotation } from "../../services/api";
import download from "downloadjs";

const { API_URL, PDFTRON_LICENSE_KEY } = window.env;

/**
 * model used for saving pdftron instances
 *
 */
class DocViewerModel {
  constructor(authStore: AuthStore, socket: any) {
    this.authStore = authStore;
    this.socket = socket;
  }

  authStore: AuthStore;
  socket: any;

  instance: WebViewerInstance | null;

  annotManager: any;

  annotationList: any = {};

  file: string;
  nis: string;
  projectnummer: string;

  setFile = (newFile) => {
    this.file = newFile;
  };

  setNis = (newNis) => {
    this.nis = newNis;
  };

  setProjectnummer = (newProjectnummer) => {
    this.projectnummer = newProjectnummer;
  };

  getFile = () => {
    return this.file;
  };

  getNis = () => {
    return this.nis;
  };

  getProjectnummer = () => {
    return this.projectnummer;
  };

  createViewer = (viewer) => {
    const role = this.authStore.role;
    //console.log("aaa Creating viewer", viewer);
    WebViewer(
      {
        path: "/webviewer/lib",
        fullAPI: true, // https://www.pdftron.com/documentation/web/guides/full-api/
        enableMeasurement: true,
        backendType: "ems", //https://www.pdftron.com/documentation/web/faq/pnacl-modules
        disabledElements: ["stickyToolButton", "calloutToolButton", "outlinesPanelButton", "outlinesPanel"],
        enableFilePicker: false,
        preloadWorker: "pdf",
        annotationUser: this.authStore.user.given_name + " " + this.authStore.user.family_name,
        enableAnnotations: role === "Dossierbeheerder",
        licenseKey: PDFTRON_LICENSE_KEY,
      },
      //@ts-ignore
      viewer.current,
    ).then((instance) => {
      instance.UI.setLanguage("nl");
      const { documentViewer, annotationManager, Annotations } = instance.Core;
      this.annotManager = annotationManager;

      //@ts-ignore
      instance.disablePrint();

      //disable download omdat we custom header moeten plaatsen, zie knop hieronder
      //@ts-ignore
      instance.disableDownload();
      console.log("Disabling download");

      //@ts-ignore
      const document = instance.UI.iframeWindow.document;

      if (role === "Dossierbeheerder") {
        //@ts-ignore
        instance.UI.setHeaderItems((header) => {
          const menu = document.createElement("div");
          menu.classList.add("Overlay");
          menu.classList.add("FlyoutMenu");
          menu.style.padding = "1em";

          const renderCustomMenu = () => {
            const menuBtn = document.createElement("button");
            menuBtn.textContent = "Download";

            menuBtn.onclick = () => {
              annotationManager.exportAnnotations().then((xfdfString) => {
                const doc = documentViewer.getDocument();
                doc.getFileData({ xfdfString }).then((data) => {
                  const myFile = this.getFile();
                  //const ext = myFile.split(".").pop()?.toLowerCase();
                  const arr = new Uint8Array(data);
                  // Hardcoded als pdf downloaden anders kunnen de images niet gedownload worden.
                  const blob = new Blob([arr], { type: "application/pdf" });
                  download(blob, myFile + ".pdf", "application/pdf");
                });
              });
              /*
                OUDE CODE -> download rechtstreeks van de API maar bevat geen annotaties
              */
              // const myFile = this.getFile();
              // const url = `${API_OMGEVING_URL}document_viewer/2019/${myFile}`;
              // return axios
              //   .get(url, {
              //     headers: {
              //       Authorization: "Bearer " + this.authStore.token,
              //       mode: "no-cors"
              //     },
              //     responseType: "blob"
              //   })
              //   .then(response => {
              //     const content = response.headers["content-type"];
              //     console.log(content);
              //     download(response.data, myFile, content);
              //   })
              //   .catch(error => console.log(error));
            };

            return menuBtn;
          };

          const newCustomElement = {
            type: "customElement",
            render: renderCustomMenu,
          };

          header.push(newCustomElement);
        });

        instance.UI.setMeasurementUnits({
          from: ["cm"],
          to: ["cm"],
        });

        documentViewer.addEventListener("documentLoaded", async () => {
          const myFile = this.getFile();
          const myNis = this.getNis();
          const myProjectnummer = this.getProjectnummer();
          //const url = `${API_URL}document_viewer/2019/annotations/${myFile}`;
          //const url = "http://localhost:3000/omgeving/23002/OMV_2021164348/f7bea531-039c-47cc-bdc5-a0a4648c735b_Briefovername.pdf"
          const url = `${API_URL}/omgeving/${myNis}/${myProjectnummer}/${myFile}`;

          try {
            const { data } = await axios.get(url);
            for (const annot of data) {
              const annotations = await this.annotManager.importAnnotCommand(annot.xfdfString);
              this.annotManager.drawAnnotationsFromList(annotations);
              this.annotationList[annot.annotationId] = {
                annotationId: annot.annotationId,
                xfdfString: annot.xfdfString,
                state: annot.state,
              };
            }
          } catch (error) {
            console.log("ERROR: getAnnotations", error.toString());
          }
        });

        this.annotManager.addEventListener("annotationChanged", async (annotations, action, event) => {
          if (event.imported) return;
          const myFile = this.getFile();
          const xfdfString = await this.annotManager.exportAnnotCommand();
          annotations.forEach((annot) => {
            this.annotationList[annot.annotationId] = {
              annotationId: annot.Id,
              xfdfString,
              state: action,
            };
            postAnnotation(myFile, annot.Id, xfdfString, action);
            this.socket.emit("update", {
              file: myFile,
              xfdfString,
            });
          });
        });
      }

      this.instance = instance;
    });
  };

  loadDocument = (file, bestanden, nis, projectnummer) => {
    console.log("load document", file);
    const instance = this.instance;
    this.setFile(file);
    this.setNis(nis);
    this.setProjectnummer(projectnummer);
    //const url = "http://localhost:3000/omgeving/23002/OMV_2021164348/f7bea531-039c-47cc-bdc5-a0a4648c735b_Briefovername.pdf"

    let useFile = file;
    if (file.substr(-4).toLowerCase() === ".dxf") {
      useFile = file.replace(/.dxf$/i, ".pdf");
    }
    const url = `${API_URL}/omgeving/${nis}/${projectnummer}/${useFile}`;
    //const url = `${API_URL}document_viewer/2019/${file}`;
    const options = {
      customHeaders: {
        Authorization: "Bearer " + this.authStore.token,
      },
      //withCredentials: true
    };
    console.log("load document");
    instance?.UI.loadDocument(url, options);
    console.log("document loaded");

    if (this.authStore.role === "Dossierbeheerder") {
      let inverse_schaal = null;
      for (var key in bestanden) {
        if (key !== "Plannen") {
          bestanden[key].forEach((bestand) => {
            if (bestand.lokale_uri === file) {
              inverse_schaal = bestand.inverse_schaal;
            }
          });
        } else {
          for (var key2 in bestanden[key]) {
            bestanden[key][key2].forEach((bestand) => {
              if (bestand.lokale_uri === file) {
                inverse_schaal = bestand.inverse_schaal;
              }
            });
          }
        }
      }

      //@ts-ignore
      const { documentViewer } = instance?.Core;
      const distanceMeasurementTool = documentViewer.getTool("AnnotationCreateDistanceMeasurement");
      const perimeterMeasurementTool = documentViewer?.getTool("AnnotationCreatePerimeterMeasurement");
      const areaMeasurementTool = documentViewer?.getTool("AnnotationCreateAreaMeasurement");
      distanceMeasurementTool?.setStyles(function () {
        return {
          // value of Scale is an array that is consisted of two arrays
          // the first element in each array is the scale ratio and the second element is the unit.
          // valid units are: mm, cm, m, km, mi, yd, ft, in and pt
          // the following array means that for the annotations created by the distance measurement tool, 0.25 inches on the document is equal to 1 inch in the real world
          Scale: [
            [1, "cm"],
            [inverse_schaal !== null ? inverse_schaal : 1, "cm"],
          ],
          // value of Precision is a number that means how many decimal places the calculated value should have
          Precision: 0.01,
        };
      });
      perimeterMeasurementTool?.setStyles(function () {
        return {
          // value of Scale is an array that is consisted of two arrays
          // the first element in each array is the scale ratio and the second element is the unit.
          // valid units are: mm, cm, m, km, mi, yd, ft, in and pt
          // the following array means that for the annotations created by the distance measurement tool, 0.25 inches on the document is equal to 1 inch in the real world
          Scale: [
            [1, "cm"],
            [inverse_schaal !== null ? inverse_schaal : 1, "cm"],
          ],
          // value of Precision is a number that means how many decimal places the calculated value should have
          Precision: 0.01,
        };
      });
      areaMeasurementTool?.setStyles(function () {
        return {
          // value of Scale is an array that is consisted of two arrays
          // the first element in each array is the scale ratio and the second element is the unit.
          // valid units are: mm, cm, m, km, mi, yd, ft, in and pt
          // the following array means that for the annotations created by the distance measurement tool, 0.25 inches on the document is equal to 1 inch in the real world
          Scale: [
            [1, "cm"],
            [inverse_schaal !== null ? inverse_schaal : 1, "cm"],
          ],
          // value of Precision is a number that means how many decimal places the calculated value should have
          Precision: 0.01,
        };
      });
    }
  };

  clearInstance = () => {
    this.instance = null;
  };
}

export default DocViewerModel;

decorate(DocViewerModel, {
  instance: [observable],
  annotManager: [observable],
  createViewer: action.bound,
  setFile: action.bound,
  clearInstance: action.bound,
});
