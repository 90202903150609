import { withStyles } from "@mui/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import HelpIcon from "@mui/icons-material/Help";
import PersonIcon from "@mui/icons-material/Person";
import { Dialog, DialogContent, DialogTitle, IconButton, Link, List, ListItem, ListItemText, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AutoComplete, Header, HeaderFab } from "@orbit/components";
import { StoresContext } from "index";
import { useHistory } from "react-router-dom";
import { observer as hooksObserver } from "mobx-react-lite";
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useParams } from "react-router";
import { ROUTE_BASE } from "routes/RouteList";
import { fetchMunicipality, searchHeader } from "services/api";
import { getUserRights } from "services/auth";
import { firstLetterUpperCase } from "../../utils";
import useHeaderViewStyles, { useTextFieldStyles } from "./HeaderViewStyles";
import { BestandenSteps, HeaderSteps, InfoStepsInBehandeling, InfoStepsIngediend, InfoStepsOmgevingsloket, MetenSteps } from "./steps";

interface Props {
  classes: any;
}

interface SearchProps {
  municipality: string;
}

interface RightsType {
  role: string;
  first: string;
  scope: string;
  env: string;
  scopeName: string;
}

const AUTH_URL = window.env.AUTH_URL;

const goToACMIDM = () => {
  window.location.href = `${window.env.AUTH_URL ?? "https://ti.auth.geoportaal.be/"}login`;
};

const goToDoc = () => {
  window.open("https://wiki.geoportaal.be/nl/Omgeving/publiek");
};

const goToAbout = () => {
  window.open("http://www.geoportaal.be/homepage/over-geoportaal/");
};

const SearchComp: FunctionComponent<SearchProps> = hooksObserver(({ municipality }) => {
  const {
    mapStore: { setSearchLocation },
  } = useContext(StoresContext);

  const [entries, setEntries] = useState([]);
  const onSelectValue = async (item) => {
    if (item.type === "street") {
      setSearchLocation(item.location);
    }
  };

  const classes = useTextFieldStyles();
  console.log(classes);

  return (
    <div id="tour-adres-zoeken" style={{ flexGrow: 1 }}>
      <AutoComplete
        id="searchComp"
        placeholder={`Zoeken in ${municipality.charAt(0).toUpperCase() + municipality.slice(1)}`}
        noResult="Geen resultaten"
        entries={entries}
        // variant="outlined"
        // classes={classes}
        onChange={async (value) => onSelectValue(value)}
        apiCall={async (value) => {
          const data = await searchHeader(municipality, value);
          setEntries(data);
        }}
      />
    </div>
  );
});

const HeaderView: FunctionComponent<Props> = hooksObserver(({ classes }) => {
  const {
    uiStore: { sideBarOpen, setSideBarOpen, showTour, setShowTour, tourIndex, setTourIndex, getMunicipalityMapData, projectDetail: { projectDetail } },

    authStore: { doLogout, isLoggedIn, sessionId, role, checkIfMunicipalityExists },
    authStore,
    mapStore: { nis, setLatLng, setZoomLevel },
    routingStore: { push },
  } = useContext(StoresContext);

  const { municipality, project, file1, file2 } = useParams<{ municipality: string; project: string; file1: string; file2: string }>();
  const [dialog, setDialog] = useState(false);
  const [rights, setRights] = useState<RightsType[]>([]);
  const [munGeometry, setMunGeometry] = useState<any>(null);
  const [sidebarSelected, setSidebarSelected] = useState<any>();
  let history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const getMunicipalityView = () => {
    const { lat, lng, zoom } = getMunicipalityMapData(municipality);
    setLatLng({
      lat,
      lng,
    });
    setZoomLevel(zoom);
    history.push(ROUTE_BASE + municipality);
  };

  useEffect(() => {
    if (file1 || file2) {
      setSidebarSelected(BestandenSteps);
      if (role === "Dossierbeheerder") setSidebarSelected(MetenSteps);
    } else if (project) {
      if (["Openbaar Onderzoek", "Beslissing"].includes(projectDetail?.dossierstatussen[0]?.statusgeoportaal)) {
        setSidebarSelected(InfoStepsOmgevingsloket);
      } else if (
        (projectDetail?.dossierstatussen[0]?.statusgeoportaal === "Ingediend" && ["Dossierbeheerder", "Administrator", "Beleidsmaker", "Gemeenteraadslid"].includes(role)) ||
        (projectDetail?.dossierstatussen[0]?.statusgeoportaal === "In Behandeling" && ["Administrator", "Beleidsmaker", "Gemeenteraadslid", "Burgerloket"].includes(role))
      ) {
        setSidebarSelected(InfoStepsIngediend);
      } else if (role === "Dossierbeheerder" && projectDetail?.dossierstatussen[0]?.statusgeoportaal === "In Behandeling") {
        setSidebarSelected(InfoStepsInBehandeling);
      }
    } else {
      setSidebarSelected(HeaderSteps);
    }
  }, [project, projectDetail, file1, file2]);

  useEffect(() => {
    if (!checkIfMunicipalityExists(municipality)) {
      push(ROUTE_BASE);
    }
  }, [municipality]);

  useEffect(() => {
    const fetchMun = async () => {
      const munData = await fetchMunicipality(nis, "actual");
      const geom = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: JSON.parse(munData.geometry.boundingBox).coordinates,
        },
      };
      setMunGeometry(geom);
    };
    fetchMun();
  }, [nis]);

  const fetchRights = async () => {
    const data = await getUserRights(sessionId);
    setRights(data);
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      // this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
      setTourIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || [ACTIONS.CLOSE].includes(action)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setShowTour(false);
      setTourIndex(0);
    }
  };

  const selectProfile = async (right: any) => {
    window.location.href = `${AUTH_URL}userights?s=${right.scope}&r=${right.role}&id=${sessionId}`;
  };
  const annoteren = <div id="annoteren" style={{ width: 100 }}></div>;
  const districtFab = (
    <HeaderFab
      id="top-right"
      fabmenuid="app-district"
      fabtitle={municipality ? municipality : ""}
      fabicon={
        <BusinessIcon
          style={{
            fontSize: "1.5rem",
            marginLeft: 12,
          }}
        />
      }
      fabaction={getMunicipalityView}
    />
  );
  const meten = <div id="meten"></div>;

  let fabActions: { title: string; funct: () => void }[] = [];
  if (!matches) {
    fabActions.push({
      title: `${showTour ? "Sluit" : "Open"} rondleiding`,
      funct: () => {
        setShowTour(!showTour);
      },
    });
  }

  fabActions.push({
    title: "Documentatie",
    funct: () => goToDoc(),
  });
  fabActions.push({
    title: "Over Geoportaal Omgeving",
    funct: () => goToAbout(),
  });

  const infoFab = <HeaderFab fabmenuid="app-help" fabtitle="" id="tour" fabicon={<HelpIcon className={classes.helpbutton} />} fabactions={fabActions} />;
  const downloaden = <div style={{ width: 10 }} id="downloaden"></div>;
  const roleFab = isLoggedIn ? (
    <HeaderFab
      fabmenuid="app-profile"
      fabtitle={authStore.role.toLowerCase() === "burger online" ? "" : authStore.role}
      fabicon={<AccountCircleIcon className={classes.userbutton} />}
      fabactions={
        role === "Burgerloket"
          ? [
              {
                title: "Uitloggen",
                funct: (e: any) => doLogout(),
              },
            ]
          : [
              {
                title: "Uitloggen",
                funct: (e: any) => doLogout(),
              },
              {
                title: "Profiel wijzigen",
                funct: (e: any) => {
                  fetchRights();
                  setDialog(!dialog);
                },
              },
            ]
      }
    />
  ) : (
    <Typography>
      <Link href={`${window.env.AUTH_URL}login`}>
        <IconButton onClick={goToACMIDM} className={classes.userbutton}>
          <PersonIcon />
        </IconButton>
      </Link>
    </Typography>
  );

  return (
    <Fragment>
      <Header
        data-test-id="set-sidebar-open"
        applicationTitle={"Geoportaal Omgeving"}
        sideBarOpen={sideBarOpen}
        setSideBarOpen={setSideBarOpen}
        SearchComponent={file1 ? () => <></> : () => <SearchComp municipality={municipality} />}
        headerFabs={role === "Dossierbeheerder" || role === "Gemeenteraadslid" ? [annoteren, roleFab, meten, infoFab, districtFab, downloaden] : [roleFab, infoFab, districtFab]}
      />
      <Dialog open={dialog}>
        <DialogTitle className={classes.dialogTitle}>Selecteer profiel</DialogTitle>
        <DialogContent>
          <List>
            {rights.map((right: any, index) => (
              <ListItem
                key={index}
                button
                onClick={(e) => {
                  selectProfile(right);
                }}
              >
                <ListItemText primary={firstLetterUpperCase(right.role)} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
      {!matches && (
        <Joyride
          stepIndex={tourIndex}
          callback={handleJoyrideCallback}
          steps={sidebarSelected}
          continuous={true}
          run={showTour}
          showProgress={true}
          showSkipButton={true}
          disableOverlay={true}
          disableScrolling={true}
          locale={{
            back: "Terug",
            close: "Sluiten",
            last: "Einde",
            next: "Volgende",
            skip: "Stoppen",
          }}
          styles={stepStyles}
        />
      )}
    </Fragment>
  );
});

export default withStyles(useHeaderViewStyles)(HeaderView);

const stepStyles: any = {
  options: {
    arrowColor: "#607d8b",
    overlayColor: "#fff",
    primaryColor: "#d32f2f",
    backgroundColor: "#607d8b",
    textColor: "#fff",
    width: undefined,
    zIndex: 10000,
  },
  tooltipContainer: {
    textAlign: "left",
  },
  tooltipFooter: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: 15,
  },
  buttonNext: {
    backgroundColor: "#607d8b",
    border: "solid 1px #fff",
    borderRadius: 4,
    color: "#fff",
  },
  buttonBack: {
    color: "#fff",
    marginLeft: "auto",
    border: "solid 1px #fff",
    borderRadius: 4,
    marginRight: 5,
  },
  buttonSkip: {
    color: "#fff",
    border: "solid 1px #fff",
    borderRadius: 4,
    fontSize: 14,
  },
  tooltipTitle: {
    fontSize: 16,
    padding: "0 10px",
  },
  tooltipContent: {
    padding: "0 10px",
    fontSize: 14,
  },
};
