import { LngLatBounds } from "mapbox-gl";
import React, { useEffect, useMemo } from "react";
import { FlyToInterpolator, Layer, LayerProps, MapContext, Source, WebMercatorViewport } from "react-map-gl";
import { clusterLayer, spiderPointLayer } from "./layers";

export const Spiderify = function ({ onViewportChange, onClickItem }) {
  const { map, eventManager, viewport } = React.useContext(MapContext);
  const { zoom, width, height } = viewport || { zoom: 0, width: 0, height: 0 };
  const [internalFeatures, setInternalFeatures] = React.useState([]);
  const [position, setPosition] = React.useState({ lat: 0, lng: 0 });
  const { lat, lng } = position;

  const { items } = useMemo(() => {
    return getPositions({ total: internalFeatures.length, map, lat, lng, zoom });
  }, [internalFeatures]);

  useEffect(() => {
    if (eventManager) {
      const clickCallback = (event) => {
        const { offsetCenter } = event;
        const features = map.queryRenderedFeatures([offsetCenter.x, offsetCenter.y]).filter((item) => ["cluster-pins", "cluster-spiderify"].includes(item.source));
        if (features.length === 0) {
          return;
        }

        const pointCount = features[0].properties.point_count;

        if (!pointCount) {
          onClickItem(features[0].properties);
          return;
        }

        if (pointCount <= 1) {
          return;
        }

        map.getSource(clusterLayer.source).getClusterLeaves(features[0].properties.cluster_id, pointCount, 0, (err, leafFeatures) => {
          if (err) {
            return console.error("error while getting leaves of a cluster", err);
          }
          console.log({ leafFeatures });
          if (zoom < 17) {
            const coordinates = leafFeatures.map((f) => f.geometry.coordinates);
            const bounds = fitBoundsMap(coordinates, height, width, 50, 18);

            onViewportChange({
              ...viewport,
              ...bounds,
              transitionDuration: "auto",
              transitionInterpolator: new FlyToInterpolator(),
            });
          } else {
            setPosition({ lat: features[0].geometry.coordinates[1], lng: features[0].geometry.coordinates[0] });
            setInternalFeatures(leafFeatures);
          }
        });
      };

      eventManager.on("click", clickCallback);
      return () => {
        eventManager.off("click", clickCallback);
      };
    }
    return undefined;
  }, [eventManager, map, onViewportChange, viewport, zoom, height, width]);

  useEffect(() => {
    const zoomStartHandler = (event) => {
      if (internalFeatures.length > 0) {
        setInternalFeatures([]);
      }
    };

    if (map) {
      map.on("zoomstart", zoomStartHandler);
      return () => {
        map.off("zoomstart", zoomStartHandler);
      };
    }
    return undefined;
  }, [map, internalFeatures]);

  const lines: GeoJSON.FeatureCollection<GeoJSON.Geometry> = useMemo(() => {
    return {
      type: "FeatureCollection",
      features: items.map((item, index) => ({
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: [
            [item?.lng, item?.lat],
            [lng, lat],
          ],
        },
      })),
    };
  }, [items, lat, lng]);

  const markers: GeoJSON.FeatureCollection<GeoJSON.Geometry> = useMemo(() => {
    return {
      type: "FeatureCollection",
      features: items.map((item, index) => {
        const temp = internalFeatures[index] as any;

        return {
          type: "Feature",
          properties: temp?.properties || {},
          geometry: {
            type: "Point",
            coordinates: [item.lng, item.lat],
          },
        };
      }),
    };
  }, [items]);

  return (
    <>
      {
        <Source key="spider-line-source" id="my-data" type="geojson" data={lines}>
          <Layer key="spider-line-layer" {...spiderLineLayer} />
        </Source>
      }
      {
        <Source id="cluster-spiderify" key="spider-marker-source" type="geojson" data={markers}>
          {<Layer key="spider-marker-layer" {...spiderPointLayer} source="cluster-spiderify" />}
        </Source>
      }
    </>
  );
};

/**
 * devide circle into segments and return the coordinates of the segment
 */
function getPositions({ total, map, lat, lng, zoom }) {
  const position = map.project([lng, lat], zoom);
  const radius = 40;
  let items: { lat: number; lng: number }[] = [];
  // if lower then 12 then we go into the circle pattern
  if (total < 12) {
    let lg_rad = total > 6 ? radius * 2 : radius;
    for (let i = 1; i <= total; ++i) {
      let angle = (i * (2 * Math.PI)) / total;
      let x = Math.cos(angle) * lg_rad;
      let y = Math.sin(angle) * lg_rad;
      items.push({ lat: x, lng: y });
    }

    // if larger than 12 then we go into the grid pattern
  } else {
    const itemSize = 45;
    const gridSize = Math.floor(Math.sqrt(total));
    const offSet = (gridSize * itemSize) / 2;

    for (let i = 0; i < total; i++) {
      let x = (i % gridSize) * itemSize - offSet;
      let y = Math.floor(i / gridSize) * itemSize - offSet;
      items.push({ lat: x, lng: y });
    }
  }

  items = items.map((off) => map.unproject([position.x + off.lat, position.y + off.lng], zoom));
  console.log({ items });
  return { position, items };
}

const fitBoundsMap = (coordinates, mapHeight, mapWidth, padding = 75, maxZoom = 23) => {
  // Create a 'LngLatBounds' with both corners at the first coordinate.
  let bounds: LngLatBounds = new LngLatBounds(coordinates[0], coordinates[0]);

  // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
  for (const coord of coordinates) {
    bounds.extend(coord);
  }

  const mercatorViewport = new WebMercatorViewport({ height: mapHeight, width: mapWidth });

  const mercatorBounds: [[number, number], [number, number]] = [
    [bounds.getNorthEast().lat, bounds.getNorthEast().lng],
    [bounds.getSouthWest().lat, bounds.getSouthWest().lng],
  ];
  const bound = mercatorViewport.fitBounds(mercatorBounds, { padding });

  return {
    longitude: bound.latitude,
    latitude: bound.longitude,
    zoom: Math.min(maxZoom, bound.zoom),
  };
};

export const spiderLineLayer = {
  id: "lineLayer",
  type: "line",
  source: "my-data",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "orange",
    "line-width": 4,
    "line-opacity": 0.5,
    "line-dasharray": [0.5, 1],
    "line-dasharray-transition": {
      duration: 1,
    },
  },
} as LayerProps;

export const spiderDeBugPointLayer = {
  source: "cluster-spiderify",
  id: "cluster-test",
  type: "circle",
  paint: {
    "circle-radius": 3,
    "circle-color": "white",

    "circle-stroke-width": 3,
    "circle-stroke-color": "black",
  },
} as LayerProps;
