import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { firstLetterUpperCase } from "../../../utils";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Dialog,
  DialogTitle,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { StoresContext } from "index";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import React, { FunctionComponent, useContext, useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import { DataType } from "stores/models/ProjectDetailModel";
import useSidebarTekstveldStyles from "./SidebarTekstveldStyles";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const sidebarTekstveld: FunctionComponent<{
  classes: any;
}> = observer(({ classes }) => {
  const {
    uiStore: { sideBarTekstveldOpen, setSideBarTekstveldOpen, projectDetail: { projectDetail } },
    routingStore: { push },
  } = useContext(StoresContext);

  const [modalData, setModalData] = useState<{ title: string; index: number; data: DataType } | null>();
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setTabIndex(index);
  };

  const closeTekstvelden = () => {
    setSideBarTekstveldOpen(false);
  };

  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleClose = () => {
    setModalData(null);
  };

  useEffect(() => {
    setTabIndex(0);
  }, [modalData]);

  return (
    <Drawer
      className={matches ? classes.drawerMobile : classes.drawer}
      variant="persistent"
      anchor="left"
      open={sideBarTekstveldOpen}
      classes={{
        paper: matches ? classes.drawerPaperMobile : classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <Typography data-test-id="title-tekstveld" variant="h6" className={classes.whiteText}>
          Kies een tekstveld
        </Typography>
        <div className={classes.grow} />
        <IconButton
          edge="end"
          sx={{ marginLeft: 13 }}
          color="inherit"
          aria-label="back"
          className={classes.closeButton}
          onClick={(e) => {
            closeTekstvelden();
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div>
        {projectDetail?.datablokken?.map((tekst, index) => (
          <>
            <Accordion data-test-id="accordion" key={index} square expanded={expanded === tekst.aard} onChange={handleChange(tekst.aard)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
                <Typography
                  noWrap
                  sx={{
                    width: 250,
                    maxWidth: "100%",
                  }}
                  variant="subtitle2"
                >
                  {firstLetterUpperCase(tekst.aard)}
                </Typography>
                <Typography variant="subtitle2">({tekst.data.length})</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <List className={classes.root}>
                  {tekst.data.map((item, index) => {
                    return (
                      <ListItemButton
                        key={tekst.aard + index}
                        role={undefined}
                        dense
                        onClick={() => {
                          console.log({ title: tekst.aard, data: toJS(item), index, tekst: toJS(tekst) });
                          setModalData({ title: tekst.aard, data: item, index });
                        }}
                      >
                        <ListItemText primary={firstLetterUpperCase(`${tekst.aard}_${index + 1}`)} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="visibilties">
                            <VisibilityIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
          </>
        ))}
        {modalData && (
          <Dialog data-test-id="dialog" open={true} onClose={handleClose} maxWidth="md" fullWidth scroll="paper" aria-labelledby="form-dialog-title">
            <DialogTitle data-test-id="dialog-title" sx={{ backgroundColor: "#E0E0E0", color: "primary" }} id="form-dialog-title">
              Tekstveld: {firstLetterUpperCase(modalData.title)} {modalData.index + 1}
            </DialogTitle>
            <Box sx={{ bgcolor: "background.paper" }}>
              <AppBar position="static" color="secondary" sx={{ color: "#ffffff" }}>
                <Tabs
                  orientation={matches ? "vertical" : "horizontal"}
                  value={tabIndex}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="inherit"
                  aria-label="full width tabs example"
                >
                  {Object.keys(modalData.data).map((item, index) => {
                    return <Tab label={item} />;
                  })}
                </Tabs>
              </AppBar>
              <SwipeableViews style={{ margin: 20 }} axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={tabIndex} onChangeIndex={handleChangeIndex}>
                {Object.entries(modalData.data).map(([key, value], index) => {
                  return (
                    <TabPanel value={tabIndex} index={index} dir={theme.direction}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: value,
                        }}
                      ></div>
                    </TabPanel>
                  );
                })}
              </SwipeableViews>
            </Box>
          </Dialog>
        )}
      </div>
    </Drawer>
  );
});

export default withStyles(useSidebarTekstveldStyles)(sidebarTekstveld);
