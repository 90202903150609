import React from "react";
import area from "@turf/area";
import length from "@turf/length";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import useControlPanelStyles from "./ControlPanelStyles";

const ControlPanel = ({ features, classes }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  if (!features) {
    return null;
  }
  let distanceMeasure = 0;
  let polygonArea = 0;
  features?.forEach((feature) => {
    distanceMeasure += feature ? length(feature, { units: "meters" }) : 0;
    polygonArea += feature ? area(feature) : 0;
  });

  return (
    <div className={matches ? classes.controlPanelMobile : classes.controlPanel}>
      {polygonArea > 0 && (
        <>
          Totale oppervlakte: {polygonArea.toFixed(2)} m²
          <br />
        </>
      )}
      {distanceMeasure && `Totale afstand: ${distanceMeasure.toFixed(2)} m `}
    </div>
  );
};
export default withStyles(useControlPanelStyles)(ControlPanel);
