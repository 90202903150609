declare global {
  interface Window {
    env: any;
  }
}

window.env = window.env || {};

export enum APP_LOCALES {
  NL = "nl",
  FR = "fr",
}

export const DEFAULT_LOCALE = APP_LOCALES.NL;

export const MAP_TYPES = {
  WMS: "wmt",
  WMTS: "wmts",
  TILE: "tile",
};

export const MAP_TILE_MATRIX = {
  GOOGLE: "GoogleMapsVL",
  G20: "g20",
};

export const MAP_TILE_FORMAT = {
  PNG: "image/png",
};

export const MAP_CONFIG = {
  zoom: window.env.MAP_WMTS_TILE_LAYER_DEFAULT_ZOOM || 15,
};

export const HEADER_CONFIG = {
  SEARCH_ENABLED: window.env.SEARCH_ENABLED || true,
  SEARCH_RESULT_COUNT: 8,
};

export const SIDEBAR_CONFIG = {
  width: 350,
  height: 120,
};

export const Z_INDEXES = {
  MAP_PUBLIC_WMTS_TILE_LAYER: 100,
  MAP_PUBLIC_TILE_LAYER: 101,
  MAP_PUBLIC_WMTS_TILE_LAYER_ORG: 102,
  MAP_ORBIT_LOGO: 1000,
};
