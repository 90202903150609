import { SIDEBAR_CONFIG } from "../../constants";
import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
const useMunicipalityBaseStyles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100%",
      display: "flex",
      flexDirection: "row",
    },
    content: {
      flexGrow: 1,

      marginLeft: -SIDEBAR_CONFIG.width,
    },
    contentShift: {
      marginLeft: 0,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    munContainer: {
      padding: theme.spacing(3),
    },
  });

export default useMunicipalityBaseStyles;
