import axios from "axios";
import { create } from "mobx-persist";
import { RouterStore } from "mobx-react-router";
import { SnackbarProvider, SnackbarKey, SnackbarMessage } from "notistack";
import React, { createContext } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import AuthStore from "./stores/AuthStore";
import DocViewerStore from "./stores/DocViewerStore";
import MapStore from "./stores/MapStore";
import ProjectStore from "./stores/ProjectStore";
import UiStore from "./stores/UiStore";
import NotifierStore from "./views/notifier/NotifierStore";
import SnackMessage from "./views/notifier/SnackMessage";

// setup all stores
const routingStore = new RouterStore();
const uiStore = new UiStore();
const mapStore = new MapStore();
const authStore = new AuthStore();
const projectStore = new ProjectStore();
const docViewerStore = new DocViewerStore(authStore);
export const notifierStore = NotifierStore;

const stores = {
  routingStore,
  uiStore,
  authStore,
  mapStore,
  projectStore,
  docViewerStore,
  notifierStore,
};

export const StoresContext = createContext(stores);

const persistStores = async () => {
  const hydrate = create();
  await hydrate("uiStore", stores.uiStore);
  await hydrate("authStore", stores.authStore);
  await hydrate("mapStore", stores.mapStore);
  await hydrate("projectStore", stores.projectStore);
  await hydrate("docViewerStore", stores.docViewerStore);
};
/* 
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 404) {
      return notifierStore.enqueueSnackbar({
        message: {
          title: "Project kan niet worden weergegeven.",
          message: ["U beschikt niet over de nodige rechten voor dit project."],
          isExpanded: true
        },
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "warning",
          persist: true
        }
      });
    }
    if (error.response.status === 418) {
      return notifierStore.enqueueSnackbar({
        message: {
          title: "Project kan niet worden weergegeven.",
          message: ["De publicatie termijn van dit project is verlopen."],
          isExpanded: true
        },
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "warning",
          persist: true
        }
      });
    }
  }
); */

const renderApp = async () => {
  ReactDOM.render(<div>loading application...</div>, document.getElementById("root"));
  await persistStores();
  ReactDOM.render(
    <StoresContext.Provider value={stores}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        content={(key: SnackbarKey, message: SnackbarMessage) => {
          if (message) {
            return <SnackMessage key={key} {...message} />;
          }
          return null;
        }}
      >
        <App />
      </SnackbarProvider>
    </StoresContext.Provider>,
    document.getElementById("root"),
  );
};

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
