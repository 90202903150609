import { SIDEBAR_CONFIG } from "../../constants";
import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useBasePageStyles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100%",
      display: "flex",
      flexDirection: "row",
    },
    content: {
      flexGrow: 1,
      marginLeft: -SIDEBAR_CONFIG.width,
    },
    contentMobile: {
      marginLeft: "-100%",
      flexGrow: 1,
    },
    contentShift: {
      marginLeft: 0,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    snackError: {
      backgroundColor: theme.palette.error.dark,
    },
    snackInfo: {
      backgroundColor: theme.palette.info.dark,
    },
    snackButton: {
      color: "#ffffff",
    },
    snackIcon: {
      fontSize: 20,
      opacity: 0.9,
      marginRight: 8,
    },
    snackMessage: {
      display: "flex",
      alignItems: "center",
    },
  });

export default useBasePageStyles;
