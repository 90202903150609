import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useHeaderViewStyles = (theme: Theme) =>
  createStyles({
    Header: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    userbutton: {
      color: "#fff",
      marginLeft: 5,
    },
    helpbutton: {
      color: "#fff",
    },
    whiteText: {
      color: "white",
    },
    headerFab: {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      zIndex: 99,
      display: "flex",
      alignItems: "center",
      padding: "0 16px",
      ...theme.mixins.toolbar,
      paddingLeft: "8 !important",
    },
    borderBottom: {
      paddingBlock: "1px solid var(--border-color)",
    },
    grow: {
      flexGrow: 1,
    },
  });

export const useTextFieldStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.light,
      color: "#ffffff !important",
      borderRadius: 4,
      border: 0,
      "& input": {
        color: "#ffffff !important",
      },
      "& .MuiSvgIcon-root": {
        color: "#ffffff !important",
      },
      "& fieldset": {
        border: 0,
        color: "#ffffff",
      },
    },
  }),
);

export default useHeaderViewStyles;
