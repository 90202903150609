import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { BottomNavigation, Button, IconButton, useMediaQuery } from "@mui/material";
import { withStyles } from "@mui/styles";
import { StoresContext } from "index";
import { observer } from "mobx-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { ROUTE_BASE } from "routes/RouteList";
import useDocumentViewerStyles from "./DocumentViewerStyles";
import { useTheme } from "@mui/material/styles";

const DocumentViewer = observer(({ classes }) => {
  const {
    uiStore: { splitscreenActive, setSplitscreenActive, projectDetail: { projectDetail, navigateFiles, planType } },
    authStore: { token, role },
    routingStore: { push },
    docViewerStore: { docViewer1, docViewer1: { instance, clearInstance }, docViewer2, docViewer2: { instance: instance2, clearInstance: clearInstance2 } },
    mapStore: { nis },
  } = useContext(StoresContext);

  const [file1Paging, setFile1Paging] = useState<{ index: number; total: number }>({ index: 0, total: 0 });
  const [file2Paging, setFile2Paging] = useState<{ index: number; total: number }>({ index: 0, total: 0 });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { municipality, project, file1, file2 } = useParams<{ municipality: string; project: string; file1: string; file2: string }>();

  let viewer = useRef(null);
  let viewer2 = useRef(null);

  /**
   * returns a list of the active basis documenten or plannen
   * array of filenames
   * @param fileName
   * @returns
   */
  function getFileNames(fileName) {
    const fileList = Object.entries(projectDetail?.bestanden).reduce<string[]>((result, [key, value]) => {
      let hasFile;
      let tempFiles: string[] = [];
      if (Array.isArray(value)) {
        hasFile = value.find((item) => item.lokale_uri === fileName);
        tempFiles.push(...value.sort((a, b) => (a.bestandsnaam > b.bestandsnaam ? 1 : -1)).map((file) => file.lokale_uri));
      } else {
        if (planType === "Alle") {
          for (const items of Object.values(value)) {
            if (Array.isArray(items)) {
              tempFiles.push(...items.sort((a, b) => (a.bestandsnaam > b.bestandsnaam ? 1 : -1)).map((file) => file.lokale_uri));
            }
          }
        } else {
          tempFiles = value[planType].sort((a, b) => (a.bestandsnaam > b.bestandsnaam ? 1 : -1)).map((file) => file.lokale_uri);
        }

        hasFile = tempFiles.includes(fileName);
      }
      if (hasFile) {
        result = tempFiles;
      }

      return result;
    }, []);

    return fileList ?? [];
  }

  useEffect(() => {
    if (!projectDetail?.bestanden) {
      return;
    }
    const fileNames = getFileNames(file1);

    const index = fileNames.findIndex((file) => file === file1) + 1;
    setFile1Paging({ index, total: fileNames.length });
  }, [file1, projectDetail?.bestanden]);

  useEffect(() => {
    if (!projectDetail?.bestanden) {
      return;
    }
    const fileNames = getFileNames(file2);

    const index = fileNames.findIndex((file) => file === file2) + 1;
    setFile2Paging({ index, total: fileNames.length });
  }, [file2, projectDetail?.bestanden]);

  useEffect(() => {
    if (viewer.current && docViewer1) {
      docViewer1.createViewer(viewer);
    }
    return () => {
      clearInstance();
    };
  }, [viewer, docViewer1]);

  useEffect(() => {
    if (viewer2.current && docViewer2) {
      docViewer2.createViewer(viewer2);
    }
    return () => {
      clearInstance2();
      setSplitscreenActive(false);
    };
  }, [viewer2, docViewer2]);

  useEffect(() => {
    if (file2) {
      setSplitscreenActive(true);
    }
  }, [file2]);

  useEffect(() => {
    if (docViewer1 && instance && projectDetail && projectDetail.bestanden && file1) {
      docViewer1.loadDocument(file1, projectDetail.bestanden, nis, project);
    }
  }, [file1, projectDetail, docViewer1, instance]);

  useEffect(() => {
    if (docViewer2 && instance2 && projectDetail && projectDetail.bestanden && file2) {
      docViewer2.loadDocument(file2, projectDetail.bestanden, nis, project);
    }
  }, [file2, projectDetail, docViewer2, instance2]);

  useEffect(() => {
    if (matches && file2) {
      setSplitscreenActive(false);
    } else if (!matches && file2) {
      setSplitscreenActive(true);
    } else {
      setSplitscreenActive(false);
    }
  }, [matches, file2]);

  const toggleSplitScreen = () => {
    if (splitscreenActive) {
      setSplitscreenActive(!splitscreenActive);
      if (file2) push(`${ROUTE_BASE}${municipality}/${project}/${file1}`);
    } else {
      setSplitscreenActive(!splitscreenActive);
    }
  };

  const navigateFile = (fileNr, direction, fileName) => {
    const newFile: any = navigateFiles(direction, fileName); //direction, fileName, fileType
    if (newFile?.lokale_uri) {
      if (fileNr === 1 && !file2) push(`${ROUTE_BASE}${municipality}/${project}/${newFile.lokale_uri}`);
      if (fileNr === 1 && file2) push(`${ROUTE_BASE}${municipality}/${project}/${newFile.lokale_uri}/${file2}`);
      if (fileNr === 2) push(`${ROUTE_BASE}${municipality}/${project}/${file1}/${newFile.lokale_uri}`);
    }
  };

  return (
    <div id="docviewer" className={classes.docviewer}>
      <div id="docviewer-1-wrap" className={splitscreenActive ? classes.webviewerLeft : null}>
        <div id="docviewer-1" className={matches ? classes.webviewerMobile : classes.webviewer} ref={viewer} />
      </div>

      <div id="docviewer-2-wrap" style={splitscreenActive ? { display: "block" } : { display: "none" }} className={classes.webviewerRight}>
        <div id="docviewer-2" className={classes.webviewer} style={file2 ? { display: "block" } : { display: "none" }} ref={viewer2} />
        <div id="docviewer-2-paragraph" className={classes.webviewer} style={file2 ? { display: "none" } : { display: "block" }}>
          <p className={classes.paragraph}>Selecteer een document in de lijst om hier te openen</p>
        </div>
      </div>

      <BottomNavigation className={classes.bottomNav}>
        <div id="button-bestand1" className={classes.bottomNavContainer}>
          {file1Paging.index !== 1 && (
            <IconButton
              className={classes.bottomNavIconButton}
              size="small"
              onClick={() => {
                navigateFile(1, "prev", file1);
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          )}
          <Button
            className={classes.bottomNavButton}
            disableElevation
            size="small"
            onClick={splitscreenActive ? () => {} : () => push(`${ROUTE_BASE}${municipality}/${project}`)}
            startIcon={!splitscreenActive && <CancelPresentationIcon />}
          >
            {splitscreenActive ? "Bestand 1" : "Sluit Bestand 1"} ({file1Paging.index + " / " + file1Paging.total})
          </Button>
          {file1Paging.index !== file1Paging.total && (
            <IconButton
              className={classes.bottomNavIconButton}
              size="small"
              onClick={() => {
                navigateFile(1, "next", file1);
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          )}
        </div>
        {!matches && (
          <div id="button-bestand2" className={classes.bottomNavContainer}>
            {file2 && file2Paging.index !== 1 && (
              <IconButton
                className={classes.bottomNavIconButton}
                size="small"
                onClick={() => {
                  navigateFile(2, "prev", file2);
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
            )}
            <Button
              className={classes.bottomNavButton}
              disableElevation
              size="small"
              onClick={() => toggleSplitScreen()}
              startIcon={splitscreenActive ? <CancelPresentationIcon /> : <AddToQueueIcon />}
            >
              {splitscreenActive ? "Sluit Bestand 2" : "Open Bestand 2"} ({file2Paging.index + " / " + file2Paging.total})
            </Button>
            {file2 && file2Paging.index !== file2Paging.total && (
              <IconButton
                className={classes.bottomNavIconButton}
                size="small"
                onClick={() => {
                  navigateFile(2, "next", file2);
                }}
              >
                <ChevronRightIcon />
              </IconButton>
            )}
          </div>
        )}
      </BottomNavigation>
    </div>
  );
});

export default withStyles(useDocumentViewerStyles)(DocumentViewer);
