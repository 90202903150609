import bbox from "@turf/bbox";
import { StoresContext } from "index";
import { observer } from "mobx-react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Layer, Marker, Source } from "react-map-gl";
import { useParams } from "react-router";
import { ProjectDetailsType } from "stores/models/ProjectDetailModel";
// import Cluster from "./Cluster/Cluster";
// import PinGroups from "./Cluster/PinGroups";
// import ProjectMarkerBody from "./ProjectMarkerBody";
import ProjectPopup from "./Utils/ProjectPopup";
import { GetColorForStatusGeoportaal } from "../../utils";
import { clusterLayer, clusterCountLayer, unclusteredPointLayer } from "./spiderify/layers";
import { Spiderify } from "./spiderify/Spiderify";
const MapViewProjects = observer(({ map, onViewportChange }) => {
  const {
    mapStore: { fitBoundsMap, setZoomLevel, setLatLng, zoomLevel, viewport },
    projectStore: { filteredItems, projects, projectsGeoJSON },
    uiStore: { projectDetail: { projectDetail, setActiveProject }, getMunicipalityMapData },
    authStore: { token, role },
  } = useContext(StoresContext);
  const [popupProject, setPopupProject] = useState<ProjectDetailsType | null>(null);
  const { project: projectParams, municipality } = useParams<{ project: string; municipality: string }>();
  const [popupProjectShape, setPopupProjectShape] = useState();
  const [status, setStatus] = useState("");

  useEffect(() => {
    // if (projectParams) {
    // }
    zoomToProject();
  }, [projectDetail]);

  useEffect(() => {
    /**
     * only zoom to municipality when there are projects and no project detail
     * prevents zoom to bounds of municipality when there is a project detail
     * somekind of bugfix when projects are loaded after project detail
     */
    if (token && !projectParams) {
      const { lat, lng, zoom } = getMunicipalityMapData(municipality);
      setLatLng({
        lat,
        lng,
      });
      setZoomLevel(zoom);
      if (!projectParams) {
        setActiveProject(null as any);
      }
    }
  }, [token, projectParams]);

  const zoomToProject = () => {
    if (projectDetail?.geojson) {
      const bboxCoord = bbox(projectDetail.geojson);
      const bbox2: [[number, number], [number, number]] = [
        [bboxCoord[0], bboxCoord[1]],
        [bboxCoord[2], bboxCoord[3]],
      ];
      fitBoundsMap(bbox2, viewport.height, viewport.width, 30);
    }
  };

  // const onClickCluster = (event, bbox) => {
  //   if (zoomLevel < 17.5) {
  //     fitBoundsMap(bbox, viewport.height, viewport.width);
  //   } else {
  //     console.log(event);
  //   }
  // };

  const onClickProjectMarker = (project) => {
    setPopupProject(project);
    if (project?.geojson) {
      setPopupProjectShape(project?.geojson);
      setStatus(project?.statusgeoportaal);
    }
  };

  const onCloseProjectPopup = () => {
    setPopupProject(null);
  };

  return (
    <Fragment>
      <Source
        id="cluster-pins"
        key="cluster-pins-source"
        type="geojson"
        data={projectsGeoJSON}
        {...{
          cluster: true,
          maxzoom: 25,
          clusterRadius: 50,
          clusterMaxZoom: 24,
        }}
      >
        <Layer key="cluster-layer" {...clusterLayer} />

        <Layer key="cluster-count-layer" {...clusterCountLayer} />
        {<Layer key="cluster-uncluster-layer" {...unclusteredPointLayer} />}
      </Source>
      <Spiderify
        key="spider"
        onViewportChange={onViewportChange}
        onClickItem={(properties) => {
          onClickProjectMarker(JSON.parse(properties.project));
        }}
      />
      {popupProject && <ProjectPopup project={popupProject} onClose={onCloseProjectPopup} />}
      {popupProject && popupProjectShape && (
        <Source key={"popupLayer"} type="geojson" data={popupProjectShape}>
          <Layer
            type={"fill"}
            id={"project-shape-layer"}
            beforeId={"clusters"}
            paint={{
              "fill-color": GetColorForStatusGeoportaal(status),
              "fill-opacity": 0.2,
            }}
          />
          <Layer
            type={"line"}
            beforeId={"project-shape-layer"}
            paint={{
              "line-color": GetColorForStatusGeoportaal(status),
              "line-width": 4,
            }}
          />
        </Source>
      )}
    </Fragment>
  );
});

export default MapViewProjects;
