import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { MapPopup } from "@orbit/components";
import { StoresContext } from "index";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useContext } from "react";
import { useHistory, useParams } from "react-router";
import { ROUTE_BASE } from "../../../routes/RouteList";
import { ProjectDetailsType } from "../../../stores/models/ProjectDetailModel";
import { ProjectType } from "../../../stores/ProjectStore";

const usePopupStyles = () =>
  makeStyles(() => ({
    popup: {
      width: "300px",
      margin: -10,
      marginBottom: -15,
    },
    selectable: {
      cursor: "text",
      userSelect: "text",
    },
  }))();

const ProjectPopup = observer(({ project, onClose }: { project: ProjectType | ProjectDetailsType; onClose: any }) => {
  const {
    uiStore: { sideBarOpen, setSideBarOpen },
  } = useContext(StoresContext);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  console.log("project2", toJS(project));
  const classes = usePopupStyles();
  let history = useHistory();
  const { municipality } = useParams<{ municipality: string }>();
  const [lng, lat] = project.geojson.properties.center;

  const goToProject = () => {
    history.push(ROUTE_BASE + municipality + "/" + project.projectnummer);
    setSideBarOpen(true);
  };

  return (
    <MapPopup
      onClose={(evt) => {
        onClose(evt);
      }}
      canCloseTooltip={false}
      //tipSize={5}
      lat={lat}
      lng={lng}
    >
      <MapPopup.Content>
        <List dense className={classes.popup}>
          <ListItem key={project.projectnummer + "-title"}>
            <ListItemText
              primary={
                <Typography component="div" variant="subtitle2" color="textPrimary" className={classes.selectable}>
                  Projectnummer
                </Typography>
              }
              secondary={
                <Typography component="div" variant="caption" color="textSecondary" className={classes.selectable}>
                  {project.projectnummer}
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="closepopup"
                onClick={(evt) => {
                  onClose(evt);
                }}
              >
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem key={project.projectnummer + "-omschrijving"}>
            <ListItemText
              primary={
                <Typography component="div" variant="subtitle2" color="textPrimary" className={classes.selectable}>
                  Project omschrijving
                </Typography>
              }
              secondary={
                <Typography component="div" variant="caption" color="textSecondary" className={classes.selectable}>
                  {project.naam}
                </Typography>
              }
            />
          </ListItem>
          <ListItem key={project.projectnummer + "-indieningsdatum"}>
            <ListItemText
              primary={
                <Typography component="div" variant="subtitle2" color="textPrimary" className={classes.selectable}>
                  Indieningsdatum
                </Typography>
              }
              secondary={
                <Typography component="div" variant="caption" color="textSecondary" className={classes.selectable}>
                  {project.indieningsdatum}
                </Typography>
              }
            />
            {!matches && (
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="closepopup"
                  onClick={(evt) => {
                    onClose(evt);
                    goToProject();
                  }}
                >
                  <ZoomInIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}

            {matches && (
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="closepopup"
                  onClick={(evt) => {
                    onClose(evt);
                    goToProject();
                    setSideBarOpen(true);
                  }}
                >
                  <ZoomInIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        </List>
      </MapPopup.Content>
    </MapPopup>
  );
});

export default ProjectPopup;
