import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { SIDEBAR_CONFIG } from "../../../constants";

const useSidebarStyles = (theme: Theme) =>
  createStyles({
    drawer: {
      width: SIDEBAR_CONFIG.width,
      height: SIDEBAR_CONFIG.height,
      flexShrink: 0,
    },
    drawerPaper: {
      width: SIDEBAR_CONFIG.width,
      overflow: "hidden",
      borderRight: 0,
    },
    drawerMobile: {
      width: "100%",
      flexShrink: 0,
      overflow: "hidden",
    },
    drawerPaperMobile: {
      width: "100%",
      overflow: "hidden",
      borderRight: 0,
    },
    list: {
      padding: 0,
      overflowY: "auto",
    },
    listItem: {
      minHeight: 87,
    },
    subTitle: {
      display: "block",
    },
    search: {
      marginTop: theme.spacing(1),
    },
    AlleItem: {
      color: theme.palette.secondary.main,
    },
    PublicItem: {
      color: "#008000",
    },
    PublicItemLimited: {
      color: "#8BC34A",
    },
    leftAlignDialogActions: {
      justifyContent: "flex-start",
      marginLeft: 15,
      marginBottom: 15,
    },
    BeslissingsItem: {
      color: "#660066",
    },
    BehandelingsItem: {
      color: "#0000FF",
    },
    IngediendItem: {
      color: "#ff6600",
    },
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
    },
    LijstProjecten: {
      height: SIDEBAR_CONFIG.height,
    },
    drawerHeader: {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      zIndex: 99,
      display: "flex",
      alignItems: "center",
      padding: "0 16px",
      ...theme.mixins.toolbar,
    },
    whiteText: {
      color: "#ffffff",
    },
    grow: {
      flexGrow: 1,
    },
    closeButton: {
      color: "#ffffff",
    },
  });

export default useSidebarStyles;
