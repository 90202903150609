import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import { Marker } from "react-map-gl";

const SIZE = 30;

export const CustomLocationMarker = ({ key, longitude, latitude }) => {
  return (
    <Marker key={`marker-${key}`} longitude={longitude} latitude={latitude}>
      <svg
        height={SIZE}
        width={SIZE}
        viewBox="0 0 24 24"
        style={{
          cursor: "pointer",
          stroke: "none",
          transform: `translate(${-SIZE / 2}px,${-SIZE}px)`,
        }}
      >
        <path fill="red" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0" />
        <path
          fill="white"
          d="M9.78 18.65l.28-4.23 7.68-6.92c.34-.31-.07-.46-.52-.19L7.74 13.3 3.64 12c-.88-.25-.89-.86.2-1.3l15.97-6.16c.73-.33 1.43.18 1.15 1.3l-2.72 12.81c-.19.91-.74 1.13-1.5.71L12.6 16.3l-1.99 1.93c-.23.23-.42.42-.83.42z"
          transform="scale(0.5 0.5) translate(11 5)"
        />
      </svg>
    </Marker>
  );
};
