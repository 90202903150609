import { StoresContext } from "../../index";
import { autorun } from "mobx";
import { withSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";

const Notifier = ({ enqueueSnackbar }) => {
  const { notifierStore } = useContext(StoresContext);

  const [displayed, setDisplayed] = useState<any[]>([]);

  function storeDisplayed(id) {
    setDisplayed([...displayed, id]);
  }

  useEffect(() => {
    autorun(() => {
      const { notifications = [] } = notifierStore;

      notifications.forEach((notification) => {
        // Do nothing if snackbar is already displayed
        if (displayed.includes(notification.key)) return;

        // Display snackbar using notistack
        enqueueSnackbar(notification.message, notification.options);

        // Keep track of snackbars that we've displayed
        storeDisplayed(notification.key);

        // Dispatch action to remove snackbar from mobx store
        notifierStore.removeSnackbar(notification.key);
      });
    });
  }, []);
  return null;
};

export default withSnackbar(Notifier);
