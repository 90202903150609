import { withStyles } from "@mui/styles";
import { StoresContext } from "index";
import { observer as hooksObserver } from "mobx-react-lite";
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import { Layer, MapContext, Source } from "react-map-gl";
import { useDebouncedCallback } from "use-debounce";
import useThreeDMCViewerStyles from "./ThreeDMCViewerStyles";

interface Props {
  classes: any;
}

const ThreeDMCViewer: FunctionComponent<Props> = hooksObserver(({ classes }) => {
  const {
    mapStore: { mapLayer: { activeMap }, mappingCloud: { mcActive, footprints, fovs, fetchFootprints, fetchFovs, pingFootprints } },
  } = useContext(StoresContext);

  const { map } = useContext(MapContext);

  const [features, setFeatures] = useState<any>([]);

  const askFootprintsAndFOV = () => {
    const bounds = map.getBounds();
    fetchFootprints(bounds, map);
    fetchFovs();
    //console.log("askFootprintsAndFOV");
  };
  const askFootprintsAndFOVDebounced = useDebouncedCallback(
    // function
    () => {
      askFootprintsAndFOV();
    },
    // delay in ms
    350,
  );

  useEffect(() => {
    //console.log("mcActive", mcActive);
    //console.log("pingFootprints", pingFootprints);
    if (mcActive) {
      if (pingFootprints) {
        // events enkel binden indien men is ingelogd in de andere applicatie (pingFootprints = true)
        //console.log("enable binding map change to send bounds if mcActive");
        map.on("zoomend", () => {
          askFootprintsAndFOVDebounced();
        });
        map.on("moveend", () => {
          askFootprintsAndFOVDebounced();
        });
        askFootprintsAndFOVDebounced();
      }
    } else {
      //console.log("disable binding map change to send bounds if mcActive");
      map.off("zoomend");
      map.off("moveend");
      setFeatures([]);
      //svgLayer.selectAll("*").remove();
    }
  }, [mcActive, pingFootprints]);

  useEffect(() => {
    if (footprints.length) {
      const newFeatures = footprints.map((footprint) => {
        if (!footprint.spatialPoint) return;
        return {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: [footprint.spatialPoint.x, footprint.spatialPoint.y],
          },
        };
      });
      //console.log(newFeatures)
      setFeatures(newFeatures);
    }
  }, [footprints]);

  return (
    <Fragment>
      {map.transform._zoom > 16 &&
        pingFootprints &&
        fovs.length &&
        fovs.map((fov) => {
          if (fov.spatialPoint) {
            const center = map.project([fov.spatialPoint.x, fov.spatialPoint.y]);
            const viewbox = `0 0 ${map.getContainer().clientWidth} ${map.getContainer().clientHeight}`;
            return (
              <svg viewBox={viewbox} xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", top: 0, pointerEvents: "none" }}>
                <path
                  fill={`rgba(${fov.fillColor.red},${fov.fillColor.green},${fov.fillColor.blue},${fov.fillColor.alpha / 255})`}
                  d={fov.path[1]}
                  stroke={`rgba(${fov.color.red},${fov.color.green},${fov.color.blue},${fov.color.alpha / 255})`}
                  stroke-width="2"
                  style={{ zIndex: 500 }}
                  transform={`translate(${center.x},${center.y})`}
                />
                <text
                  x={fov.labelPosition.x}
                  y={fov.labelPosition.y}
                  font-family={fov.labelFontName}
                  font-size={`${fov.labelFontSize}px`}
                  fill={`rgba(${fov.labelColor.red},${fov.labelColor.green},${fov.labelColor.blue},${fov.labelColor.alpha / 255})`}
                  style={{ zIndex: 501 }}
                  transform={`translate(${center.x},${center.y})`}
                >
                  {fov.label}
                </text>
              </svg>
            );
          } else {
            return null;
          }
        })}
      <Source
        key={features.length}
        id="featureGroup-3DMC"
        type="geojson"
        data={{
          type: "FeatureCollection",
          features: features,
        }}
      >
        <Layer
          id="footprints"
          source="featureGroup-3DMC"
          type="circle"
          paint={{
            "circle-radius": ["interpolate", ["linear"], ["zoom"], 0, 0, 15.999, 0, 16, 4, 22, 10],
            "circle-color": "#007cbf",
          }}
        />
      </Source>
    </Fragment>
  );
});

export default withStyles(useThreeDMCViewerStyles)(ThreeDMCViewer);
