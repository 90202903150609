import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  AppBar,
  BottomNavigation,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { StoresContext } from "index";
import { jsPDF } from "jspdf";
import { observer } from "mobx-react";
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { ROUTE_BASE } from "routes/RouteList";
import { activateBOO, disableBOO, getBOO } from "services/api";
import useTimer from "views/Map/Utils/Idle";
import useBeperktOpenbaarOnderzoekStyles from "./BeperktOpenbaarOnderzoekStyles";
const BeperktOpenbaarOnderzoek: FunctionComponent<{
  classes: any;
}> = observer(({ classes }) => {
  const {
    uiStore: { projectDetail: { projectDetail } },
    authStore: { role },
  } = useContext(StoresContext);

  let history = useHistory();
  const { municipality, project: projectParams } = useParams<{ municipality: string; project: string }>();
  const timer = useTimer(60 * 8);

  const [isBOOEnabled, setIsBOOEnabled] = useState<boolean>(false);
  const [isBOODialogOpen, setIsBOODialogOpen] = useState<boolean>(false);
  const [isDialogTimerOpen, setIsDialogTimerOpen] = useState(false);
  const [code, setCode] = useState<any>("");

  /*   useEffect(() => {
      if (isBOOEnabled) {
        setTimeout(async () => {
          setIsBOOEnabled(false);
          await disableBOO(projectDetail.id);
        }, 8 * 60 * 1000);
      }
    }, [isBOOEnabled]); */

  useEffect(() => {
    async function handleTimer() {
      if (role === "Burgerloket" && projectDetail?.dossierstatussen[0]?.statusgeoportaal === "In Behandeling") {
        if (timer === 0) {
          setIsDialogTimerOpen(false);
          setIsBOOEnabled(false);
          await disableBOO(projectDetail.id);
          history.push(`/${municipality}`);
        } else if (timer < 2 * 60) {
          setIsDialogTimerOpen(true);
        }
      }
    }

    handleTimer();
  }, [role, projectDetail, timer, municipality]);

  useEffect(() => {
    const fetchCode = async () => {
      const code = await getBOO(projectDetail.id);
      if (code) {
        setCode(code);
        setIsBOOEnabled(true);
      }
    };
    fetchCode();
  }, [projectDetail]);

  const handleSwitchChange = async (e) => {
    if (!isBOOEnabled) {
      const code = await activateBOO(projectDetail.id);
      setCode(code);
      setIsBOOEnabled(true);
    } else {
      setIsBOOEnabled(false);
    }
    setIsBOODialogOpen(true);
  };

  const removeBOO = async () => {
    await disableBOO(projectDetail.id);
    setIsBOODialogOpen(false);
  };

  const cancelBOOChange = () => {
    setIsBOOEnabled(true);
    setIsBOODialogOpen(false);
  };

  const printCode = async () => {
    let image: any = document.createElement("IMG");
    image.src = `${ROUTE_BASE}assets/img/logo.png`;
    image.width = "250";
    image.height = "40";
    let pdf = new jsPDF("p", "mm", "a4");
    await pdf.addImage(image, "PNG", 10, 12.5, 150, 25);
    await pdf.html(
      `<html><div style="font-size: 3.5px; width: 175px;">
      <h3>Project in Beperkt Openbaar Onderzoek raadplegen aan het digitaal loket</h3>
      <p>
          Bericht aan de medewerker van de gemeente: <br />
          Dit stappenplan is enkel uitvoerbaar indien is ingelogd in de applicatie met het profiel Burger Loket!
      </p>
      <p>
          Volg onderstaande stappenplan om een project te raadplegen waarvoor een beperkt openbaar onderzoek geldt. <br />
          1.
          Selecteer de keuzelijst onder "Toon alleen projecten met status"<br />2. Selecteer de optie Beperkt Openbaar
          Onderzoek<br />3. Geef de code in die je onderaan deze pagina kan terugvinden en druk op Project Ophalen
      </p>
      <p>
          U kan nu de inhoud van het project in kwestie bekijken en de bestanden raadplegen.<br />
          Indien u, per ongeluk, terug naar het overzicht van de applicatie bent gegaan (via de &#60;- pijl), <br />
          zal het project niet meer zichtbaar zijn. Begin in dat geval opnieuw vanaf stap 1.
      </p>
      <p>
          Let op: <br />Indien er gedurende 10 minuten geen activiteit is in de applicatie, zal het project automatisch worden gesloten.<br />De gebruiker krijgt hiervan een melding en kan indien gewenst de sessie verlengen.
      </p>
      </br>
      <p>
          Uw code:
      </p>
      <h3>${code}</h3>
      <br/>
      Veel succes
      </div>
      </html>`,
      {
        x: 14,
        y: 40,
      },
    );
    window.open(pdf.output("bloburl").toString(), "_blank");
  };

  const handleCloseDialog = () => {
    setIsBOODialogOpen(false);
  };

  const handleOpenDialog = () => {
    setIsBOODialogOpen(true);
  };

  const stayLoggedIn = () => {
    setIsDialogTimerOpen(false);
  };

  return (
    <Fragment>
      <BottomNavigation className={classes.bottomNav}>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              id="beperktOpenbaarOnderzoek"
              value="end"
              control={<Switch name="beperktOpenbaarOnderzoek" checked={isBOOEnabled} onChange={handleSwitchChange} color="default" />}
              label={<Typography sx={{ lineHeight: 1 }}>Beperkt openbaar onderzoek</Typography>}
              labelPlacement="end"
              className={classes.bottomNavButton}
            />
            {isBOOEnabled && (
              <IconButton aria-label="visibility" className={classes.switchIcon} onClick={handleOpenDialog}>
                <VisibilityIcon />
              </IconButton>
            )}
          </FormGroup>
        </FormControl>
      </BottomNavigation>
      <Dialog open={isBOODialogOpen} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <AppBar position="static">
          <DialogTitle>Beperkt Openbaar Onderzoek {isBOOEnabled ? "inschakelen" : "uitschakelen"}</DialogTitle>
        </AppBar>
        <DialogContent>
          <DialogContentText>
            {isBOOEnabled ? (
              <Fragment>
                <Typography paragraph>
                  Gebruik onderstaande code om dit project in beperkt openbaar onderzoek aan te bieden aan de burger aan het loket (ingelogd met profiel burgerloket).
                </Typography>
                <Typography paragraph>Je kan het beperkt openbaar onderzoek terug uitschakelen onderaan in de zijbalk. Dit gebeurt ook automatisch na 8u.</Typography>
                <Typography paragraph variant="h6" align="center">
                  {code}
                  <IconButton aria-label="print" onClick={printCode}>
                    <PrintIcon />
                  </IconButton>
                </Typography>
                <Typography paragraph>Tip: Je kan ook een korte handleiding afdrukken via "de print knop".</Typography>
              </Fragment>
            ) : (
              <Fragment>
                <Typography>Bent u zeker om het beperkt openbaar onderzoek uit te schakelen?</Typography>
                <Typography paragraph>De code zal hierdoor verloren gaan.</Typography>
              </Fragment>
            )}
          </DialogContentText>
        </DialogContent>
        {!isBOOEnabled && (
          <DialogActions>
            <Button onClick={removeBOO} color="secondary">
              Ja
            </Button>
            <Button onClick={cancelBOOChange} color="secondary">
              Annuleer
            </Button>
          </DialogActions>
        )}
      </Dialog>
      {projectDetail?.dossierstatussen[0]?.statusgeoportaal === "In Behandeling" && (
        <>
          <Dialog open={isDialogTimerOpen} onClose={stayLoggedIn} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <AppBar position="static">
              <DialogTitle id="alert-dialog-title">{"Sessie bijna verlopen"}</DialogTitle>
            </AppBar>
            <DialogActions>
              <Button onClick={stayLoggedIn} variant="contained" style={{ textTransform: "none" }}>
                Aangemeld blijven
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Fragment>
  );
});

export default withStyles(useBeperktOpenbaarOnderzoekStyles)(BeperktOpenbaarOnderzoek);
