import CloseIcon from "@mui/icons-material/Close";
import LayersIcon from "@mui/icons-material/Layers";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import ThreeDRotationIcon from "@mui/icons-material/ThreeDRotation";
import { AppBar, Drawer, Fab, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { StoresContext } from "index";
import "mapbox-gl/dist/mapbox-gl.css";
import { observer } from "mobx-react";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import ReactMapGL, { AttributionControl, Layer, LayerProps, Marker, ScaleControl, Source, ViewState } from "react-map-gl";
import { useLocation as useLocationParams } from "react-router";
import { GetColorForStatusGeoportaal } from "../../utils";
import { CustomLocationMarker } from "./CustomLocationMarker";
import DrawFunctions from "./Draw/DrawFunctions";
import styles from "./location.module.css";
import MapViewProjects from "./MapViewProjects";
import { clusterLayer, spiderPointLayer, unclusteredPointLayer } from "./spiderify/layers";
import ThreeDMCViewer from "./ThreeDMCViewer/ThreeDMCViewer";
import { useLocation } from "./Utils/Location";
import ProjectLayer from "./Utils/ProjectLayer";
import ZoomButtons from "./Utils/ZoomButtons";

const useLogoStyle = makeStyles((theme) => ({
  logoStyle: {
    position: "absolute",
    bottom: "30px",
    right: "8px",
    height: "45px",
    opacity: 0.8,
    zIndex: 1000,
  },
}));

const scaleControlStyle = {
  left: 10,
  bottom: 10,
};
const scaleControlStyleMobile = {
  left: 10,
  bottom: 35,
};

const attributionStyle = {
  right: 0,
  bottom: 0,
  fontSize: "0.7em",
};

const maps = [
  {
    name: "GRB",
    icon: <img style={{ borderRadius: "50%", width: 40, height: 40 }} src={`/assets/img/grb.png`} alt="GRB" loading="lazy" />,
    url: "https://geo.api.vlaanderen.be/GRB/wmts/1.0.0/grb_bsk/default/GoogleMapsVL/{z}/{y}/{x}.png",
    attribution: '<a target="_blank" href="https://overheid.vlaanderen.be/GRB-GRB-raadpleegdiensten">Grootschalig Referentie Bestand Vlaanderen, Informatie Vlaanderen</a>',
  },
  {
    name: "GRB Grijs",
    icon: <img style={{ borderRadius: "50%", width: 40, height: 40 }} src={`/assets/img/grb_grijs.png`} alt="GRB Grijs" loading="lazy" />,
    url: "https://geo.api.vlaanderen.be/GRB/wmts/1.0.0/grb_bsk_grijs/default/GoogleMapsVL/{z}/{y}/{x}.png",
    attribution: '<a target="_blank" href="https://overheid.vlaanderen.be/GRB-GRB-raadpleegdiensten">Grootschalig Referentie Bestand Vlaanderen, Informatie Vlaanderen</a>',
  },
  {
    name: "OpenStreetMap",
    icon: <img style={{ borderRadius: "50%", width: 40, height: 40 }} src={`/assets/img/osm.png`} alt="OpenStreetMap" loading="lazy" />,
    url: "",
    attribution: "",
  },
  {
    name: "Luchtfoto",
    icon: <img style={{ borderRadius: "50%", width: 40, height: 40 }} src={`/assets/img/ortho.png`} alt="Luchtfoto" loading="lazy" />,
    url: "https://geo.api.vlaanderen.be/OMWRGBMRVL/wmts/1.0.0/omwrgbmrvl/default/GoogleMapsVL/{z}/{y}/{x}.png",
    attribution: '<a target="_blank" href="https://overheid.vlaanderen.be/producten-diensten/luchtopnamen">Orthofotomozaiek Vlaanderen, Informatie Vlaanderen</a>',
  },
];

const logo = {
  img: "/assets/img/orbit.png",
  url: "https://www.orbitgis.com/",
};

const MapView = observer(() => {
  const {
    mapStore: {
      update,
      latLng,
      zoomLevel,
      setZoomLevel,
      setLatLng,
      mapLayer: { activeMap },
      searchLocation,
      mappingCloud: { mcActive, enableMc, disableMc, pickPosition, setPickPosition },
    },
    uiStore: { projectDetail: { projectDetail } },
    authStore: { role },
  } = useContext(StoresContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useLogoStyle();
  const viewport: ViewState = {
    //@ts-ignore
    width: "100wv",
    height: matches ? "calc(100vh - 56px)" : "calc(100vh - 64px)",
    latitude: latLng.lat,
    longitude: latLng.lng,
    zoom: zoomLevel,
  };

  const [mapbox, setMapbox] = useState<any>();
  const [hasImages, setHasImages] = useState<boolean>(false);
  const mapRef = useRef<any>();
  const [layerSelectorOpen, setLayerSelectorOpen] = useState(false);
  const [baseLayer, setBaseLayer] = useState(maps[0]);
  const [enableLayerSelecter, setEnableLayerSelector] = useState<boolean>(false);
  const [enabled, setEnabled] = useState(false);
  const [aanplakLocation, setAanplakLocation] = useState<any>(null);
  const location = useLocation(enabled, null, 2);
  const locationParams = useLocationParams();
  const [userLocation, setUserLocation] = useState({
    lat: 0,
    lng: 0,
  });

  const onViewportChange = (newViewport: ViewState) => {
    const { latitude, longitude, zoom } = newViewport;
    setZoomLevel(zoom);
    setLatLng({
      lat: latitude,
      lng: longitude,
    });
    update("viewport", newViewport);
  };

  const toggleDrawer = (open) => {
    setLayerSelectorOpen(open);
  };

  useEffect(() => {
    if (searchLocation) {
      const { lat, lon } = searchLocation;
      setZoomLevel(18);
      setLatLng({
        lat,
        lng: lon,
      });
    }
  }, [searchLocation]);

  useEffect(() => {
    const search = locationParams.search;
    const params = new URLSearchParams(search);
    const lat = Number(params.get("lat"));
    const lng = Number(params.get("lng"));
    if (lat && lng) {
      setAanplakLocation({
        lat,
        lng,
      });
    }
  }, [locationParams]);

  useEffect(() => {
    setEnabled(true);
  }, []);

  /**
   * loads background icon for project markers
   * will set hasImages to true when done
   * has images is needed because the image needs to be loaded before the markers can be added
   */
  useEffect(() => {
    if (mapbox) {
      let image = new Image();
      image.src =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAjCAYAAACD1LrRAAAAAklEQVR4AewaftIAAABiSURBVMXBMQ7CQBDAQK/F/78c6FIgxF4az8z1QUAiEpGIRCQiEYlIRCISkYhEJCIRiUhEIhKRiEQkIhGJSEQiEpGIRCTyAoadi51hQfaG/4YlOTP8NhyQc8O34ZA8M9yGB9528wVNsQOZrAAAAABJRU5ErkJggg==";
      image.onload = (e) => {
        if (!mapbox.hasImage("background")) {
          mapbox.addImage("background", image, {
            sdf: true,
          });
        }
      };
    }
  }, [mapbox]);

  return (
    <Fragment>
      <ReactMapGL
        {...viewport}
        maxZoom={activeMap.maxZoom}
        minZoom={activeMap.minZoom}
        mapboxApiAccessToken={activeMap.accessToken}
        mapStyle={activeMap.url}
        attributionControl={false}
        ref={mapRef}
        onLoad={() => {
          if (mapRef.current) {
            setMapbox(mapRef.current.getMap());
          }
        }}
        onViewportChange={onViewportChange}
        onClick={(e) => {
          if (pickPosition && mcActive) {
            setPickPosition(e.lngLat[0], e.lngLat[1]);
          }
        }}
        style={
          pickPosition &&
          mcActive && {
            cursor: "crosshair !important",
          }
        }
        getCursor={({ isHovering, isDragging }) => (pickPosition && mcActive ? "crosshair" : isDragging ? "grabbing" : isHovering ? "pointer" : "default")}
        interactiveLayerIds={[String(unclusteredPointLayer.id), String(spiderPointLayer.id), String(clusterLayer.id)]}
      >
        <ScaleControl key={viewport.zoom} maxWidth={100} unit="metric" style={matches ? scaleControlStyleMobile : scaleControlStyle} />

        {baseLayer.name !== "OpenStreetMap" && (
          <>
            <Source
              {...{
                key: baseLayer.name,
                type: "raster",
                tiles: [baseLayer.url],
                tileSize: 256,
              }}
            >
              <Layer
                {...({
                  id: "wms-base-layer",
                  beforeId: "country_label-en",
                  key: baseLayer.name + "-layer",
                  type: "raster",
                  source: baseLayer.name,
                  paint: {},
                } as LayerProps)}
              />
            </Source>
          </>
        )}
        {projectDetail && (
          <ProjectLayer
            data={[projectDetail.geojson, ...projectDetail.otherGeojson]}
            id={"base" + projectDetail.id}
            color={GetColorForStatusGeoportaal(projectDetail?.statusgeoportaal)}
          />
        )}
        <MapViewProjects map={mapbox} onViewportChange={onViewportChange} />

        <DrawFunctions />
        <a data-test-id="logoUrl" href={logo.url} target="_blank">
          <img data-test-id="logoImage" src={logo.img} alt="Logo" className={classes.logoStyle} />
        </a>

        <AttributionControl key={baseLayer.attribution} compact={false} style={attributionStyle} customAttribution={baseLayer.attribution} />
        {searchLocation && (
          <Marker
            key={`searchmarker-${searchLocation.lat}-${searchLocation.lon}`}
            latitude={searchLocation.lat}
            longitude={searchLocation.lon}
            offsetLeft={-25 / 2}
            offsetTop={-42}
          >
            <img src="/assets/img/marker-icon-selected.png" />
          </Marker>
        )}

        {aanplakLocation && (
          <CustomLocationMarker key={`aanplakmarker-${aanplakLocation.lat}-${aanplakLocation.lng}`} latitude={aanplakLocation.lat} longitude={aanplakLocation.lng} />
        )}
        <Marker offsetTop={-20} offsetLeft={-10} latitude={userLocation.lat} longitude={userLocation.lng}>
          <div className={styles.mapboxglUserLocationDot}></div>
        </Marker>
        <ThreeDMCViewer />
      </ReactMapGL>
      <div style={{ right: 3, top: 65, position: "absolute", width: 50 }}>
        <div id="tour-plus">
          <ZoomButtons activeMap={activeMap} setZoomLevel={setZoomLevel} zoomLevel={zoomLevel} setEnableLayerSelector={setEnableLayerSelector} />
        </div>
        <Fab id="tour-layers" color="secondary" size="small" style={{ color: "white", margin: 4 }} onClick={() => toggleDrawer(true)}>
          <LayersIcon />
        </Fab>
        {role === "Dossierbeheerder" && (
          <Fab id="tour-3dmc" color="secondary" size="small" style={{ color: "white", margin: 4 }} onClick={() => enableMc()}>
            <ThreeDRotationIcon />
          </Fab>
        )}
        <Drawer anchor="right" open={layerSelectorOpen} onClose={() => toggleDrawer(false)}>
          <div role="presentation" onClick={() => toggleDrawer(false)} onKeyDown={() => toggleDrawer(false)}>
            <AppBar color="secondary" position="static">
              <Toolbar sx={{ color: "white" }}>
                <Typography variant="h6">Kies een kaartlaag</Typography>
                <IconButton edge="end" sx={{ marginLeft: 13 }} color="inherit" aria-label="menu">
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <List>
              {maps.map(({ name, url, icon }, index) => (
                <ListItem button key={index} onClick={() => setBaseLayer(maps[index])}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={name} />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
        <Fab
          id="tour-location"
          color="secondary"
          size="small"
          style={{ color: "#fff", margin: 4 }}
          onClick={() => {
            setUserLocation({ lat: location[0]?.lat ?? 0, lng: location[0]?.lng ?? 0 });
            setLatLng({ lat: location[0]?.lat ?? 0, lng: location[0]?.lng ?? 0 });
            setZoomLevel(18);
          }}
        >
          <MyLocationIcon />
        </Fab>
      </div>
    </Fragment>
  );
});

export default MapView;
