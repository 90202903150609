import { browserHistory } from "App";
import axios from "axios";
import jsonpAdapter from "axios-jsonp";
import { notifierStore } from "index";
import { formatProjects, formatProject } from "./omgeving";

export const setDefaultHeader = (token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
};

const AUTH_URL = window.env.AUTH_URL;
const API_OMGEVING_URL = window.env.API_OMGEVING_URL;
export const LOGIN_URL = `${AUTH_URL}token`;

export const changeVisibilityAPI = async (action, projectnummer, bestandsnaam, municipality) => {
  let url = `${API_OMGEVING_URL}bestanden/visibility/${action}/${projectnummer}/${bestandsnaam}`;
  const data = await axios.post(url, { municipality: municipality });
  return data;
};

export const activateBOO = async (projectId) => {
  let url = `${API_OMGEVING_URL}projecten/access/${projectId}/activate`;
  const {
    data: { code, status },
  } = await axios.post(url);
  return code;
};

export const getBOO = async (projectId) => {
  let url = `${API_OMGEVING_URL}projecten/access/${projectId}/code`;
  const {
    data: { code, status },
  } = await axios.get(url);
  if (status === 200) return code;
  return null;
};

export const disableBOO = async (projectId) => {
  let url = `${API_OMGEVING_URL}projecten/access/${projectId}/deactivate`;
  await axios.get(url);
  return true;
};

export const accessBOO = async (sharedCode) => {
  let url = `${API_OMGEVING_URL}projecten/access/${sharedCode}`;
  const { data } = await axios.get(url);
  return data;
};

export const fetchMunicipalities = async () => {
  const { data } = await axios.get(`https://geo.api.vlaanderen.be/capakey/v2/municipality/`);
  return data;
};

export const fetchMunicipality = async (municipality, mode = "fiscal") => {
  const { data } = await axios.get(`https://geo.api.vlaanderen.be/capakey/v2/municipality/${municipality}?data=adp&status=${mode}&srs=4326`);
  return data;
};

export const ROUTE_SEARCH_HEADER = "https://geo.api.vlaanderen.be/geolocation/geolocation/Suggestion";

export const getProjectDetails = async (municipality: number, projectnummer: string) => {
  try {
    let url = `${API_OMGEVING_URL}projecten/${projectnummer}`;
    const result = await axios.post(url, { municipality, scrape_mode: "both", dossierstatussen: true });
    console.log(result);
    if (result.data) {
      return formatProject(result.data);
    }

    return null;
  } catch (error) {
    console.log("error", error.toString());
    if (error.response.status === 404) {
      await getStatusProject(municipality, projectnummer);
    }
    return null;
  }
};

export const getStatusProject = async (municipality: number, projectnummer: string) => {
  try {
    let url = `${API_OMGEVING_URL}projecten/status/${projectnummer}`;
    const result = await axios.post(url, { municipality, scrape_mode: "both", dossierstatussen: true });
  } catch (error) {
    console.log("error", error.response.status);

    try {
      browserHistory.push("/" + window.location.pathname.split("/")[1]);
    } catch (error) {
      console.log("cannot push");
    }

    if (error.response.status === 403) {
      return notifierStore.enqueueSnackbar({
        message: {
          title: "Project kan niet worden weergegeven.",
          message: ["U beschikt niet over de nodige rechten voor dit project."],
          isExpanded: true,
        },
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "warning",
          persist: true,
        },
      });
    }
    if (error.response.status === 418) {
      return notifierStore.enqueueSnackbar({
        message: {
          title: "Project kan niet worden weergegeven.",
          message: ["De publicatie termijn van dit project is verlopen."],
          isExpanded: true,
        },
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "warning",
          persist: true,
        },
      });
    }

    return notifierStore.enqueueSnackbar({
      message: {
        title: "Project kan niet worden weergegeven.",
        message: ["Dit project is niet gekend in de databank."],
        isExpanded: true,
      },
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "warning",
        persist: true,
      },
    });
  }
};

export const postAnnotation = async (fileName, annotationId, xfdfString, action) => {
  let url = `${API_OMGEVING_URL}document_viewer/2019/annotations/${fileName}`;
  return axios.put(
    url,
    {
      annotationId,
      xfdfString,
      action,
    },
    {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Accept: "application/json; charset=utf-8",
      },
    },
  );
};

export const getProjects = async (municipality) => {
  let url = `${API_OMGEVING_URL}projecten`;
  const {
    data: { projecten },
  } = await axios.post(url, {
    municipality: `${municipality}`,
    dossierstatussen: true,
  });

  const formattedProjects = formatProjects(projecten);
  return formattedProjects;
};

export const searchHeader = async (muncipality, searchInput) => {
  let url = `https://geo.api.vlaanderen.be/geolocation/geolocation/Location?q=${muncipality}%20${searchInput}&c=5`;
  const {
    data: { LocationResult },
  } = await axios({
    url,
    adapter: jsonpAdapter,
  });

  return LocationResult.map(({ FormattedAddress: item, Location: { Lat_WGS84, Lon_WGS84 } }) => ({
    type: "street",
    value: item,
    label: item,
    location: { lat: Lat_WGS84, lon: Lon_WGS84 },
  }));
};

export type GemeenteConf = {
  id: string;
  name: string;
  display: string;
  lat: string;
  lon: string;
  zoom: string;
  scrape_mode: string;
  niscode: string;
  agiv_poi: string;
  postcode: string;
  email: string;
};

export const getOmvConfMunicipality = async (requestedNiscode: string | number): Promise<null | GemeenteConf> => {
  try {
    const { data: omv_municipalities } = await axios.get(`/config/municipalities.json`);
    //@ts-ignore
    const gemeente: GemeenteConf = Object.values(omv_municipalities).find((omv_municipality: GemeenteConf) => Number(omv_municipality.niscode) === Number(requestedNiscode));
    return gemeente;
  } catch (error) {
    console.error("getOmvConfMunicipality", error);
    return null;
  }
};
