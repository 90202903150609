import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { SIDEBAR_CONFIG } from "../../../constants";

const useSidebarProjectStyles = (theme: Theme) =>
  createStyles({
    drawer: {
      width: SIDEBAR_CONFIG.width,
      flexShrink: 0,
      overflow: "hidden",
    },
    drawerPaper: {
      width: SIDEBAR_CONFIG.width,
      overflow: "hidden",
      borderRight: 0,
    },
    drawerMobile: {
      width: "100%",
      flexShrink: 0,
      overflow: "hidden",
    },
    drawerPaperMobile: {
      width: "100%",
      overflow: "hidden",
      borderRight: 0,
    },
    tabs: {
      backgroundColor: theme.palette.secondary.main,
      color: "#ffffff",
      "& button": {
        color: "#ffffff !important",
        opacity: ".7",
      },
      "& .Mui-selected": {
        opacity: "1",
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "#ffffff !important",
      },
    },
    tabContainer: {
      height: "calc(100% - 176px)",
      overflowY: "auto",
      overflowX: "hidden",
    },
    tabContainerMobile: {
      height: "calc(100% - 168px)",
      overflowY: "auto",
      overflowX: "hidden",
    },
    tabContainerLarge: {
      height: "calc(100% - 136px)",
      overflowY: "auto",
      overflowX: "hidden",
    },
    list: {
      padding: 0,
    },
    stripethrough: {
      textDecoration: "line-through",
    },
    statusList: {
      padding: 0,
    },
    statusListItem: {
      margin: 0,
    },
    statusListButton: {
      marginTop: 5,
    },
    vvo: {
      display: "block",
    },
    listItem: {
      minHeight: 64,
      overflow: "hidden",
      wordWrap: "break-word",
    },
    bottomNav: {
      backgroundColor: theme.palette.secondary.main,
      maxHeight: 40,
      paddingLeft: 10,
      paddingRight: 10,
    },
    bottomNavButton: {
      color: "#ffffff",
    },
    backButton: {
      color: "#ffffff",
      marginRight: theme.spacing(1),
    },
    switchIcon: {
      color: "#ffffff",
    },
    dialog: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "1.5em",
    },
    drawerHeader: {
      backgroundColor: theme.palette.secondary.main,
      zIndex: 99,
      display: "flex",
      alignItems: "center",
      padding: "0 16px",
      ...theme.mixins.toolbar,
    },
    drawerFooter: {
      width: SIDEBAR_CONFIG.width,
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      zIndex: 99,
      display: "flex",
      alignItems: "center",
      padding: "0 16px",
      ...theme.mixins.toolbar,
    },
    whiteText: {
      color: "white",
    },
    grow: {
      flexGrow: 1,
    },
    closeButton: {
      color: "white",
    },
  });

export default useSidebarProjectStyles;
