import React, { Fragment, useContext, useEffect } from "react";
import { Redirect, Route, useParams } from "react-router";
//import { withErrorBoundary } from "containers/partials/error-boundary/ErrorBoundary";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import {
  ROUTE_SELECTEER_PROFIEL,
  ROUTE_BASE,
  ROUTE_MUNICIPALITY,
  ROUTE_MUNICIPALITY_PROJECT,
  ROUTE_MUNICIPALITY_PROJECT_FILE,
  ROUTE_MUNICIPALITY_PROJECT_FILE_SPLIT,
} from "./RouteList";
import BasePage from "../containers/base/BasePage";
import MunicipalityBase from "../containers/municipalities/MunicipalityBase";
import SelectProfile from "../containers/users/SelectProfile";
import { observer } from "mobx-react";
import { StoresContext } from "index";

const AppRoute = observer(() => {
  return (
    <Fragment>
      <PublicRoutes>
        <Route exact path={ROUTE_SELECTEER_PROFIEL} component={SelectProfile} />
        <Route exact path={[ROUTE_BASE]} component={MunicipalityBase} />
        <Route exact path={[ROUTE_MUNICIPALITY, ROUTE_MUNICIPALITY_PROJECT, ROUTE_MUNICIPALITY_PROJECT_FILE, ROUTE_MUNICIPALITY_PROJECT_FILE_SPLIT]} component={BasePage} />
        <Redirect to={ROUTE_BASE} />
      </PublicRoutes>
      <PrivateRoutes>
        <Route exact path={ROUTE_SELECTEER_PROFIEL} component={SelectProfile} />
        <Route exact path={ROUTE_BASE} component={MunicipalityBase} />
        <Route exact path={[ROUTE_MUNICIPALITY, ROUTE_MUNICIPALITY_PROJECT, ROUTE_MUNICIPALITY_PROJECT_FILE, ROUTE_MUNICIPALITY_PROJECT_FILE_SPLIT]} component={BasePage} />
        <Redirect to={ROUTE_BASE} />
      </PrivateRoutes>
    </Fragment>
  );
});

export default /* withErrorBoundary */ AppRoute;
