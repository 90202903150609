import { toJS } from "mobx";
import React from "react";
import { Source, Layer } from "react-map-gl";
import { unclusteredPointLayer } from "../spiderify/layers";
const ProjectLayer = ({ id, color, data }) => {
  return data.map((item, index) => (
    <Source key={"projectLayer-" + index} id={"projectLayer-" + index} type="geojson" data={item}>
      <Layer
        type={"fill"}
        beforeId="clusters"
        id={index === 0 ? "projects-fill" : "projects-fill" + index}
        paint={{
          "fill-color": color,
          "fill-opacity": 0.2,
        }}
      />
      <Layer
        type={"line"}
        id={index === 0 ? "projects" : "projects" + index}
        beforeId="projects-fill"
        paint={{
          "line-color": color,
          "line-width": 4,
        }}
      />
    </Source>
  ));
};

export default ProjectLayer;
