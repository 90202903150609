import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
const useBeperktOpenbaarOnderzoekStyles = (theme: Theme) =>
  createStyles({
    bottomNav: {
      backgroundColor: theme.palette.secondary.main,
      maxHeight: 40,
    },
    bottomNavButton: {
      color: "#ffffff",
    },
    switchIcon: {
      color: "#ffffff",
      padding: 8,
    },
    dialog: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "1.5em",
    },
  });

export default useBeperktOpenbaarOnderzoekStyles;
