import { observable, action, decorate, computed } from "mobx";
import { persist } from "mobx-persist";
import { APP_LOCALES } from "../constants";
import ProjectDetailModel from "./models/ProjectDetailModel";
import axios from "axios";

export enum SnackType {
  ERROR = "Error",
  INFO = "Info",
}

export const checkIfMobile = () => {
  const userAgent = navigator.userAgent;
  if (!userAgent?.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)) {
    return false;
  }
  return true;
};

export default class UiStore {
  constructor() {
    this.isMobile = checkIfMobile();
    this.loadMunicipalities();
  }
  locale: APP_LOCALES = APP_LOCALES.NL;

  sideBarOpen: boolean = true;
  sideBarRightOpen = false;
  dialogSelectProfileOpen: boolean = false;
  sideBarTekstveldOpen = false;

  splitscreenActive: boolean = false;

  projectDetail: ProjectDetailModel = new ProjectDetailModel();

  snackEnabled = false;
  snackType = SnackType.ERROR;
  snackMessage = "Er is iets fout gegaan.";

  showTour = true;
  tourIndex = 0;

  allMunicipalities: any[] = [];

  searchMunicipality = "";

  isMobile: boolean;

  loadMunicipalities = async (): Promise<string | void> => {
    try {
      const response = await axios.get("/config/municipalities.json");
      this.allMunicipalities = response.data;
    } catch (error) {
      console.log(error.toString());
    }
  };

  setSearchMunicipality = (value) => {
    this.searchMunicipality = value;
  };

  getMunicipalityMapData = (municipality_id) => {
    return (
      Object.values(this.allMunicipalities)
        //@ts-ignore
        .filter((val) => val.name === municipality_id)
        .map((val) => ({ lat: JSON.parse(val.lat), lng: JSON.parse(val.lon), zoom: JSON.parse(val.zoom) }))[0]
    );
  };

  get municipalities() {
    return (
      Object.values(this.allMunicipalities)
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        //@ts-ignore
        .filter((val) => {
          if (this.searchMunicipality === "") {
            return val;
          } else if (
            val.display.toLowerCase().indexOf(this.searchMunicipality.toLowerCase()) > -1 ||
            val.postcode.toLowerCase().indexOf(this.searchMunicipality.toLowerCase()) > -1
          ) {
            return val;
          }
        })
    );
  }

  setSideBarOpen = (isOpen: boolean) => {
    this.sideBarOpen = isOpen;
  };

  setSideBarRightOpen = (isOpen: boolean) => {
    this.sideBarRightOpen = isOpen;
  };

  setDialogSelectProfileOpen = (isOpen: boolean) => {
    this.dialogSelectProfileOpen = isOpen;
  };

  setSideBarTekstveldOpen = (isOpen: boolean) => {
    this.sideBarTekstveldOpen = isOpen;
  };

  setSplitscreenActive = (isActive: boolean) => {
    this.splitscreenActive = isActive;
  };

  toggleSnackEnabled = () => {
    this.snackEnabled = !this.snackEnabled;
  };

  triggerSnack = (message: string, type: SnackType) => {
    this.snackMessage = message;
    this.snackType = type;
    this.snackEnabled = true;
  };

  setShowTour = (showTour) => {
    this.showTour = showTour;
  };

  setTourIndex = (tourIndex) => {
    this.tourIndex = tourIndex;
  };
}

decorate(UiStore, {
  locale: [observable, persist],
  sideBarOpen: [observable, persist],
  sideBarRightOpen: [observable],
  dialogSelectProfileOpen: [observable],
  sideBarTekstveldOpen: [observable],
  splitscreenActive: [observable],
  snackEnabled: [observable],
  snackMessage: [observable],
  snackType: [observable],
  showTour: [observable, persist],
  tourIndex: [observable],
  allMunicipalities: [observable],
  searchMunicipality: [observable],
  isMobile: [observable],
  setSideBarOpen: action.bound,
  setSideBarRightOpen: action.bound,
  setDialogSelectProfileOpen: action.bound,
  setSideBarTekstveldOpen: action.bound,
  setSplitscreenActive: action.bound,
  toggleSnackEnabled: action.bound,
  triggerSnack: action.bound,
  setShowTour: action.bound,
  setTourIndex: action.bound,
  setSearchMunicipality: action.bound,
  getMunicipalityMapData: action.bound,
  loadMunicipalities: action.bound,
  municipalities: computed,
});
