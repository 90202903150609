import { Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText } from "@mui/material";
import { StoresContext } from "../../index";
import { observer as hooksObserver } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { ROUTE_SELECTEER_PROFIEL } from "../../routes/RouteList";
import { getUserRights } from "../../services/auth";
import { firstLetterUpperCase } from "../../utils";
import styles from "./SelectProfileStyles";
import { withStyles } from "@mui/styles";

const AUTH_URL = window.env.AUTH_URL;

const SelectProfile = hooksObserver(({ /*open,*/ canClose, classes, intl: { formatMessage } }) => {
  const {
    uiStore: { setDialogSelectProfileOpen /*dialogSelectProfileOpen*/ },
    authStore: { availableProfiles, setAvailableProfiles, sessionId, setSessionId },
    //authStore,
    routingStore: { push, location },
  } = useContext(StoresContext);

  const [laravelId, setLaravelId] = useState<string>("");

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.has("id")) {
      const id = urlParams.get("id");
      if (id) {
        setLaravelId(id);
        if (location.pathname) {
          push(ROUTE_SELECTEER_PROFIEL);
        }
      }
    }
  }, []);

  useEffect(() => {
    const fetchUserRights = async () => {
      const data = await getUserRights(laravelId);
      setSessionId(laravelId);
      setAvailableProfiles(data);
    };
    if (laravelId !== "") {
      fetchUserRights();
    }
  }, [laravelId]);

  const selectProfile = async (right: any) => {
    window.location.href = `${AUTH_URL}userights?s=${right.scope}&r=${right.role}&id=${sessionId}`;
  };

  return (
    <Dialog open={true /*open || dialogSelectProfileOpen*/} onClose={(e) => (canClose ? setDialogSelectProfileOpen(false) : "")}>
      <DialogTitle className={classes.dialogTitle}>Selecteer uw hoedanigheid</DialogTitle>
      <DialogContent>
        <List>
          {availableProfiles.map((profile: any, index) => (
            <ListItem
              key={index}
              button
              onClick={(e) => {
                selectProfile(profile);
              }}
            >
              <ListItemText primary={firstLetterUpperCase(profile.role)} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
});
export default withStyles(styles)(injectIntl(SelectProfile));
