import proj4 from "proj4";
import { wktToGeoJSON } from "@terraformer/wkt";
import wkx from "wkx";
import rewind from "geojson-rewind";
import reproject from "reproject";
import { centerOfMass } from "@turf/turf";
import moment from "moment";
import { ProjectDetailsType } from "../stores/models/ProjectDetailModel";
import { ProjectType } from "stores/ProjectStore";

const epsg = require("epsg");
proj4.defs(
  "EPSG:31370",
  "+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs ",
);

export const multiyPolygonFromWKT = (wkt: string) => {
  const geo = wktToGeoJSON(wkt);
  geo.crs = {
    type: "name",
    properties: {
      name: "EPSG:31370",
    },
  };
  const newWkt: any = wkx.Geometry.parseGeoJSON(reproject.toWgs84(geo, undefined, epsg));
  let geojson = rewind(newWkt.toGeoJSON(), false);
  geojson.crs = {
    type: "name",
    properties: {
      name: "EPSG:4326",
    },
  };
  geojson.properties = {
    center: centerOfMass(geojson)?.geometry?.coordinates,
  };
  return geojson;
};

export const formatProjects = (projects: ProjectType[]) => {
  let result = projects.map((project) => {
    project.geojson = project.wkt ? multiyPolygonFromWKT(project.wkt) : "";
    project.otherGeojson = project.otherwkt.map((otherWkt) => multiyPolygonFromWKT(otherWkt));
    project.indieningsdatum = moment(project.indieningsdatum).format("DD-MM-YYYY");
    return project;
  });
  return result;
};

export const formatProject = (project: ProjectDetailsType) => {
  let newProject = project;
  newProject.geojson = project.wkt ? multiyPolygonFromWKT(project.wkt) : "";
  newProject.otherGeojson = project.otherwkt.map((otherWkt) => multiyPolygonFromWKT(otherWkt));
  newProject.indieningsdatum = moment(project.indieningsdatum).format("DD-MM-YYYY");
  return newProject;
};
