import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HouseIcon from "@mui/icons-material/House";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import PersonIcon from "@mui/icons-material/Person";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RoomIcon from "@mui/icons-material/Room";
import SettingsIcon from "@mui/icons-material/Settings";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MapIcon from "@mui/icons-material/Map";

import {
  Alert,
  Autocomplete,
  Avatar,
  BottomNavigation,
  Button,
  Chip,
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Snackbar,
  SnackbarCloseReason,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useTheme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { StoresContext } from "index";
import { observer } from "mobx-react";
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ROUTE_BASE } from "routes/RouteList";
import { setDefaultHeader, getOmvConfMunicipality } from "services/api";
import { PlannenType } from "stores/models/ProjectDetailModel";
import BeperktOpenbaarOnderzoek from "./BeperktOpenbaarOnderzoek";
import useSidebarProjectStyles from "./SidebarProjectStyles";

const SidebarProject: FunctionComponent<{
  classes: any;
}> = observer(({ classes }) => {
  const {
    uiStore: {
      sideBarOpen,
      setSideBarOpen,
      splitscreenActive,
      isMobile,
      showTour,
      setSideBarTekstveldOpen,
      triggerSnack,
      projectDetail: { projectDetail, loadProjectDetail, changeVisibility, setActiveProject, loading },
      projectDetail: activeProject,
    },
    projectStore: { fetchProjects, projects },
    authStore: { token, role },
    routingStore: { push },
    mapStore: { nis },
  } = useContext(StoresContext);

  const { municipality, project, file1, file2 } = useParams<{ municipality: string; project: string; file1: string; file2: string }>();

  const [filteredPlannen, setFilteredPlannen] = useState<PlannenType>({});
  const [tabSelected, setTabSelected] = useState(file1 ? "Bestanden" : "Info");
  const [openedBestandsType, setOpenedBestandsType] = useState("Basis documenten");
  const [bestandsListOpen, setBestandsListOpen] = useState(true);
  const [toonMeer, setToonMeer] = useState(false);
  const [uniqueArchitects, setUniqueArchitects] = useState<string[]>([]);
  const [uniqueVVOs, setUniqueVVOs] = useState<string[]>([]);
  const [uniqueAanvragers, setUniqueAanvragers] = useState<string[]>([]);
  const [omgevingsLoketLinkActief, setOmgevingsLoketLinkActief] = useState<boolean>(false);
  const [beperktOpenbaarOnderzoekLinkActief, setBeperktOpenbaarOnderzoekLinkActief] = useState<boolean>(false);
  const projectNummer = projectDetail && projectDetail?.projectnummer;
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesLarge = useMediaQuery(theme.breakpoints.down("lg"));

  const [hasPosting, setHasPosting] = useState(false);

  useEffect(() => {
    async function getData() {
      if (nis) {
        const data = await getOmvConfMunicipality(nis);
        if (data?.email) {
          setHasPosting(!!data?.email);
        }
      }
    }
    getData();
  }, [nis]);

  useEffect(() => {
    if (matchesLarge) {
      setSideBarOpen(false);
    } else {
      setSideBarOpen(true);
    }
  }, [matchesLarge]);

  useEffect(() => {
    if (projectDetail) {
      let vvos: string[] = [];
      let architects: string[] = [];
      let aanvragers: string[] = [];
      projectDetail.personen.forEach((persoon) => {
        if (persoon.hoedanigheid === "VVO") {
          vvos.push(persoon.naam);
        } else if (persoon.hoedanigheid === "ARCHITECT_ONTW" || persoon.hoedanigheid === "ARCHITECT_UITV" || persoon.hoedanigheid === "ASSISTENT_ARCHITECT") {
          if (!persoon.voornaam) persoon.voornaam = "";
          architects.push(persoon.naam + " " + persoon.voornaam);
        } else if (persoon.hoedanigheid === "AANVRAGER") {
          if (!persoon.voornaam) persoon.voornaam = "";
          aanvragers.push(persoon.naam + " " + persoon.voornaam);
        }
      });

      //@ts-ignore
      vvos = [...new Set(vvos)];
      //@ts-ignore
      architects = [...new Set(architects)];
      //@ts-ignore
      aanvragers = [...new Set(aanvragers)];

      setUniqueArchitects(architects);
      setUniqueVVOs(vvos);
      setUniqueAanvragers(aanvragers);
      if (!file1) {
        setOpenedBestandsType(projectDetail.bestanden && Object.keys(projectDetail.bestanden).sort()[0]);
      }
    }
  }, [projectDetail]);

  const toggleOpened = (bestandKey) => {
    if (openedBestandsType === bestandKey) {
      setBestandsListOpen(!bestandsListOpen);
    } else if (!bestandsListOpen) {
      setOpenedBestandsType(bestandKey);
      setBestandsListOpen(!bestandsListOpen);
    } else {
      setOpenedBestandsType(bestandKey);
    }
  };

  const handleToonMeer = () => {
    setToonMeer(!toonMeer);
  };

  const handleplannenChange = (e) => {
    activeProject.setPlanType(e.target.innerHTML);
  };

  const getVisibilityIcon = (visiblity) => {
    switch (visiblity) {
      case 1:
        return <VisibilityOffIcon />;
      case 2:
        return <VisibilityOffIcon />;
      default:
        return <SettingsIcon />;
    }
  };

  const goToOpenbaarOmg = () => {
    window.open(`https://omgevingsloketpubliek.omgeving.vlaanderen.be/?projectnummer=${projectDetail.projectnummer}&organisator=${municipality}`);
  };

  useEffect(() => {
    if (showTour && tabSelected === "Bestanden" && !file1) {
      setTabSelected("Info");
    }
  }, [showTour, file1]);

  const handleChange = (e, value) => {
    setTabSelected(value);
  };

  const openTextvelden = () => {
    setSideBarTekstveldOpen(true);
  };

  const closeProject = async () => {
    if (matches) {
      setSideBarOpen(false);
    }
    setActiveProject(null as any);
    push(ROUTE_BASE + municipality);
  };

  const openDocument = (projectnummer: string, lokaleUri: string) => {
    if (!splitscreenActive || !file1) {
      push(`${ROUTE_BASE + municipality}/${projectnummer}/${lokaleUri}`);
    } else {
      push(`${ROUTE_BASE + municipality}/${projectnummer}/${file1}/${lokaleUri}`);
    }
  };

  useEffect(() => {
    if (projectDetail) {
      if (activeProject.planType === "Alle") {
        setFilteredPlannen(projectDetail.bestanden && projectDetail.bestanden["Plannen"]);
      } else {
        setFilteredPlannen({ xyz: projectDetail.bestanden["Plannen"][activeProject.planType] });
      }
    }
  }, [activeProject.planType, projectDetail]);

  useEffect(() => {
    if (projectDetail?.dossierstatussen[0]?.statusgeoportaal === "Openbaar Onderzoek" || projectDetail?.dossierstatussen[0]?.statusgeoportaal === "Beslissing") {
      setOmgevingsLoketLinkActief(true);
    } else {
      setOmgevingsLoketLinkActief(false);
    }
  }, [projectDetail]);

  useEffect(() => {
    if (role === "Dossierbeheerder" && projectDetail?.dossierstatussen[0]?.statusgeoportaal === "In Behandeling") {
      setBeperktOpenbaarOnderzoekLinkActief(true);
    } else {
      setBeperktOpenbaarOnderzoekLinkActief(false);
    }
  }, [projectDetail]);

  useEffect(() => {
    if (token) {
      setDefaultHeader(token);
      setSideBarOpen(true);
      if (project !== "BeperktOpenbaarOnderzoek") loadProjectDetail(nis, project);
      fetchProjects(nis); //fetchprojects uitvoeren anders heb je geen projecten op kaart als je rechtstreeks een projectdetail surft
    }
  }, [token, project]);

  const handleClickLinkOrAlert = (e) => {
    isMobile ? (window.location.href = `${window.env.AANPLAKKING_URL ?? "https://aanplakking.develop.geoportaal.be/"}${nis}/${projectNummer}`) : setOpenAlert(true);
  };

  const handleClose = (event: React.SyntheticEvent, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };
  const RenderProjectInfo = ({ primary, secondary, icon }: { key: any; primary: any; secondary: any; icon?: any }) => (
    <ListItem>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText inset={!icon} primary={primary} secondary={secondary} />
    </ListItem>
  );
  const RenderProjectWarning = ({ primary, icon }: { key: any; primary: any; icon?: any }) => (
    <ListItem>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText inset={!icon} primary={primary} />
    </ListItem>
  );

  const renderBestand = (bestand, bestandKey) => (
    <Fragment key={"fragment-" + bestand.lokale_uri}>
      <ListItem
        className={classes.listItem}
        key={bestand.lokale_uri}
        selected={bestand.lokale_uri === file1 || bestand.lokale_uri === file2}
        button
        onClick={() => {
          if (matches) {
            setSideBarOpen(false);
          }
          openDocument(project, bestand.lokale_uri);
        }}
      >
        <ListItemIcon>{bestand.lokale_uri === file1 ? <LooksOneIcon /> : bestand.lokale_uri === file2 ? <LooksTwoIcon /> : <PictureAsPdfIcon />}</ListItemIcon>
        <ListItemText>
          <Typography variant="caption" className={bestand.visibility === 2 ? classes.stripethrough : ""}>
            {bestand.bestandsnaam}
          </Typography>
        </ListItemText>
        {role === "Dossierbeheerder" && (
          <ListItemIcon>
            <IconButton
              onClick={async (e) => {
                e.stopPropagation();
                const { message, type } = await changeVisibility(nis, bestandKey, bestand.bestandsnaam, bestand.visibility, projectDetail.id);
                triggerSnack(message, type);
              }}
            >
              {getVisibilityIcon(bestand.visibility)}
            </IconButton>
          </ListItemIcon>
        )}
      </ListItem>
      <Divider />
    </Fragment>
  );

  const convertStatusvlag = (statusvlag) => {
    let status = statusvlag.toLowerCase();
    status = status.replace(/_/g, " ");
    status = status.replace("openonderz", "openbaar onderzoek");
    status = status.replace("gew", "gewestelijk");
    status = status.replace("on hold", "wacht");
    status = status.charAt(0).toUpperCase() + status.substr(1);
    return status;
  };

  return (
    <Drawer
      className={matches ? classes.drawerMobile : classes.drawer}
      variant="persistent"
      anchor="left"
      open={sideBarOpen}
      classes={{
        paper: matches ? classes.drawerPaperMobile : classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <Typography data-test-id="sidebarPoject-title" variant={matches ? "body1" : "h6"} className={classes.whiteText}>
          <IconButton
            aria-label="back"
            className={classes.backButton}
            onClick={() => {
              if (file1) {
                if (matches) {
                  setSideBarOpen(false);
                }
                push(`${ROUTE_BASE}${municipality}/${project}`);
              } else {
                closeProject();
              }
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          Project {projectDetail?.projectnummer}
        </Typography>
        <div className={classes.grow} />
        {matches && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              setSideBarOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
      <Tabs variant="fullWidth" indicatorColor="primary" textColor="primary" value={tabSelected} onChange={handleChange} className={classes.tabs}>
        <Tab value="Info" label="Info" icon={<InfoIcon />}></Tab>
        <Tab id="bestanden" value="Bestanden" label="Bestanden" icon={<PictureAsPdfIcon />}></Tab>
      </Tabs>
      {tabSelected === "Info" && (
        <Fragment>
          {loading && (
            <List>
              <ListItem>
                <ListItemIcon>
                  <Skeleton animation="wave" variant="circular" width={40} height={40} />
                </ListItemIcon>
                <ListItemText
                  primary={<Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />}
                  secondary={<Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  inset
                  primary={<Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />}
                  secondary={<Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemIcon>
                  <Skeleton animation="wave" variant="circular" width={40} height={40} />
                </ListItemIcon>
                <ListItemText
                  primary={<Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />}
                  secondary={<Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemIcon>
                  <Skeleton animation="wave" variant="circular" width={40} height={40} />
                </ListItemIcon>
                <ListItemText
                  primary={<Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />}
                  secondary={<Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemIcon>
                  <Skeleton animation="wave" variant="circular" width={40} height={40} />
                </ListItemIcon>
                <ListItemText
                  primary={<Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />}
                  secondary={<Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />}
                />
              </ListItem>
            </List>
          )}
          {!loading && (
            <Fragment>
              <div className={matches ? classes.tabContainerMobile : omgevingsLoketLinkActief ? classes.tabContainer : classes.tabContainerLarge}>
                <List id="informatie" className={classes.list} dense>
                  <RenderProjectInfo key={"projectinfo-omschrijving"} primary={"Project omschrijving"} secondary={projectDetail?.naam} icon={<InfoIcon color="secondary" />} />
                  <RenderProjectInfo key={"projectinfo-dossiertype"} primary={"Dossier type"} secondary={projectDetail?.dossiertype} />
                  <RenderProjectInfo key={"projectinfo-indieningsdatum"} primary={"Indieningsdatum"} secondary={projectDetail?.indieningsdatum.split("T")[0]} />
                  {(projectDetail?.dossierstatussen[0]?.statusgeoportaal === "Openbaar Onderzoek" || projectDetail?.dossierstatussen[0]?.statusgeoportaal === "Beslissing") &&
                    projectDetail?.dossierstatussen[0]?.begindatum && (
                      <RenderProjectInfo
                        key={"projectinfo-begindatum"}
                        primary={"Begin " + projectDetail?.dossierstatussen[0]?.statusgeoportaal?.toLowerCase()}
                        secondary={projectDetail?.dossierstatussen[0]?.begindatum.split("T")[0]}
                      />
                    )}
                  {(projectDetail?.dossierstatussen[0]?.statusgeoportaal === "Openbaar Onderzoek" || projectDetail?.dossierstatussen[0]?.statusgeoportaal === "Beslissing") &&
                    projectDetail?.dossierstatussen[0]?.einddatum && (
                      <RenderProjectInfo
                        key={"projectinfo-einddatum"}
                        primary={"Einde " + projectDetail?.dossierstatussen[0]?.statusgeoportaal?.toLowerCase()}
                        secondary={projectDetail?.dossierstatussen[0]?.einddatum.split("T")[0]}
                      />
                    )}
                  <RenderProjectInfo
                    key={"projectinfo-status"}
                    primary={projectDetail?.dossierstatussen !== null && "Status"}
                    secondary={
                      <List className={classes.statusList}>
                        {projectDetail?.dossierstatussen.slice(0, 3).map((status) => (
                          <ListItemText className={classes.statusListItem} key={status.statusvlag} secondary={convertStatusvlag(status.statusvlag)} />
                        ))}
                        {toonMeer &&
                          projectDetail?.dossierstatussen
                            .slice(3, projectDetail?.dossierstatussen.length)
                            .map((status) => <ListItemText className={classes.statusListItem} key={status.statusvlag} secondary={convertStatusvlag(status.statusvlag)} />)}
                        {projectDetail?.dossierstatussen.length > 3 && (
                          <Button
                            color="primary"
                            fullWidth
                            variant="outlined"
                            key="status-toonmeer"
                            endIcon={toonMeer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            size="small"
                            onClick={handleToonMeer}
                            className={classes.statusListButton}
                          >
                            {toonMeer ? "Toon minder" : "Toon meer"}
                          </Button>
                        )}
                        {omgevingsLoketLinkActief && (
                          <>
                            {hasPosting && (
                              <Button sx={{ marginTop: 1 }} fullWidth variant="contained" onClick={handleClickLinkOrAlert}>
                                Aanplakking melden
                              </Button>
                            )}
                            <Snackbar anchorOrigin={{ horizontal: "left", vertical: "bottom" }} open={openAlert} autoHideDuration={3000} onClose={handleClose}>
                              <Alert severity="info">Deze functie is enkel beschikbaar op mobiele apparaten!</Alert>
                            </Snackbar>
                          </>
                        )}
                      </List>
                    }
                  />
                  {(uniqueVVOs.length > 0 || uniqueAanvragers.length > 0 || uniqueArchitects.length > 0) && (
                    <Fragment>
                      <Divider variant="inset" component="li" />
                      {uniqueVVOs.length > 0 && (
                        <RenderProjectInfo
                          key={"projectinfo-vvo"}
                          primary={"Vergunning Verlenende Overheid"}
                          secondary={uniqueVVOs.map((vvo) => <span className={classes.vvo}>{vvo}</span>)}
                          icon={<PersonIcon color="secondary" />}
                        />
                      )}
                      {uniqueAanvragers.length > 0 && (
                        <RenderProjectInfo
                          key={"projectinfo-aanvrager"}
                          primary={"Aanvrager"}
                          secondary={uniqueAanvragers.map((aanvrager) => <span className={classes.vvo}>{aanvrager}</span>)}
                        />
                      )}
                      {uniqueArchitects.length > 0 && (
                        <RenderProjectInfo
                          key={"projectinfo-architect"}
                          primary={"Architect"}
                          secondary={uniqueArchitects.map((architect) => <span className={classes.vvo}>{architect}</span>)}
                        />
                      )}
                    </Fragment>
                  )}
                  {projectDetail?.adres?.length > 0 && (
                    <Fragment>
                      <Divider variant="inset" component="li" />
                      <RenderProjectInfo
                        key={"projectinfo-locaties"}
                        primary={"Adressen"}
                        secondary={projectDetail?.adres?.map((adres) => <ListItemText secondary={adres.straat + " " + adres.huisnummer} />)}
                        icon={<RoomIcon color="secondary" />}
                      />
                    </Fragment>
                  )}
                  {projectDetail?.perceelliggingen?.length > 0 && (
                    <Fragment>
                      <Divider variant="inset" component="li" />
                      <RenderProjectInfo
                        key={"projectinfo-percelen"}
                        primary={"Percelen"}
                        secondary={projectDetail?.perceelliggingen?.map((perseel) => <ListItemText secondary={perseel.capakey} />)}
                        icon={<HouseIcon color="secondary" />}
                      />
                    </Fragment>
                  )}
                </List>
              </div>

              {omgevingsLoketLinkActief && !matches && (
                <BottomNavigation className={classes.bottomNav}>
                  <Button
                    id="omgevingsloket"
                    startIcon={<img src="/assets/img/omgevingsloket.png"></img>}
                    fullWidth
                    size="small"
                    disableElevation
                    className={classes.bottomNavButton}
                    onClick={goToOpenbaarOmg}
                  >
                    Omgevingsloket
                  </Button>
                </BottomNavigation>
              )}
              {omgevingsLoketLinkActief && matches && (
                <BottomNavigation className={classes.bottomNav}>
                  <Grid container direction="row" justifyContent="space-around" alignItems="center">
                    <Grid item sx={{ textAlign: "center" }} xs={6}>
                      <Button
                        id="omgevingsloket"
                        startIcon={<img src="/assets/img/omgevingsloket.png"></img>}
                        size="small"
                        disableElevation
                        className={classes.bottomNavButton}
                        onClick={goToOpenbaarOmg}
                      >
                        Omgevingsloket
                      </Button>
                    </Grid>
                    <Grid item sx={{ textAlign: "center" }} xs={6}>
                      <Button
                        size="small"
                        variant="text"
                        className={classes.bottomNavButton}
                        onClick={() => {
                          setSideBarOpen(false);
                        }}
                      >
                        <MapIcon /> <span style={{ paddingLeft: 20 }}>Kaart</span>
                      </Button>
                    </Grid>
                  </Grid>
                </BottomNavigation>
              )}
              {beperktOpenbaarOnderzoekLinkActief && !matches && <BeperktOpenbaarOnderzoek />}
              {beperktOpenbaarOnderzoekLinkActief && matches && (
                <BottomNavigation className={classes.bottomNav}>
                  <Grid container direction="row" justifyContent="space-around" alignItems="center">
                    <Grid item sx={{ textAlign: "center" }} xs={8}>
                      <BeperktOpenbaarOnderzoek />
                    </Grid>
                    <Grid item sx={{ textAlign: "center" }} xs={4}>
                      <Button
                        variant="text"
                        className={classes.bottomNavButton}
                        onClick={() => {
                          setSideBarOpen(false);
                        }}
                      >
                        <MapIcon /> <span style={{ paddingLeft: 20 }}>Kaart</span>
                      </Button>
                    </Grid>
                  </Grid>
                </BottomNavigation>
              )}
              {projectDetail?.dossierstatussen[0]?.statusgeoportaal === "Ingediend" && matches && (
                <BottomNavigation className={classes.bottomNav}>
                  <Button
                    variant="text"
                    className={classes.bottomNavButton}
                    onClick={() => {
                      setSideBarOpen(false);
                    }}
                  >
                    <MapIcon /> <span style={{ paddingLeft: 20 }}>Kaart</span>
                  </Button>
                </BottomNavigation>
              )}
            </Fragment>
          )}
        </Fragment>
      )}

      {tabSelected === "Bestanden" && (
        <Fragment>
          <div className={matches ? classes.tabContainerMobile : classes.tabContainer}>
            {projectDetail && !projectDetail.bestanden && (
              <RenderProjectWarning key={"project-warning"} primary={"Dit project heeft geen zichtbare bestanden!"} icon={<WarningIcon color="secondary" />} />
            )}
            {projectDetail && projectDetail.bestanden && (
              <List className={classes.list}>
                {Object.keys(projectDetail.bestanden)
                  .sort()
                  .map((bestandKey) => (
                    <Fragment key={"fragment-" + bestandKey}>
                      <ListItemButton data-test-id={bestandKey} key={bestandKey} onClick={() => toggleOpened(bestandKey)}>
                        <ListItemText data-test-id="bestandKey">
                          <Typography component="span">{bestandKey} </Typography>
                          <Typography component="span" variant="overline">
                            (
                            {Array.isArray(projectDetail.bestanden[bestandKey])
                              ? projectDetail.bestanden[bestandKey].length
                              : Object.values(projectDetail.bestanden[bestandKey]).flat().length}
                            )
                          </Typography>
                        </ListItemText>

                        {openedBestandsType === bestandKey ? bestandsListOpen ? <ExpandLess /> : <ExpandMore /> : <ExpandMore />}
                      </ListItemButton>
                      <Divider />
                      <Collapse in={bestandsListOpen && openedBestandsType === bestandKey}>
                        {Array.isArray(projectDetail.bestanden[bestandKey]) ? (
                          <List className={classes.list}>
                            {projectDetail.bestanden[bestandKey].sort((a, b) => (a.bestandsnaam > b.bestandsnaam ? 1 : -1)).map((bestand) => renderBestand(bestand, bestandKey))}
                          </List>
                        ) : (
                          <Fragment>
                            <Autocomplete
                              id="plantype-select"
                              value={activeProject.planType}
                              disableClearable
                              onChange={handleplannenChange}
                              options={Object.keys(projectDetail.bestanden[bestandKey]).concat(["Alle"])}
                              getOptionLabel={(option) => option}
                              renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => <Chip avatar={<Avatar>{option.charAt(0)}</Avatar>} label={option} {...getTagProps({ index })} />)
                              }
                              fullWidth
                              renderInput={(params) => <TextField {...params} label="Kies type plan" variant="filled" />}
                            />
                            <List className={classes.list}>
                              {Object.keys(filteredPlannen).map((planType) =>
                                filteredPlannen[planType].sort((a, b) => (a.bestandsnaam > b.bestandsnaam ? 1 : -1)).map((plan) => renderBestand(plan, bestandKey)),
                              )}
                            </List>
                          </Fragment>
                        )}
                      </Collapse>
                    </Fragment>
                  ))}
              </List>
            )}
          </div>
          <BottomNavigation data-test-id="button-tekstvelden" className={classes.bottomNav}>
            {projectDetail?.datablokken?.length > 0 && (
              <Button className={classes.bottomNavButton} disableElevation fullWidth size="small" onClick={openTextvelden} startIcon={<CommentIcon />}>
                Tekstvelden
              </Button>
            )}
          </BottomNavigation>
        </Fragment>
      )}
    </Drawer>
  );
});

export default withStyles(useSidebarProjectStyles)(SidebarProject);
