import { action, decorate, observable } from "mobx";
import { changeVisibilityAPI, getProjectDetails } from "services/api";
import { ProjectStatus } from "stores/ProjectStore";
import { SnackType } from "../UiStore";

export interface ProjectDetailsType {
  id: number;
  projectnummer: string;
  naam: string;
  dossiertype: string;
  indieningsdatum: string;
  statusgeoportaal: ProjectStatus;
  personen: PersoonType[];
  adres: AdresType[];
  bestanden: BestandenType;
  wkt: string;
  otherwkt: string[];
  otherGeojson: any;
  geojson: any[];
  datablokken: DatablokType[];
  dossierstatussen: DossierStatusType[];
  perceelliggingen: PerseellingType[];
}

export interface PerseellingType {
  capakey: string;
}

export interface DossierStatusType {
  begindatum: string;
  einddatum: string;
  statusgeoportaal: ProjectStatus;
  statusgeoportaal_orderd: string;
  statusvlag: string;
}

export interface DatablokType {
  aard: string;
  data: DataType[];
}

export interface DataType {
  [key: string]: string;
}

export interface BestandenType {
  Adviezen: DocumentType[];
  "Basis documenten": DocumentType[];
  "Foto's": DocumentType[];
  Overige: DocumentType[];
  Milieu: DocumentType[];
  Plannen: PlannenType;
}

export interface DocumentType {
  aard: string;
  bestandsnaam: string;
  dossierstuk_id: string;
  inverse_schaal: string;
  lokale_uri: string;
  mimetype: string;
  visibility: number;
}

export interface PlannenType {
  Inplantingsplan?: DocumentType[];
  "Constructieve tekening"?: DocumentType[];
  "snede of profiel"?: DocumentType[];
  "Geveltekening of schema"?: DocumentType[];
  Grondplan?: DocumentType[];
  Legende?: DocumentType[];
  xyz?: any;
}

export interface AdresType {
  huisnummer: string;
  naam: string;
  straat: string;
}

export interface PersoonType {
  naam: string;
  voornaam: string;
  hoedanigheid: string;
}

class ProjectDetailModel {
  projectDetail: ProjectDetailsType;
  fileList: any = [];
  bestanden: BestandenType;
  planType: string = "Alle";
  loading: boolean = true;

  getTypeByFileName(fileName) {
    let type: string = "";
    for (let bestandType in this.bestanden) {
      if (bestandType !== "Plannen") {
        for (let bestand of this.bestanden[bestandType]) {
          if (bestand.lokale_uri === fileName) {
            type = bestandType;
          }
        }
      } else {
        for (let planSoort in this.bestanden.Plannen) {
          for (let planBestand of this.bestanden.Plannen[planSoort]) {
            if (planBestand.lokale_uri === fileName) {
              type = bestandType;
            }
          }
        }
      }
    }
    return type;
  }

  setPlanType = (type: string) => {
    this.planType = type;
  };

  setActiveProject = async (project: ProjectDetailsType) => {
    this.projectDetail = project;
    this.loading = false;
  };

  loadProjectDetail = async (municipality_id: number, projectNummer: string): Promise<string | void> => {
    this.loading = true;
    try {
      const data = await getProjectDetails(municipality_id, projectNummer);
      if (data) this.projectDetail = data;
      if (data?.bestanden) {
        this.bestanden = data.bestanden;
        for (let bestandType in data.bestanden) {
          if (bestandType !== "Plannen") {
            let newBestanden = data.bestanden[bestandType].sort((a, b) => (a.bestandsnaam > b.bestandsnaam ? 1 : -1));
            for (let bestand of newBestanden) {
              let type = this.getTypeByFileName(bestand.lokale_uri);
              bestand.type = type;
              this.fileList.push(bestand);
            }
          } else {
            for (let planSoort in data.bestanden.Plannen) {
              let newPlannen = data.bestanden.Plannen[planSoort].sort((a, b) => (a.bestandsnaam > b.bestandsnaam ? 1 : -1));
              for (let planBestand of newPlannen) {
                planBestand.type = "Plannen";
                planBestand.sort = planSoort;
                this.fileList.push(planBestand);
              }
            }
          }
        }
      }
    } catch (error) {}

    this.loading = false;
  };

  navigateFiles = (direction, fileName) => {
    let file = this.fileList.filter((file) => file.lokale_uri === fileName)[0];

    if (!file) return null;

    var fileType = file.type;
    let pos = this.fileList.indexOf(file);
    let newFile = null;
    if (direction === "next") {
      pos++;
      while (pos < this.fileList.length) {
        if (this.fileList[pos].type === fileType) {
          if (this.fileList[pos].type === "Plannen") {
            if (this.fileList[pos].sort === file.sort || this.planType === "Alle") {
              newFile = this.fileList[pos];
              break;
            }
          } else {
            newFile = this.fileList[pos];
            break;
          }
        }
        pos++;
      }
    } else if (direction === "prev") {
      pos--;
      while (pos !== -1) {
        if (this.fileList[pos].type === fileType) {
          if (this.fileList[pos].type === "Plannen") {
            if (this.fileList[pos].sort === file.sort || this.planType === "Alle") {
              newFile = this.fileList[pos];
              break;
            }
          } else {
            newFile = this.fileList[pos];
            break;
          }
        }
        pos--;
      }
    }
    return newFile;
  };

  changeVisibility = async (municipality_id: number, typeBestand: String, bestandsnaam: String, visibility, id) => {
    let status = parseInt(visibility);
    let newStatus;
    if (isNaN(status)) newStatus = 1;
    else if (status === 2) newStatus = 0;
    else newStatus = ++status;
    let action;
    if (newStatus === 0) action = "default";
    else if (newStatus === 1) action = "hidepublic";
    else if (newStatus === 2) action = "hide";

    if (action) {
      const response = await changeVisibilityAPI(action, id, bestandsnaam, municipality_id);
      if (response.status === 200) {
        if (typeBestand === "Plannen") {
          for (const aardCat in this.projectDetail.bestanden.Plannen) {
            let files: DocumentType[] = [];
            this.projectDetail.bestanden["Plannen"][aardCat].forEach((file) => {
              if (file.bestandsnaam === bestandsnaam) file.visibility = newStatus;
              files.push(file);
            });
          }
        } else {
          Object.keys(this.projectDetail.bestanden).map((bestandType) => {
            if (bestandType !== "Plannen") {
              let files: DocumentType[] = [];
              this.projectDetail.bestanden[bestandType].forEach((file) => {
                if (file.bestandsnaam === bestandsnaam) file.visibility = newStatus;
                files.push(file);
              });
            }
          });
        }
        let message = "De originele beveiliging van het bestand wordt hersteld.";
        if (newStatus === 1) message = " Het bestand is enkel zichtbaar voor ingelogde gebruikers";
        else if (newStatus === 2) message = "Het bestand is enkel zichtbaar voor dossierbeheerders";
        return {
          message,
          type: SnackType.INFO,
        };
      } else {
        console.log("Aanpassen van de visibility van het bestand is mislukt", action, typeBestand, bestandsnaam, visibility);
        return {
          message: "Aanpassen van de visibility van het bestand is mislukt.",
          type: SnackType.ERROR,
        };
      }
    }
    console.log("Aanpassen van de visibility van het bestand is mislukt", action, typeBestand, bestandsnaam, visibility);
    return {
      message: "Aanpassen van de visibility van het bestand is mislukt.",
      type: SnackType.ERROR,
    };
  };
}

export default ProjectDetailModel;

decorate(ProjectDetailModel, {
  projectDetail: [observable],
  loading: [observable],
  planType: [observable],
  setPlanType: action.bound,
  loadProjectDetail: action.bound,
  changeVisibility: action.bound,
  navigateFiles: action.bound,
  setActiveProject: action.bound,
});
