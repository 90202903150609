// @ts-nocheck
import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useSnackbar } from "notistack";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useSnackStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 400,
    minWidth: 344,
  },
  errorMessage: {
    maxHeight: 200,
    overflow: "auto",
  },
  typography: {
    fontWeight: "bold",
  },
  actionRoot: {
    padding: "8px 8px 8px 16px",
    backgroundColor: "#fddc6c",
  },
  action: {
    margin: 0,
  },
  icons: {
    marginLeft: "auto !important",
  },
  expand: {
    padding: "8px 8px",
    transform: "rotate(0deg)",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  collapse: {
    padding: 16,
  },
  checkIcon: {
    fontSize: 20,
    color: "#b3b3b3",
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: "none",
  },
}));

const SnackMessage = React.forwardRef((props, ref) => {
  const {
    title = "Melding van Magda",
    message,
    isExpanded = false,
    link = {
      url: "https://wiki.geoportaal.be/nl/Legger/Meldingen",
      text: "Meer over Meldingen",
    },
  } = props;

  const classes = useSnackStyles();
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(isExpanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDismiss = () => {
    closeSnackbar(props.id);
  };

  return (
    <Card ref={ref} className={classes.card} data-test-id="card-meldingmagda">
      <CardActions classes={{ root: classes.actionRoot, action: classes.action }}>
        <Typography variant="subtitle2" className={classes.typography}>
          {title}
        </Typography>
        <div className={classes.icons}>
          <IconButton
            aria-label="Show more"
            className={classnames(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
          >
            <ExpandMoreIcon />
          </IconButton>
          <IconButton className={classes.expand} onClick={handleDismiss}>
            <CloseIcon />
          </IconButton>
        </div>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Paper className={classes.collapse}>
          {message.map((error) => (
            <Typography className={classes.errorMessage} gutterBottom>
              {error}
            </Typography>
          ))}

          {/*  <Button size="small" className={classes.button} onClick={() => open(link.url, "_blank")}>
            <CheckCircleIcon className={classes.checkIcon} />
            {link.text}
          </Button> */}
        </Paper>
      </Collapse>
    </Card>
  );
});

SnackMessage.propTypes = {
  id: PropTypes.number.isRequired,
};

export default SnackMessage;
