import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CloseIcon from "@mui/icons-material/Close";
import GavelIcon from "@mui/icons-material/Gavel";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import PublicIcon from "@mui/icons-material/Public";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { withStyles } from "@mui/styles";
import fuzzysort from "fuzzysort";
import { StoresContext } from "index";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ROUTE_BASE } from "routes/RouteList";
import { accessBOO } from "services/api";
import { AanvragerType, ProjectStatus, ProjectType } from "stores/ProjectStore";
import { useDebounce } from "use-debounce";
import useWidth from "../../../components/UseWidth";
import { multiyPolygonFromWKT } from "../../../services/omgeving";
import useSidebarStyles from "./SidebarStyles";

const Sidebar: FunctionComponent<{
  classes: any;
}> = observer(({ classes }) => {
  const {
    uiStore: { sideBarOpen, setSideBarOpen, projectDetail },
    authStore: { isLoggedIn, isBurger, token },
    routingStore: { push },
    mapStore: { nis },
    projectStore: { projStatus, setProjStatus, filteredItems, setFilteredItems, projects, fetchProjects, loading },
  } = useContext(StoresContext);

  const width = useWidth();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesLarge = useMediaQuery(theme.breakpoints.down("lg"));

  const [search, setSearch] = useState<string>("");
  const [searchDebounced] = useDebounce(search, 250);
  const [filteredScore, setFilteredScore] = useState<number>(0);
  const { municipality } = useParams<{ municipality: string }>();
  const [open, setOpen] = React.useState(false);
  const [code, setCode] = React.useState<any>();

  useEffect(() => {
    if (matchesLarge) {
      setSideBarOpen(false);
    } else {
      setSideBarOpen(true);
    }
  }, [matchesLarge]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleProjectOphalen = async () => {
    const BOOproject = await accessBOO(code);
    if (BOOproject && BOOproject.id) {
      BOOproject.type = "BeperktOpenbaarOnderzoek";
      BOOproject.statusgeoportaal = "BeperktOpenbaarOnderzoek";
      BOOproject.geojson = BOOproject.wkt ? multiyPolygonFromWKT(BOOproject.wkt) : "";
      BOOproject.otherGeojson = BOOproject.otherwkt.map((otherWkt) => multiyPolygonFromWKT(otherWkt));
      BOOproject.indieningsdatum = moment(BOOproject.indieningsdatum).format("DD-MM-YYYY");
      projectDetail.setActiveProject(BOOproject);
      setFilteredItems([BOOproject]);
      push(ROUTE_BASE + municipality + "/BeperktOpenbaarOnderzoek");
    } else {
      alert("Ongeldige code");
    }
  };

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const onSearchChange = (e) => {
    setSearch(e.target.value.trim());
  };

  const openProject = (projectnummer: string) => {
    push(ROUTE_BASE + municipality + "/" + projectnummer);
  };

  const getAanvragers = (project: ProjectType) => {
    let aanvragers: AanvragerType[] = [];
    if (project.personen) {
      for (let persoon of project.personen) {
        if (persoon.hoedanigheid === "AANVRAGER") {
          let aanvrager: AanvragerType = {
            voornaam: "",
            naam: "",
            volledig: "",
            volledigReversed: "",
          };
          if (persoon.voornaam) aanvrager.voornaam = persoon.voornaam;
          else aanvrager.voornaam = "";
          if (persoon.naam) aanvrager.naam = persoon.naam;
          else aanvrager.naam = "";
          aanvrager.volledig = aanvrager.voornaam + " " + aanvrager.naam;
          aanvrager.volledigReversed = aanvrager.naam + " " + aanvrager.voornaam;
          aanvragers.push(aanvrager);
        }
      }
    } else {
      let aanvrager: AanvragerType = {
        voornaam: "",
        naam: "",
        volledig: "",
        volledigReversed: "",
      };
      aanvrager.volledig = "N/A";
      aanvragers.push(aanvrager);
    }
    if (aanvragers.length === 0) {
      aanvragers.push({
        voornaam: "",
        naam: "",
        volledig: "",
        volledigReversed: "",
      });
    }
    return aanvragers;
  };

  useEffect(() => {
    const myFilteredProjects = projects
      .filter((project) => project?.statusgeoportaal === projStatus || projStatus === ProjectStatus.ALLE)
      .sort((a, b) => (a.naam > b.naam ? 1 : -1));
    if (!search || search.length === 0) {
      setFilteredItems(myFilteredProjects);
    } else {
      let fuseProjects: ProjectType[] = [];
      for (let project of myFilteredProjects) {
        project.straathn = project.adres ? project.adres.straat + " " + project.adres.huisnummer : "";
        let aanvragers = getAanvragers(project);
        let aantAanvragers = aanvragers.length;
        let x;
        for (x = 0; x < aantAanvragers; x++) {
          project["aanvrager" + x + "_vol"] = aanvragers[x].volledig;
          project["aanvrager" + x + "_volReversed"] = aanvragers[x].volledigReversed;
          project["aanvrager" + x + "_voorn"] = aanvragers[x].voornaam;
          project["aanvrager" + x + "_naam"] = aanvragers[x].naam;
        }
        let y;
        for (y = aantAanvragers; y < 10; y++) {
          project["aanvrager" + y + "_vol"] = "";
          project["aanvrager" + y + "_volReversed"] = "";
          project["aanvrager" + y + "_voorn"] = "";
          project["aanvrager" + y + "_naam"] = "";
        }
        let duplicate = fuseProjects.filter(function (obj) {
          return obj.projectnummer === project.projectnummer;
        })[0];
        if (!duplicate) {
          fuseProjects.push(project);
        }
      }
      let searchParam: string[] = [];
      if (isLoggedIn && !isBurger) {
        searchParam = [
          "naam",
          "projectnummer",
          "dossier_aard",
          "huisnummer",
          "straat",
          "straathn",
          "aanvrager0_vol",
          "aanvrager0_volReversed",
          "aanvrager0_voorn",
          "aanvrager0_naam",
          "aanvrager1_vol",
          "aanvrager1_volReversed",
          "aanvrager1_voorn",
          "aanvrager1_naam",
          "aanvrager2_vol",
          "aanvrager2_volReversed",
          "aanvrager2_voorn",
          "aanvrager2_naam",
          "aanvrager3_vol",
          "aanvrager3_volReversed",
          "aanvrager3_voorn",
          "aanvrager3_naam",
          "aanvrager4_vol",
          "aanvrager4_volReversed",
          "aanvrager4_voorn",
          "aanvrager4_naam",
          "aanvrager5_vol",
          "aanvrager5_volReversed",
          "aanvrager5_voorn",
          "aanvrager5_naam",
          "aanvrager6_vol",
          "aanvrager6_volReversed",
          "aanvrager6_voorn",
          "aanvrager6_naam",
          "aanvrager7_vol",
          "aanvrager7_volReversed",
          "aanvrager7_voorn",
          "aanvrager7_naam",
          "aanvrager8_vol",
          "aanvrager8_volReversed",
          "aanvrager8_voorn",
          "aanvrager8_naam",
          "aanvrager9_vol",
          "aanvrager9_volReversed",
          "aanvrager9_voorn",
          "aanvrager9_naam",
        ];
      } else {
        searchParam = ["naam", "projectnummer", "dossier_aard", "huisnummer", "straat", "straathn"];
      }
      const result = fuzzysort.go(search, fuseProjects, { keys: searchParam });
      let newFilteredScore = 0;
      const filteredProjects: ProjectType[] = [];
      result.forEach((proj) => {
        if (proj.score > -1000) newFilteredScore++;
        filteredProjects.push(proj.obj);
      });
      setFilteredScore(newFilteredScore);
      filteredProjects.sort((a, b) => (a.naam > b.naam ? 1 : -1));
      setFilteredItems(filteredProjects);
    }
  }, [search, projects, projStatus]);

  const handleProjChange = (e) => {
    setProjStatus(e.target.value);
  };

  useEffect(() => {
    if (token && nis) {
      //setDefaultHeader(token);
      fetchProjects(nis);
      setSideBarOpen(true);
    }
  }, [token, nis]);

  const renderIcon = (projStatus: ProjectStatus) => {
    switch (projStatus) {
      case ProjectStatus.OPENBAAR_ONDERZOEK:
        return <PublicIcon className={classes.PublicItem} />;
      case ProjectStatus.BEPERKT_OPENBAAR_ONDERZOEK:
        return <PublicIcon className={classes.PublicItemLimited} />;
      case ProjectStatus.INGEDIEND:
        return <AssignmentTurnedInIcon className={classes.IngediendItem} />;
      case ProjectStatus.IN_BEHANDELING:
        return <HourglassFullIcon className={classes.BehandelingsItem} />;
      case ProjectStatus.BESLISSING:
        return <GavelIcon className={classes.BeslissingsItem} />;
      default:
        console.log("ADD ICON FOR FOLLOWING PROJECTSTATUS:", projStatus);
        return <></>;
    }
  };

  return (
    <Drawer
      className={matches ? classes.drawerMobile : classes.drawer}
      variant="persistent"
      anchor="left"
      open={sideBarOpen}
      classes={{
        paper: matches ? classes.drawerPaperMobile : classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <Typography data-test-id="sidebarProjects-title" variant="h6" className={classes.whiteText}>
          Projecten
        </Typography>
        <div className={classes.grow} />
        {["xs", "sm", "md"].includes(width) && (
          <IconButton
            data-test-id="close-icon"
            aria-label="close"
            className={classes.closeButton}
            onClick={(e) => {
              setSideBarOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
      <FormControl className={classes.formControl}>
        <InputLabel variant="standard" id="projectstatus-label">
          Toon alleen projecten met status
        </InputLabel>
        <Select labelId="projectstatus-label" variant="standard" id="projectstatus" value={projStatus} onChange={handleProjChange}>
          <MenuItem key={ProjectStatus.ALLE} value={ProjectStatus.ALLE}>
            <ListItemText primary={"Alle"} />
            <ListItemSecondaryAction style={{ pointerEvents: "none" }}>
              <StarIcon className={classes.AlleItem} />
            </ListItemSecondaryAction>
          </MenuItem>
          {isLoggedIn && !isBurger && (
            <MenuItem key={ProjectStatus.INGEDIEND} value={ProjectStatus.INGEDIEND}>
              <ListItemText primary={"Ingediend"} />
              <ListItemSecondaryAction style={{ pointerEvents: "none" }}>
                <AssignmentTurnedInIcon className={classes.IngediendItem} />
              </ListItemSecondaryAction>
            </MenuItem>
          )}
          <MenuItem key={ProjectStatus.BESLISSING} value={ProjectStatus.BESLISSING}>
            <ListItemText primary={"Beslissing"} />
            <ListItemSecondaryAction style={{ pointerEvents: "none" }}>
              <GavelIcon className={classes.BeslissingsItem} />
            </ListItemSecondaryAction>
          </MenuItem>
          {isLoggedIn && !isBurger && (
            <MenuItem key={ProjectStatus.IN_BEHANDELING} value={ProjectStatus.IN_BEHANDELING}>
              <ListItemText primary={"In Behandeling"} />
              <ListItemSecondaryAction style={{ pointerEvents: "none" }}>
                <HourglassFullIcon className={classes.BehandelingsItem} />
              </ListItemSecondaryAction>
            </MenuItem>
          )}
          <MenuItem key={ProjectStatus.OPENBAAR_ONDERZOEK} value={ProjectStatus.OPENBAAR_ONDERZOEK}>
            <ListItemText primary={"Openbaar Onderzoek"} />
            <ListItemSecondaryAction style={{ pointerEvents: "none" }}>
              <PublicIcon className={classes.PublicItem} />
            </ListItemSecondaryAction>
          </MenuItem>
          {isLoggedIn && isBurger && (
            <MenuItem onClick={handleClickOpen} key={ProjectStatus.BEPERKT_OPENBAAR_ONDERZOEK} value={ProjectStatus.BEPERKT_OPENBAAR_ONDERZOEK}>
              <ListItemText primary={"Beperkt Openbaar Onderzoek"} />
              <ListItemSecondaryAction style={{ pointerEvents: "none" }}>
                <PublicIcon className={classes.PublicItemLimited} />
              </ListItemSecondaryAction>
            </MenuItem>
          )}
        </Select>
        <TextField
          className={classes.search}
          id="project-search"
          label="Zoeken in projecten..."
          variant="standard"
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          value={search}
          onChange={onSearchChange}
          fullWidth
          helperText={search.length > 0 && `${filteredScore} exacte overeenkomst(en)`}
        />
      </FormControl>
      {ProjectStatus.BEPERKT_OPENBAAR_ONDERZOEK && (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <AppBar position="static">
            <DialogTitle>Beperkt Openbaar Onderzoek raadplegen</DialogTitle>
          </AppBar>
          <DialogContent>
            <DialogContentText>
              <Typography>Geef uw code in om een project uit</Typography>
              <Typography paragraph>beperkt openbaar onderzoek te raadplegen.</Typography>
            </DialogContentText>
            <TextField value={code} onChange={handleCodeChange} autoFocus margin="dense" id="code" type="text" fullWidth />
          </DialogContent>
          <DialogActions className={classes.leftAlignDialogActions}>
            <Button onClick={handleProjectOphalen} color="secondary" variant="contained" style={{ textAlign: "left" }}>
              <Typography style={{ color: "#fff" }}>{"Project ophalen"}</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Divider />
      <List id="tour-selecteren" className={classes.list}>
        {loading && filteredItems.length > 0 && (
          <ListItem>
            <ListItemText
              primary={<Skeleton animation="wave" height={21} width="90%" style={{ marginBottom: 6 }} />}
              secondary={
                <Fragment>
                  <Skeleton animation="wave" height={19} width="90%" style={{ marginBottom: 6 }} />
                  <Skeleton animation="wave" height={19} width="90%" style={{ marginBottom: 6 }} />
                </Fragment>
              }
            />
            <ListItemSecondaryAction>
              <Skeleton animation="wave" variant="circular" width={40} height={40} />
            </ListItemSecondaryAction>
          </ListItem>
        )}
        {!loading &&
          filteredItems
            .sort((a, b) => (a.naam > b.naam ? 1 : -1))
            .map((project) => (
              <Fragment key={"fragment-" + project.id}>
                <ListItem
                  key={project.id}
                  className={classes.listItem}
                  button
                  dense
                  onClick={() => {
                    if (matches) {
                      setSideBarOpen(false);
                    }
                    openProject(project.projectnummer);
                  }}
                  alignItems="center"
                >
                  <ListItemText
                    primary={
                      <Typography data-test-id="project-name" variant="subtitle2" color="textPrimary">
                        {project.naam}
                      </Typography>
                    }
                    secondary={
                      <Fragment>
                        <Typography data-test-id="project-number" className={classes.subTitle} variant="caption" color="textSecondary">
                          {project.projectnummer}
                        </Typography>
                        <Typography data-test-id="project-address" className={classes.subTitle} variant="caption" color="textSecondary">
                          {project.adres?.straat} {project.adres?.huisnummer}
                          {/* project.personen.map(aanvrager => aanvrager.naam + " " + aanvrager.voornaam) */}
                        </Typography>
                      </Fragment>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="statusgeoportaal"
                      onClick={() => {
                        openProject(project.projectnummer);
                      }}
                    >
                      {renderIcon(project?.statusgeoportaal)}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </Fragment>
            ))}
      </List>
    </Drawer>
  );
});

export default withStyles(useSidebarStyles)(Sidebar);
