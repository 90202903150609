import { LayerProps } from "react-map-gl";

export const clusterLayer = {
  id: "clusters",
  type: "circle",
  source: "cluster-pins",
  filter: ["has", "point_count"],
  paint: {
    // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
    // with three steps to implement three types of circles:
    //   * Blue, 25px circles when point count is less than 10
    //   * Yellow, 25px circles when point count is between 10 and 100
    //   * Pink, 25px circles when point count is greater than or equal to 100
    "circle-color": ["step", ["get", "point_count"], "#039BE5", 10, "#3949ab", 100, "#f28cb1"],
    "circle-radius": ["step", ["get", "point_count"], 25, 10, 25, 100, 25],
  },
} as LayerProps;

export const clusterCountLayer = {
  id: "cluster-count",
  type: "symbol",
  source: "cluster-pins",
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count}",
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 16,
    "text-allow-overlap": true,
  },
  paint: {
    "text-color": "#fff",
  },
} as LayerProps;

export const unclusteredPointLayer = {
  id: "unclustered-point",
  type: "symbol",
  source: "cluster-pins",
  interactive: true,
  filter: ["!", ["has", "point_count"]],
  layout: {
    "icon-image": "background",
    "icon-size": 1.3,
    "icon-text-fit": "both",
    "icon-allow-overlap": true,
    "icon-anchor": "bottom",
    "icon-offset": [0, 2],
    "text-padding": 0,
    "text-offset": [0, -0.6],
    "text-allow-overlap": true,
    "text-field": [
      "match",
      ["get", "status"],
      "openbaaronderzoek",
      String.fromCodePoint(0xe80b),
      "ingediend",
      String.fromCodePoint(0xe862),
      "beslissing",
      String.fromCodePoint(0xe90e),
      "beperktopenbaaronderzoek",
      String.fromCodePoint(0xe80b),
      "inbehandeling",
      String.fromCodePoint(0xe88c),
      String.fromCodePoint(0xe575),
    ],
    "text-font": ["Material Icons Regular"],
    "text-size": 26,
  },

  paint: {
    "icon-color": [
      "match",
      ["get", "status"],
      "openbaaronderzoek",
      "#008000",
      "ingediend",
      "#ff6600",
      "beslissing",
      "#660066",
      "beperktopenbaaronderzoek",
      "#8BC34A",
      "inbehandeling",
      "#0000FF",
      "#FF0000",
    ],
    "text-color": "#fff",
  },
} as LayerProps;

export const spiderLineLayer = {
  id: "lineLayer",
  type: "line",
  source: "my-data",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "orange",
    "line-width": 4,
    "line-opacity": 0.5,
    "line-dasharray": [0.5, 1],
    "line-dasharray-transition": {
      duration: 1,
    },
  },
} as LayerProps;

export const spiderPointLayer = {
  ...unclusteredPointLayer,
  source: "cluster-spiderify",
  id: "cluster-spiderify",
  interactive: true,
} as LayerProps;
