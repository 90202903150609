import { observable, action, computed, decorate } from "mobx";
import bbox from "@turf/bbox";

/**
 * model used to translate the actions in the
 * 3D mapping cloud plugion
 *
 */
class MappingCloudModel {
  channel: any = null;

  mcActive: boolean = false;

  footprints: any = [];

  fovs: any = [];

  svgLayer: any = null;

  pickPosition: boolean = false;

  pingFootprints: boolean = false;

  setBounds: any;

  constructor(setBounds) {
    this.setBounds = setBounds;
    this.channel = new BroadcastChannel("3DMC_OMGEVING");
  }

  enableMc = () => {
    this.mcActive = true;
    window.open(window.location.origin + "/3dmc_viewer.html", "_blank");
    this.channel.onmessage = (event) => {
      //console.log({event});
      switch (event.data.type) {
        case "disableMC":
          this.disableMc();
          break;
        case "workspaceOpened":
          this.pingFootprints = true;
          break;
        case "fov":
          this.fovs = event.data.data;
          break;
        case "footprints":
          this.footprints = event.data.data;
          break;
        case "pickPositionRequested":
          this.pickPosition = true;
          break;
        case "pickPositionCancelled":
          this.pickPosition = false;
          break;
        case "zoomToExtent":
          const { bounds } = event.data;
          const geoJson = {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [bounds.minX, bounds.minY],
                  [bounds.maxX, bounds.minY],
                  [bounds.maxX, bounds.maxY],
                  [bounds.minX, bounds.maxY],
                  [bounds.minX, bounds.minY],
                ],
              ],
            },
          };
          this.setBounds(bbox(geoJson)); // @TODO: controleren of deze bbox klopt (is via turf)
          break;
        default:
          console.log("No code for this type:", event.data.type);
          break;
      }
    };
  };

  disableMc = () => {
    this.mcActive = false;
    this.pingFootprints = false;
    this.pickPosition = false;
    this.channel.onmessage = null;
  };

  setSvgLayer = (value) => {
    this.svgLayer = value;
  };

  fetchFootprints = (bounds, map) => {
    if (this.mcActive) {
      const body = {
        type: "getFootprints2D",
        bounds: [bounds._sw.lng, bounds._sw.lat, bounds._ne.lng, bounds._ne.lat],
        crs: "4326",
        zoomRatio: 0,
      };
      this.channel.postMessage(body);
    } else {
      console.log("User tried to fetch footprints but MC is not active.");
    }
  };

  fetchFovs = () => {
    if (this.mcActive) {
      const body = {
        type: "getFieldOfViews2D",
      };
      this.channel.postMessage(body);
    } else {
      console.log("User tried to fetch fovs but MC is not active.");
    }
  };

  setPickPosition = (x, y) => {
    if (this.mcActive) {
      const body = {
        type: "setPickPosition",
        x,
        y,
        crs: "4326",
      };
      this.channel.postMessage(body);
    } else {
      console.log("User tried to set pickPosition but MC is not active.");
    }
  };
}

export default MappingCloudModel;

decorate(MappingCloudModel, {
  mcActive: [observable],
  footprints: [observable],
  fovs: [observable],
  svgLayer: [observable],
  pickPosition: [observable],
  pingFootprints: [observable],
  enableMc: action.bound,
  disableMc: action.bound,
  setSvgLayer: action.bound,
  fetchFootprints: action.bound,
  fetchFovs: action.bound,
  setPickPosition: action.bound,
});
