import { action, decorate, observable } from "mobx";
import { persist } from "mobx-persist";
import WebMercatorViewport from "@math.gl/web-mercator";
import { getLocation } from "../services/geo";
import { LatLngType, Location } from "../types/genericTypes";
import MapLayerModel from "./models/MapLayerModel";
import MappingCloudModel from "./models/MappingCloudModel";

export default class MapStore {
  /**
   * model used to control the layer of the maps
   */

  mapLayer: MapLayerModel = new MapLayerModel();

  mappingCloud: MappingCloudModel;

  latLng: LatLngType = {
    lat: 50.82803,
    lng: 3.26487,
  };

  bounds: any = [
    [0, 0],
    [0, 0],
  ];

  searchLocation: any = null;

  viewport: any = {};

  zoomLevel: number = 18;

  nis: number;

  constructor() {
    this.mappingCloud = new MappingCloudModel(this.setBounds);
  }

  update = (key: string, value: any) => {
    this[key] = value;
  };

  setLatLng = (latLng: LatLngType) => {
    this.latLng = latLng;
  };

  setNis = (newNis: number) => {
    this.nis = newNis;
  };

  setBounds = (bounds: any, pad = 0.3) => {
    if (bounds) bounds = bounds.pad(pad);
    this.bounds = bounds;
  };

  setZoomLevel = (zoomLevel: number) => {
    this.zoomLevel = zoomLevel;
  };

  setSearchLocation = (location: any) => {
    this.searchLocation = location;
  };

  async searchLatLongForLocation(location: Location) {
    const result: any = await getLocation(location); // todo
    this.latLng = result;
  }

  fitBoundsMap = (bounds: [[number, number], [number, number]], mapHeight: number, mapWidth: number, padding: number = 75) => {
    const mercatorViewport = new WebMercatorViewport({ height: mapHeight, width: mapWidth });

    const bound = mercatorViewport.fitBounds(bounds, { padding });
    const zoom = bound.zoom <= 18 ? bound.zoom : 18;
    this.setZoomLevel(zoom);
    this.setLatLng({
      lat: bound.latitude,
      lng: bound.longitude,
    });
  };
}

decorate(MapStore, {
  mapLayer: [observable, persist("object", MapLayerModel)],
  latLng: [observable, persist("object")],
  viewport: [observable, persist("object")],
  nis: [observable, persist],
  bounds: [observable],
  zoomLevel: [observable],
  searchLocation: [observable],
  mappingCloud: [observable],
  setLatLng: action.bound,
  setNis: action.bound,
  setBounds: action.bound,
  setZoomLevel: action.bound,
  searchLatLongForLocation: action.bound,
  fitBoundsMap: action.bound,
  update: action.bound,
  setSearchLocation: action.bound,
});
