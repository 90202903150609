import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useControlPanelStyles = (theme: Theme) =>
  createStyles({
    controlPanel: {
      position: "absolute",
      bottom: "32px",
      left: "10px",
      zIndex: 1,
      backgroundColor: "#00acc1",
      color: "#fff",
      fontSize: "16px",
      lineHeight: "20px",
      display: "block",
      margin: 0,
      borderRadius: "3px",
      textAlign: "left",
      maxWidth: "350px",
      padding: "0 5px",
    },
    controlPanelMobile: {
      position: "absolute",
      bottom: "57px",
      left: "10px",
      zIndex: 1,
      backgroundColor: "#00acc1",
      color: "#fff",
      fontSize: "16px",
      lineHeight: "20px",
      display: "block",
      margin: 0,
      borderRadius: "3px",
      textAlign: "left",
      maxWidth: "350px",
      padding: "0 5px",
    },
  });

export default useControlPanelStyles;
