import { action, computed, decorate, observable } from "mobx";
import { getProjects } from "../services/api";
export default class ProjectStore {
  projects: ProjectType[] = [];
  municipality_id: string;
  loading: boolean = false;
  projStatus: ProjectStatus = ProjectStatus.ALLE;
  filteredItems: ProjectType[] = [];

  get projectsGeoJSON(): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
    const geoJson: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
      type: "FeatureCollection",
      features: [],
    };

    if (this.filteredItems.length === 0) {
      return geoJson;
    }

    for (const project of this.filteredItems) {
      geoJson.features.push({
        type: "Feature",
        properties: { project: { ...project }, status: project?.statusgeoportaal?.toLowerCase().replace(/\s/g, "") },
        geometry: {
          type: "Point",
          coordinates: project.geojson.properties.center,
        },
      });
    }

    return geoJson;
  }

  setProjStatus = (projStatus: ProjectStatus) => {
    this.projStatus = projStatus;
  };
  setFilteredItems = (filteredItems: ProjectType[]) => {
    this.filteredItems = filteredItems;
  };

  fetchNewProjects = (municipality_id) => {
    if (municipality_id === 0) {
      return;
    }
    this.projects = [];
    if (this.municipality_id !== municipality_id) {
      this.fetchProjects(municipality_id);
      this.municipality_id = municipality_id;
    }
  };

  fetchProjects = async (municipality_id) => {
    if (this.projects.length > 0 || this.loading === true) {
      return;
    }
    this.loading = true;
    const data = await getProjects(municipality_id);
    this.projects = data;
    this.filteredItems = data;
    this.loading = false;
  };
}

decorate(ProjectStore, {
  projects: [observable],
  projectsGeoJSON: [computed],
  loading: [observable],
  projStatus: [observable],
  filteredItems: [observable],
  setProjStatus: action.bound,
  setFilteredItems: action.bound,
});

export enum ProjectStatus {
  ALLE = "Alle",
  INGEDIEND = "Ingediend",
  IN_BEHANDELING = "In Behandeling",
  BESLISSING = "Beslissing",
  OPENBAAR_ONDERZOEK = "Openbaar Onderzoek",
  BEPERKT_OPENBAAR_ONDERZOEK = "Beperkt Openbaar Onderzoek",
}

export interface ProjectType {
  id: number;
  projectnummer: string;
  naam: string;
  dossiertype: string;
  indieningsdatum: string;
  statusgeoportaal: ProjectStatus;
  personen: PersoonType[];
  adres: AdresType;
  wkt: string;
  geojson: any;
  otherGeojson: any[];
  otherwkt: string[];
  straathn?: string;
  aanvrager0_vol?: string;
  aanvrager0_volReversed?: string;
  aanvrager0_voorn?: string;
  aanvrager0_naam?: string;
  aanvrager1_vol?: string;
  aanvrager1_volReversed?: string;
  aanvrager1_voorn?: string;
  aanvrager1_naam?: string;
  aanvrager2_vol?: string;
  aanvrager2_volReversed?: string;
  aanvrager2_voorn?: string;
  aanvrager2_naam?: string;
  aanvrager3_vol?: string;
  aanvrager3_volReversed?: string;
  aanvrager3_voorn?: string;
  aanvrager3_naam?: string;
  aanvrager4_vol?: string;
  aanvrager4_volReversed?: string;
  aanvrager4_voorn?: string;
  aanvrager4_naam?: string;
  aanvrager5_vol?: string;
  aanvrager5_volReversed?: string;
  aanvrager5_voorn?: string;
  aanvrager5_naam?: string;
  aanvrager6_vol?: string;
  aanvrager6_volReversed?: string;
  aanvrager6_voorn?: string;
  aanvrager6_naam?: string;
  aanvrager7_vol?: string;
  aanvrager7_volReversed?: string;
  aanvrager7_voorn?: string;
  aanvrager7_naam?: string;
  aanvrager8_vol?: string;
  aanvrager8_volReversed?: string;
  aanvrager8_voorn?: string;
  aanvrager8_naam?: string;
  aanvrager9_vol?: string;
  aanvrager9_volReversed?: string;
  aanvrager9_voorn?: string;
  aanvrager9_naam?: string;
}

export interface AdresType {
  huisnummer: string;
  naam: string;
  straat: string;
}

export interface AanvragerType {
  voornaam: string;
  naam: string;
  volledig: string;
  volledigReversed: string;
}

export interface PersoonType {
  naam: string;
  voornaam: string;
  hoedanigheid: string;
}
