import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
const useDocumentViewerStyles = (theme: Theme) =>
  createStyles({
    webviewer: {
      height: "calc(100vh - 104px)",
      width: "100%",
      position: "relative",
    },
    webviewerMobile: {
      height: "calc(100vh - 97px)",
      width: "100%",
      position: "relative",
    },
    webviewerLeft: {
      width: "50%",
      float: "left",
    },
    webviewerRight: {
      width: "calc(50% - 1px)",
      float: "right",
      borderLeft: "1px solid",
      borderColor: theme.palette.primary.main,
    },
    docviewer: {
      backgroundColor: theme.palette.primary.main,
    },
    bottomNav: {
      backgroundColor: theme.palette.secondary.main,
      maxHeight: 40,
      bottom: 0,
      width: "100%",
    },
    bottomNavContainer: {
      margin: "0 auto",
      lineHeight: "40px",
    },
    bottomNavButton: {
      color: "#ffffff",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    bottomNavIconButton: {
      color: "#ffffff",
    },
    paragraph: {
      color: "#ffffff",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      transform: "translate(-50%,-50%)",
    },
  });

export default useDocumentViewerStyles;
