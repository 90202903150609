import SearchIcon from "@mui/icons-material/Search";
import { AppBar, Icon, Toolbar, Typography } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import { StoresContext } from "index";
import React, { FunctionComponent, useContext } from "react";
import useHeaderViewStyles from "./HeaderViewStyles";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "25ch",
      "&:focus": {
        width: "28ch",
      },
    },
  },
}));

interface Props {
  classes: any;
}

const MunicipalityHeaderView: FunctionComponent<Props> = ({ classes }) => {
  const {
    uiStore: { setSearchMunicipality },
  } = useContext(StoresContext);

  const handleChange = (e) => {
    setSearchMunicipality(e.target.value.trim());
  };

  return (
    <AppBar
      className={classNames(classes.appBar, {
        [classes.appBarShift]: false,
      })}
    >
      <Toolbar>
        <Icon
          className={"icon-logo-orbit-black"}
          style={{
            height: 28,
            marginRight: 5,
          }}
        />
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 0.37, display: { xs: "none", sm: "block" } }}>
          Geoportaal Omgeving
        </Typography>
        <div className={classes.grow} />
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase data-test-id="search" onChange={handleChange} placeholder="Vb: Zemst, 1980, Bornem, 2880" inputProps={{ "aria-label": "search" }} />
        </Search>
        <div className={classes.grow} />
        <div className={classes.grow} />
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(useHeaderViewStyles)(MunicipalityHeaderView);
